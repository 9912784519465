import { ACTIVE_INACTIVE, GROOM_PAYMENT_DETAIL, TOGGLE_DEFAULT_OTP, USERS_LIST, USER_DETAIL } from "../../constants";
import { store } from "../../store";
import {
    HideTableLoader,
    ShowProgressBar,
    ShowTableLoader,
} from "../../store/alerts";
import { SaveTotalUsersCount, SaveUserList } from "../../store/users";
import { postRequest } from "../../utils/services";
import {
    formatUserDetailResponse,
    listRequestInterface,
    usersListRequest,
    usersListResponseInterface,
    MakePaymentRequest,
    formatPaymentDetailResponse,
} from "../dataType/api_interfaces/users";
import { userInfoInterface } from "../dataType/reduxActionsInterfaces/usersStoreInterfaces";
import { MAKE_PAYMENT } from "../../constants";
import { ShowErrorAlert, ShowSuccessAlert } from "../../store/alerts";
import { translateLanguage } from "../../translation";


export const UsersListAPI = async (values: listRequestInterface) => {
    const { dispatch } = store;

    let data = usersListRequest(values);
    var response: any = null;

    dispatch(ShowTableLoader());
    response = await postRequest(USERS_LIST, data);

    if (response) {
        let responseData = response.data.users_list as usersListResponseInterface[];
        let totalUsersCount = response.data.total_users;
        let tempData: userInfoInterface[] = [];

        responseData.forEach((responseData) => {
            tempData.push({
                id: responseData.id,
                name: responseData.name,
                email: responseData.email,
                phoneNumber: responseData.phone_no,
                countryCode: responseData.country_code,
                weddingDate: responseData.wedding_date,
                photo: responseData.photo,
                emailVerfied: responseData.is_email_verified,
                phoneVerified: responseData.is_phone_verified,
                isGroomProfileTransferred: responseData.is_groom_amount_transferred,
                isGroomProfileVerified: responseData.is_groom_profile_verified,
                isActive: responseData.is_active,
                groomProfile: responseData.is_groom_profile,
                palaceProfile: responseData.is_palace_profile,
                groomPayments: responseData.is_groom_payments,
                isBlocked: responseData.is_blocked

            });
        });

        dispatch(SaveUserList(tempData));
        dispatch(SaveTotalUsersCount(totalUsersCount || 0));
        dispatch(HideTableLoader());

        return true;
    }
};

export const getUserDetailApi = async (userId: string, setUserData: any) => {
    // const { dispatch } = store;
    // var response: any = null;

    // dispatch(ShowProgressBar());
    // response = await postRequest(USER_DETAIL, {
    //     user_id: userId,
    // });

    // if (response) {
    //     let responseData = response.data;
    //     const formatUserData = formatUserDetailResponse(responseData);
    //     setUserData({
    //         data: formatUserData,
    //         loading: false,
    //     });
    //     return true;
    // }
    const { dispatch } = store;
    try {
        var response: any = null;

        dispatch(ShowProgressBar());
        response = await postRequest(USER_DETAIL, {
            user_id: userId,
        });

        if (response) {
            let responseData = response.data;
            const formatUserData = formatUserDetailResponse(responseData);
            setUserData({
                data: formatUserData,
                loading: false,
            });
            dispatch(
                ShowSuccessAlert({
                    visible: true,
                    message: response.message,
                })
            );
            return true;
        }
    }
    catch (error) {
        dispatch(ShowErrorAlert({ visible: false, message: response.message }));
    }
    return false;

};


export const SentTransferMoneyApi = async (requestData: MakePaymentRequest) => {
    const { dispatch } = store;
    try {
        var response: any = null;
        dispatch(ShowProgressBar());
        response = await postRequest(MAKE_PAYMENT, requestData)
        if (response && response.data) {
            dispatch(
                ShowSuccessAlert({
                    visible: true,
                    message: response.message,
                })
            );
            return true;
        }
    } catch (error) {
        dispatch(ShowErrorAlert({ visible: false, message: translateLanguage("ERROR_MONEY_TRANSFER") }));
    }
    return false;
}


export const defaultOtpAPI = async (userId: string) => {
    const { dispatch } = store;
    try {
        var response: any = null;
        dispatch(ShowProgressBar());
        response = await postRequest(TOGGLE_DEFAULT_OTP, {
            user_id: userId,
        })
        if (response) {
            dispatch(
                ShowSuccessAlert({
                    visible: true,
                    message: response.message,
                })
            );
            return true;
        }
    } catch (error) {
        dispatch(ShowErrorAlert({ visible: false, message: response.message }));
    }
    return false;
}

export const groomPaymentDetailApi = async (paymentId: string, setUserData: any) => {
    const { dispatch } = store;

    try {
        var response: any = null;

        dispatch(ShowProgressBar());
        response = await postRequest(GROOM_PAYMENT_DETAIL, {
            payment_id: paymentId,
        });

        if (response) {
            let responseData = response.data;
            const formatUserData = formatPaymentDetailResponse(responseData);
            setUserData({
                data: formatUserData,
                loading: false,
            });
            dispatch(
                ShowSuccessAlert({
                    visible: true,
                    message: response.message,
                })
            );
            return true;
        }
    } catch (error) {
        dispatch(ShowErrorAlert({ visible: false, message: response.message }));
    }
    return false;
}

interface moneyTranferInterface {
    userId: string;
    paymentTo: string;
    amount: string;
    remarks: string
}

export const moneyTransferApi = async (props: moneyTranferInterface) => {
    const { dispatch } = store;
    const { userId, paymentTo, amount, remarks } = props;

    const requestData: MakePaymentRequest = {
        user_id: userId,
        payment_to: paymentTo,
        amount_transfer: amount.trim(),
        remarks: remarks?.trim() ?? ""
    };

    var response: any = null;
    dispatch(ShowProgressBar());
    response = await postRequest(MAKE_PAYMENT, requestData);
    if (response) {
        dispatch(ShowSuccessAlert({ visible: true, message: response.message }));
        return true;
    }
};


export const UpdateActiveInactiveAPI = async (props: { obj_id: string, service_type: string }) => {
    const { dispatch } = store;

    var response: any = null;
    dispatch(ShowProgressBar());
    response = await postRequest(ACTIVE_INACTIVE, props);
    if (response) {
        // dispatch(ShowSuccessAlert({ visible: true, message: response.message }));
        return true;
    }
};