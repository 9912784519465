import { groomPaymentDetailApi } from '../../../services/api_services/users';
import { translateLanguage } from '../../../translation';
import { Avatar, Box, Container, Divider, Grid, IconButton, KeyboardBackspace, Typography, useEffect, useState, useNavigate, useParams } from '../../../utils/commonImports';
import { paymentFeeConstants } from "../../../utils/paymentFeeConstants"

interface DonarData {
    name: string;
    phoneNo: string;
    email: string;
    photo?: string;
}

interface BasicData {
    paymentId: string;
    paymentType: string;
    time: string;
    baseAmount: number;
    totalAmount: number;
    notifiedArres: any;
    sendPaymentNotification: any;
    sendCongratsCard: any;
    deliverySpecialWay: any;
}

export const getColorStyles = (paymentType: string) => {
    switch (paymentType) {
        case 'OPPORTUNITY':
            return { backgroundColor: 'var(--red-error-light)', color: 'var(--red-dark)' };
        case 'INDIVIDUAL':
            return { backgroundColor: 'var(--edit-green-light)', color: 'var(--edit-green)' };
        default:
            return {};
    }
};

const UserPaymentDetail = () => {
    const [groomPaymentInfoData, setGroomPaymentInfoData] = useState<{
        data: {
            groomBasicPaymentInfo: BasicData;
            groomPaymentDonarInfo: DonarData;
        };
        loading: boolean;
    }>({
        data: {
            groomBasicPaymentInfo: {
                paymentId: '',
                paymentType: '',
                time: '',
                baseAmount: 0,
                totalAmount: 0,
                notifiedArres: '',
                sendPaymentNotification: '',
                sendCongratsCard: '',
                deliverySpecialWay: " ",

            },
            groomPaymentDonarInfo: {
                name: '',
                phoneNo: '',
                email: '',
                photo: '',
            },
        },
        loading: true,
    });
    const { paymentId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        paymentId && groomPaymentDetailApi(paymentId, setGroomPaymentInfoData);
    }, [paymentId]);

    const donarData = groomPaymentInfoData.data.groomPaymentDonarInfo;
    const BasicData = groomPaymentInfoData.data.groomBasicPaymentInfo;

    const formatDate = (dateString: string) => {
        if (!dateString) {
            return (
                <Box className="pl-12">
                    ---
                </Box>
            );
        }
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: "2-digit",
            month: "long",
            year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
    };

    const formatTime = (timeString: string) => {
        const date = new Date(timeString);
        return date.toLocaleTimeString("en-US", { hour12: true });
    };


    const { backgroundColor, color } = getColorStyles(BasicData.paymentType);

    const feesVisibleType = ["OPPORTUNITY", "INDIVIDUAL"]
    feesVisibleType.includes(BasicData?.paymentType)


    return (
        // <Container className="py-5 !mx-auto" >
        <Box className="md:!py-5 !pt-3 !pb-3 !px-5" >
            <Grid container spacing={3} className='!w-full !m-0 '>
                <Grid item xs={12} className='!px-0'>
                    <Typography color="textPrimary" variant='h6' className='md:!mt-2 !gap-2 !flex NunitoSans800 !items-center !font-semibold'>
                        <IconButton className="!bg-gray-100"
                            onClick={() => navigate(-1)}
                        ><KeyboardBackspace /></IconButton>
                        {translateLanguage("GROOM_PAYMENT_DETAIL")}
                    </Typography>
                </Grid>

                <Box className="md:!py-5 !pt-3 !pb-3 md:!px-5 !w-full" sx={{ maxWidth: "2000px" }}>
                    <Box
                        // sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
                        className="commonShadow commonBorderRadius  !bg-white"
                    >
                        <Box
                            className="xl:flex justify-between "
                        >
                            <Box className="xl:!flex !grid xl:!gap-12 !gap-4 p-5 items-center !h-fit ">
                                <Box className="relative w-fit">
                                    <Avatar
                                        alt="image name"
                                        src={donarData?.photo || "https://www.shutterstock.com/image-vector/default-avatar-profile-icon-vector-260nw-1725655669.jpg"}
                                        className="rounded-circle xl:!w-[100px] xl:!h-[100px] !h-[80px] !w-[80px]"
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body1" className="!w-fit !my-2 NunitoSans800  !text-sm !py-1 !px-2 !rounded !bg-opacity-15 !bg-blue-800 !text-blue-800">
                                        {translateLanguage("DONOR_INFO")}
                                    </Typography>
                                    <Typography variant="body1" className="!text-muted NunitoSans800 textOverflowTwoLines !font-semibold">
                                        {donarData.name}
                                    </Typography>
                                    <Typography variant="body1" className="!text-muted NunitoSans600">
                                        {donarData.phoneNo}
                                    </Typography>
                                    {/* <Typography
                                        variant="body1"
                                        className="text-muted flex gap-1 items-center NunitoSans600 textOverflow"
                                    >
                                        {donarData.email}
                                    </Typography> */}
                                </Box>
                            </Box>
                            <Box className="lg:border-l-2 !p-5 !mt-4 lg:pl-3">
                                <Box className="md:!flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[150px] NunitoSans600 !text-[var(--gray-font)] min-w-[100px]"
                                    >
                                        {translateLanguage("PAYMENT_ID")}
                                    </Typography>
                                    <Typography variant="body1" className="text-muted NunitoSans700 !pt-[2px] textOverflowTwoLines !font-semibold">
                                        {BasicData.paymentId}
                                    </Typography>
                                </Box>
                                <Box className="md:!flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[150px] NunitoSans600 !text-[var(--gray-font)] min-w-[100px]"
                                    >
                                        {translateLanguage("PAYMENT_TYPE")}
                                    </Typography>
                                    <Typography variant="body1" style={{ backgroundColor, color }} className="!px-[8px]  !pt-[5px] !rounded text-muted NunitoSans700 !w-fit !text-sm textOverflowTwoLines !font-semibold">
                                        {(BasicData.paymentType === "INDIVIDUAL") ?
                                            translateLanguage("INDIVIDUAL")
                                            : (BasicData.paymentType === "OPPORTUNITY") ?
                                                translateLanguage("OPPORTUNITY") :
                                                BasicData.paymentType
                                        }
                                    </Typography>
                                </Box>
                                <Box className="md:!flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[150px] NunitoSans600 !text-[var(--gray-font)] min-w-[100px]"
                                    >
                                        {translateLanguage("DATE")}
                                    </Typography>
                                    <Typography variant="body1" className="text-muted NunitoSans700 !pt-[2px] textOverflowTwoLines !font-semibold">
                                        {formatDate(BasicData.time)}
                                    </Typography>
                                </Box>
                                <Box className="md:!flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[150px] NunitoSans600 !text-[var(--gray-font)] min-w-[100px]"
                                    >
                                        {translateLanguage("TIME")}
                                    </Typography>
                                    <Typography variant="body1" className="text-muted NunitoSans700 !pt-[2px] textOverflowTwoLines !font-semibold">
                                        {formatTime(BasicData.time.toString())}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="lg:!p-5">
                            <Box className="md:!flex !grid !items-center !min-w-full lg:!min-w-[450px]">
                                <Box className=" !w-full !borwder-2 !p-5">



                                    {/* {BasicData.paymentType === 'OPPORTUNITY' ||
                                        BasicData.paymentType === 'INDIVIDUAL' ? */}
                                    {feesVisibleType.includes(BasicData?.paymentType) && (
                                        <>
                                            <Box className="md:!flex gap-10 justify-between">
                                                <Typography
                                                    variant="subtitle1"
                                                    className="md:min-w-[200px] NunitoSans500 min-w-[100px]"
                                                >
                                                    {translateLanguage("NOTIFY_GROOM_FINAL_REPORT")}
                                                </Typography>
                                                <Typography variant="body1" className="text-muted NunitoSans500 textOverflowTwoLines">
                                                    {BasicData.notifiedArres ? paymentFeeConstants.NOTIFY_GROOM_IN_FINAL_REPORT : 0}
                                                </Typography>
                                            </Box>
                                            <Box className="md:!flex gap-10 justify-between">
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        fontWeight: `${BasicData.sendPaymentNotification ? "600" : "300"
                                                            }`,
                                                    }}
                                                    className="md:min-w-[200px] NunitoSans500 min-w-[100px]"
                                                >
                                                    {translateLanguage("SEND_PAYMENT_NOTIFICATION_TO_GROOM")}
                                                </Typography>
                                                <Typography variant="body1" sx={{
                                                    fontWeight: `${BasicData.sendPaymentNotification ? "600" : "300"
                                                        }`,
                                                }} className="text-muted NunitoSans500 textOverflowTwoLines">
                                                    {BasicData.sendPaymentNotification ? paymentFeeConstants.SEND_PAY_NOTI_TO_GROOM : 0}
                                                </Typography>
                                            </Box>
                                            <Box className="md:!flex gap-10 justify-between">
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        fontWeight: `${BasicData.sendCongratsCard ? "600" : "300"
                                                            }`,
                                                    }}
                                                    className="md:min-w-[200px] NunitoSans500 min-w-[100px]"
                                                >
                                                    {translateLanguage("SEND_A_CONGO_CARD_TO_GROOM")}
                                                </Typography>
                                                <Typography variant="body1" sx={{
                                                    fontWeight: `${BasicData.sendCongratsCard ? "600" : "300"
                                                        }`,
                                                }} className="text-muted NunitoSans500 textOverflowTwoLines">
                                                    {BasicData.sendCongratsCard ? paymentFeeConstants.SEND_CONGO_CARD_TO_GROOM : 0}
                                                </Typography>
                                            </Box>
                                            {BasicData.paymentType !== 'OPPORTUNITY' && (
                                                <Box className="md:!flex gap-10 justify-between">
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{
                                                            fontWeight: `${BasicData.deliverySpecialWay ? "600" : "300"
                                                                }`,
                                                        }}
                                                        className="md:min-w-[200px] NunitoSans500 min-w-[100px]"
                                                    >
                                                        {translateLanguage("DELIVERY_IN_SPECIAL_WAY_INDIVIDUALLY")}
                                                    </Typography>
                                                    <Typography sx={{
                                                        fontWeight: `${BasicData.deliverySpecialWay ? "600" : "300"
                                                            }`,
                                                    }} variant="body1" className="text-muted NunitoSans500 textOverflowTwoLines ">
                                                        {BasicData.deliverySpecialWay ? paymentFeeConstants.DELIVER_IN_SPECIAL_WAY : 0}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </>
                                    )
                                    }

                                    <Box className="md:!flex gap-10 justify-between">
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: `${BasicData.baseAmount ? "600" : "300"
                                                    }`,
                                            }}
                                            className="md:min-w-[200px] NunitoSans500 min-w-[100px]"
                                        >
                                            {translateLanguage("BASE_AMOUNT")}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            className="text-muted NunitoSans500 flex gap-1 items-center !font-semibold"
                                        >
                                            {BasicData.baseAmount}
                                        </Typography>
                                    </Box>
                                    <Divider className='py-2' style={{
                                        borderStyle: "dashed",
                                        borderBottomWidth: "medium",
                                    }} />
                                    <Box className="md:!flex gap-10 justify-between !pt-2">
                                        <Typography
                                            variant="subtitle1"
                                            className="md:min-w-[200px] NunitoSans500 min-w-[100px] !text-lg !font-semibold"
                                        >
                                            {translateLanguage("TOTAL_AMOUNT")}
                                        </Typography>
                                        <Typography variant="body1" className="text-muted NunitoSans500 textOverflowTwoLines !text-lg !font-semibold">
                                            {BasicData.totalAmount} SAR
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Box>
        // </Container>
    )
}

export default UserPaymentDetail