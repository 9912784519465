import { Box, Button, IconButton, Modal, TextField, Typography, useMediaQuery } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { useContext, useRef, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import s3ClientConfigured, { deleteS3Object } from '../../../utils/s3Config';
import { SendPendingCardAPI, getfeaturedServiceDetailApi } from '../../../services/api_services/featuredServices';
import { useParams } from 'react-router-dom';
import { CardDataContext } from './PendingCardDetail';
import TaskIcon from '@mui/icons-material/Task';
import { translateLanguage } from '../../../translation';


interface SendCardModalInterface {
    attachmentModal: {
        open: boolean;
        attachmentUrl: string;
        remarks: string;
    }
    setAttachmentModal: any
}


const getFileExtension = (filename: string) => {
    return filename?.split('.')?.pop()?.toLowerCase();
};

const isImage = (extension: string) => {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
};

const isPdf = (extension: string) => {
    return extension === 'pdf';
};



export default function SendCardModal(props: SendCardModalInterface) {
    const isLargeScreen = useMediaQuery("(min-width:600px)");

    const contextValue: any = useContext(CardDataContext);
    const { setPendingCardDetail } = contextValue

    const { attachmentModal, setAttachmentModal } = props;
    const remarksText = useRef<HTMLInputElement>(null)
    const removeMediaArray = useRef<string[]>([]);
    const { pendingCardId } = useParams();


    const handleCloseModal = () => {
        setAttachmentModal({
            ...attachmentModal,
            open: false
        })
    }

    const uploadedImageName = attachmentModal.attachmentUrl?.replace(
        `${process.env.REACT_APP_S3_BASE_PATH}/`,
        ""
    )


    const handleRemoveAttachment = () => {
        const imageName = attachmentModal.attachmentUrl?.replace(
            `${process.env.REACT_APP_S3_BASE_PATH}/`,
            ""
        );
        if (imageName) {
            removeMediaArray.current.push(imageName);
        }
        setAttachmentModal({
            ...attachmentModal,
            attachmentUrl: ""
        });
    }


    const handleFileUpload = async (event: any) => {
        const file = event.target.files[0];

        try {
            // Proceed with file upload if validation passes
            const currentUnixTimestamp = Date.now();
            const currentUnixTimestampSeconds = Math.floor(
                currentUnixTimestamp / 1000
            );
            let key = `admin_content/custom_cards/${currentUnixTimestampSeconds}_${file.name.replace(
                / /g,
                "_"
            )}`;

            const input: any = {
                ACL: "public-read",
                Body: file,
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: key,
                ContentType: "image/jpeg",
            };
            // const command = new HeadObjectCommand(input);
            const command = new PutObjectCommand(input);
            try {
                let res = await s3ClientConfigured.send(command);
                if (res && res["$metadata"]["httpStatusCode"] === 200) {
                    setAttachmentModal({
                        ...attachmentModal,
                        attachmentUrl: `${process.env.REACT_APP_S3_BASE_PATH}/${key}`
                    });
                    // setIsAttachmentUploaded(true); 
                }
            } catch (err) {
                console.log(err);
            }
        } catch (error) {
            console.error("Validation failed:", error);
        }
    };

    const onSubmit = async () => {
        const requestData = {
            card_id: pendingCardId,
            card_media: attachmentModal.attachmentUrl,
            remarks: remarksText.current?.value?.trim()
        }

        const response = await SendPendingCardAPI(requestData)

        if (response) {
            pendingCardId && getfeaturedServiceDetailApi(pendingCardId, setPendingCardDetail)
        }

        handleCloseModal();

        if (removeMediaArray.current.length > 0) {
            removeMediaArray.current.forEach((imageName) => {
                deleteS3Object(imageName);
            });
        }
    }

    return (
        <Modal
            open={attachmentModal.open}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            className="!right-3 !left-3"
            aria-describedby="modal-description"
        >
            <Box className="!rounded-md !py-6 !px-8" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: 770, bgcolor: 'background.paper', boxShadow: 24, }}>
                <Typography id="modal-title" variant="h6" component="h2" className="NunitoSans800 !font-semibold !flex !justify-between !items-center">
                    {translateLanguage("CARD_ATTACHMENT")}
                    <IconButton onClick={handleCloseModal}>
                        <ClearIcon />
                    </IconButton>
                </Typography>

                <div className="mt-6 flex justify-evenly items-center w-full">
                    {attachmentModal.attachmentUrl ? (
                        <Box className="!grid !w-full !justify-center !m-auto">
                            <div className="!max-w-[366px] !w-full !justify-center !m-auto !rounded-md !border-2 !border-[var(--edit-green)] sm:!min-w-[366px] !max-h-[113px] !overflow-hidden sm:!min-h-[113px] !relative">
                                {
                                    (isImage(getFileExtension(attachmentModal.attachmentUrl) ?? ""))
                                        ?

                                        <img
                                            src={attachmentModal.attachmentUrl}
                                            alt="Uploaded"
                                            className="!max-w-[366px] !rounded-lg  sm:!min-w-[366px] !relative !max-h-[113px] sm:!min-h-[113px]"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        /> :
                                        <div className='!pl-4 !pt-[6px] NunitoSans700 !font-semibold !text-[var(--edit-green)] !items-center !flex'>
                                            {translateLanguage("MEDIA_UPLOADED")}
                                            <TaskIcon className="!h-24 !w-24 !text-[var(--edit-green)]" />
                                        </div>
                                }
                                <Button
                                    onClick={handleRemoveAttachment}
                                    color={"error"}
                                    className="!p-1  !min-w-[10px] !min-h-[20px] "
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        right: "0px",
                                        zIndex: 1,
                                    }}
                                >
                                    <CloseIcon
                                        // color="error"
                                        className="!w-5 !text-[var(--red)] bg-white !border-[var(--red)] !border !p-[2px] !rounded-full !h-5"
                                    />
                                </Button>
                            </div>
                            {/* <Typography variant='h6' className='!mt-3   textOverflowTwoLines !text-sm'>{uploadedImageName} </Typography> */}
                        </Box>
                    ) : (
                        <div>
                            <input
                                id="upload-button"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                            />
                            <label htmlFor="upload-button">
                                <Button
                                    variant="outlined"
                                    // color="primary"
                                    className="!text-[var(--primary)] commonBorderRadius !border-[var(--primary)] !font-semibold !border-2"
                                    component="span"
                                    sx={{
                                        padding: "20px",
                                    }}
                                    startIcon={
                                        <CloudUploadOutlinedIcon
                                            sx={{ fontSize: "40px !important" }}
                                        />
                                    }
                                >
                                    <span className='NunitoSans800'
                                        style={{
                                            display: isLargeScreen ? "flex" : "none",
                                        }}
                                    >
                                        {translateLanguage("UPLOAD_IMAGE")}
                                    </span>
                                </Button>
                            </label>
                        </div>
                    )}
                </div>

                <Box className="!grid !gap-4 !justify-betweesn !w-full !items-centewr !mt-5">
                    <Box className="!w-full">
                        <TextField
                            fullWidth
                            placeholder={translateLanguage("ENTER_REMARKS_HERE")}
                            title="Remarks"
                            type="text"
                            defaultValue={attachmentModal.remarks}
                            inputRef={remarksText}
                        />
                    </Box>
                </Box>

                <Box className="!mb-4 !mt-8 !w-full" display="flex" justifyContent="center">
                    <Button
                        onClick={() => {
                            onSubmit()
                        }}
                        disabled={!attachmentModal.attachmentUrl}
                        className={`!font-semibold !px-12 commonButtonRadius NunitoSans800 !flex !gap-2 !py-[10px] 
                        ${!attachmentModal.attachmentUrl ? '!bg-[var(--gray-font-light)]' : '!bg-[var(--primary)]'} !text-white !text-base`}
                        variant="contained"
                    // disabled={!isAttachmentUploaded} 
                    // disabled={!attachmentModal.attachmentUrl} 
                    >
                        {translateLanguage("SAVE")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}


