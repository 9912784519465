import { ADMIN_LOGIN, ADMIN_LOGOUT } from "../../constants";
import { store } from "../../store";
import { ShowProgressBar, ShowSuccessAlert } from "../../store/alerts";
import { ResetAdminStore, SaveAdminInfo } from "../../store/admin";
import { postRequest } from "../../utils/services";
import { adminLoginResponseInterface, adminLoginRequest } from "../dataType/api_interfaces/authentication";
import { adminInfoInterface } from "../dataType/reduxActionsInterfaces/adminStoreInterfaces";
import { translateLanguage } from "../../translation";


export const SignInAPI = async (values: any) => {
    const { dispatch } = store

    let data = adminLoginRequest(values);
    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((ADMIN_LOGIN), data);

    if (response) {
        let responseData = response.data.authorization as adminLoginResponseInterface
        let reduxPayload: adminInfoInterface = {
            userEmail: responseData?.email,
            userName: responseData?.username,
            accessToken: responseData.access_token,
            refreshToken: responseData.refresh_token,
        }
        dispatch(SaveAdminInfo(reduxPayload));
        dispatch(ShowSuccessAlert({ visible: true, message: translateLanguage("LOG_IN_SUCCESS") }));
        return true;
    }

};


export const logoutApi = async () => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((ADMIN_LOGOUT), {});

    if (response) {
        dispatch(ResetAdminStore())
        dispatch(ShowSuccessAlert({ visible: true, message: translateLanguage("LOGOUT_SUCCESS") }));
        return true;
    }

};

