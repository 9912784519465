import { OPPORTUNITY_DETAIL, OPPORTUNITY_LIST } from "../../constants";
import { store } from "../../store";
import { HideTableLoader, ShowProgressBar, ShowTableLoader } from "../../store/alerts";
import { SaveOpportunityList, SaveTotalOpportunityCount } from "../../store/opportunity";
import { postRequest } from "../../utils/services";
import { formatOpportunityDetailResponse, listRequest, listRequestInterface, opportunityListResponseInterface } from "../dataType/api_interfaces/opportunity";
import { opportunityInfoInterface } from "../dataType/reduxActionsInterfaces/opportunityStoreInterfaces";

export const OpportunityListAPI = async (values: listRequestInterface) => {
    const { dispatch } = store

    let data: any = listRequest(values);
    data['filter_type'] = values.filterType
    var response: any = null;

    dispatch(ShowTableLoader());
    response = await postRequest((OPPORTUNITY_LIST), data);

    if (response) {
        let responseData = response.data.opportunity_list as opportunityListResponseInterface[]
        let totalCount = response.data.total_opportunity
        let tempData: opportunityInfoInterface[] = []
        responseData.forEach((responseData) => {
            tempData.push({
                id: responseData.id,
                groomId: responseData.groom.id,
                groomName: responseData.groom.name,
                groomEmail: responseData.groom.email,
                groomPhoneNumber: responseData.groom.phone_no,
                groomPhoto: responseData.groom.photo,
                leaderId: responseData.leader.id,
                leaderName: responseData.leader.name,
                leaderEmail: responseData.leader.email,
                leaderPhoneNumber: responseData.leader.phone_no,
                leaderPhoto: responseData.leader.photo,
                relationWithGroomId: responseData.relation_with_groom.id,
                relationWithGroomText: responseData.relation_with_groom.relation,
                title: responseData.title,
                contributors: responseData.contributors,
                createdAt: responseData.created_at,
                weddingDate: responseData.wedding_date,
                totalAmountRecievedOpportunity: responseData.total_amount_recieved_opportunity,
                totalBaseAmountRecievedOpportunity: responseData.total_base_amount_recieved,
                totalFeesRecievedOpportunity: responseData.total_fees_recieved,
                isActive: responseData.is_active
                // contributorsCountryCode: responseData.contributors.country_code,
            })
        })

        dispatch(SaveOpportunityList(tempData));
        dispatch(SaveTotalOpportunityCount(totalCount || 0));
        dispatch(HideTableLoader());
        return true;
    }

};


export const getOpportunityDetailApi = async (opportunityId: string, setOpportunityData: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((OPPORTUNITY_DETAIL), {
        opportunity_id: opportunityId
    });

    if (response) {
        let responseData = response.data
        const formatData = formatOpportunityDetailResponse(responseData)
        setOpportunityData({
            data: formatData,
            loading: false
        })
        return true;
    }
};