import { Reducer } from "react";
import { adminStoreActions } from "./types";
import { adminInfoInterface } from "../../services/dataType/reduxActionsInterfaces/adminStoreInterfaces";

export const adminInitialState: adminInfoInterface = {
    userName: "",
    userEmail: "",
    accessToken: "",
    refreshToken: ""
}

export const adminInfoReducer: Reducer<adminInfoInterface, adminStoreActions> = (state = adminInitialState, { type, payload }: adminStoreActions) => {

    switch (type) {
        case "UPDATE_ADMIN_INFO":
            return {
                ...state,
                userName: payload.userName ?? "",
                userEmail: payload.userEmail ?? "",
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken,
            };

        case "RESET_ADMIN_STORE":
            return adminInitialState;

        default:
            return state;
    }
};
