import { Avatar, Box, Button, Chip, IconButton, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import "../../Users/UserDetail/GroomInfo.css";
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SendCardModal from './SendCardModal';
import { CardDataContext } from './PendingCardDetail';
import { translateLanguage } from '../../../translation';
// import ForwardIcon from '@mui/icons-material/Forward';


interface UserDataInterface {
    userData: {
        name: string
        photo: string
        state: string
        city: string
        phoneNumber: string
        email: string;
        weddingHallName: string;
        weddingDate: string;
        gender: string;
        ibanNumber: string;
        bankAccount: string;
        accountNumber: string;
        weddingPlace: string;
    }
}

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "long",
        year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
};

export default function UserBasicInfo(props: UserDataInterface) {
    const navigate = useNavigate();
    const { userData } = props;

    const contextValue: any = useContext(CardDataContext);
    const { pendingCardDetail, setPendingCardDetail } = contextValue


    const [attachmentModal, setAttachmentModal] = useState({
        open: false,
        attachmentUrl: "",
        remarks: ""
    })

    useEffect(() => {
        setAttachmentModal({
            ...attachmentModal,
            attachmentUrl: pendingCardDetail.customCardMedia,
            remarks: pendingCardDetail.remarks
        })
    }, [pendingCardDetail])

    const userFields = [
        { label: translateLanguage("WEDDING_HALL_NAME"), value: userData.weddingHallName },
        { label: translateLanguage("WEDDING_PLACE"), value: userData.weddingPlace },
        { label: translateLanguage("WEDDING_DATE"), value: formatDate(userData.weddingDate) },
        { label: translateLanguage("GENDER"), value: userData.gender },
    ];

    const userFieldsNumbers = [
        { label: translateLanguage("IBAN_NUMBER"), value: userData.ibanNumber },
        { label: translateLanguage("BANK_ACCOUNT"), value: userData.bankAccount },
        { label: translateLanguage("ACCOUNT_NUMBER"), value: userData.accountNumber },
    ];

    return (
        <>
            <SendCardModal attachmentModal={attachmentModal} setAttachmentModal={setAttachmentModal} />
            <Box>

                <Box className="lg:!flex !grid !gap-5 !justify-between !items-center !mb-8 lg:!mb-4 ">
                    <Typography color="textPrimary" variant='h6' className='!mt-2 !gap-2 !flex !items-center NunitoSans800 !font-semibold'>
                        <IconButton className="!bg-gray-100" onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></IconButton>
                        {translateLanguage("CUSTOM_CARD_DETAIL")}
                    </Typography>


                    <span>

                        {
                            pendingCardDetail.isCompleted ?

                                <Button variant={"outlined"} className="!font-semibold !pointer-events-none NunitoSans800 !mr-4 !px-5 commonButtonRadius 
                                NunitoSans800 commonStatusRadius !text-center !min-w-[120px] !text-base !py-2 !rounded !bg-opacity-15  !text-[var(--edit-green)] !border-[var(--edit-green-light)] !border-2
                                ">
                                    {translateLanguage("COMPLETED")}
                                </Button>
                                :
                                <Button variant={"outlined"} className="!font-semibold !pointer-events-none NunitoSans800 !mr-4 !text-base !py-2 !px-5 commonButtonRadius 
                                NunitoSans800 commonStatusRadius !text-center !min-w-[120px]  !rounded !bg-opacity-15  !text-[var(--red)] !border-[var(--red-error-light)] !border-2
                                
                                ">
                                    {translateLanguage("PENDING")}
                                </Button>
                        }


                        <Button variant={"outlined"} onClick={() => { setAttachmentModal({ ...attachmentModal, open: true }) }}
                            className="!font-bold !text-base !py-[10px] !px-6 NunitoSans800 !bg-[var(--primary)] commonButtonRadius  !border-[var(--primary)] !border-2 !text-[var(--white)] "
                        >
                            {translateLanguage("SEND_CARD")}
                            {/* <ForwardIcon /> */}
                        </Button>
                    </span>
                </Box>


                <Box
                    // sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
                    className=" xl:flex commonBorderRadius commonShadow justify-between  !bg-white"
                >
                    <Box className="xl:!flex !grid xl:!gap-12 !gap-4 p-5 items-center h-full">
                        <Box className="relative w-fit">
                            <Avatar
                                alt={userData.name}
                                src={userData.photo}
                                className="rounded-circle xl:!w-[100px] xl:!h-[100px] !h-[80px] !w-[80px]"
                            />
                        </Box>

                        <Box>
                            <Typography variant="body1" className="!text-muted textOverflowTwoLines NunitoSans800 !font-semibold">
                                {userData.name}
                            </Typography>
                            {/* <Typography
                                variant="body1"
                                className="text-muted flex gap-1 textOverflowTwoLines items-center NunitoSans600  !font-semibold"
                            >
                                {userData.email}
                            </Typography> */}
                        </Box>
                    </Box>

                    <Box className="p-5 flex items-center">
                        <Box className="lg:border-l-2 lg:pl-3">
                            <Box className="flex gap-10">
                                <Typography
                                    variant="subtitle1"
                                    className="md:min-w-[100px] NunitoSans600 text-[var(--gray-font)] min-w-[60px]"
                                >
                                    {translateLanguage("PHONE")}
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className="text-muted flex gap-1 items-center NunitoSans700 !font-semibold"
                                >
                                    {userData.phoneNumber}
                                </Typography>
                            </Box>

                            <Box className="flex gap-10">
                                <Typography
                                    variant="subtitle1"
                                    className="md:min-w-[100px] NunitoSans600 text-[var(--gray-font)] min-w-[60px]"
                                >
                                    {translateLanguage("STATE")}
                                </Typography>

                                <Typography variant="body1" className="text-muted textOverflowTwoLines NunitoSans700 !font-semibold">
                                    {userData.state}
                                </Typography>
                            </Box>

                            <Box className="flex gap-10">
                                <Typography
                                    variant="subtitle1"
                                    className="md:min-w-[100px] NunitoSans600 text-[var(--gray-font)] min-w-[60px]"
                                >
                                    {translateLanguage("CITY")}
                                </Typography>

                                <Typography variant="body1" className="text-muted textOverflowTwoLines !font-semibold">
                                    {userData.city}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>


                <Box className="!p-5 lg:!flex !mt-7 !bg-white commonBorderRadius commonShadow !grid !items-center !justify-between !w-full"
                // sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
                >
                    <Box className="lg:!border-l-2 lg:!pl-3">
                        {userFields.map((field, index) => (
                            <Box key={index} className="xl:!flex !grid xl:!gap-10 !gap-1">
                                <Typography variant="subtitle1" className="!min-w-[170px] NunitoSans600 text-[var(--gray-font)] xl:!mt-0 !mt-3 ">
                                    {field.label}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className="!text-muted NunitoSans700 pt-1 textOverflowTwoLines !font-semibold"
                                >
                                    {field.value}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box className="lg:!border-l-2 lg:!pl-3">
                        {userFieldsNumbers.map((number, index) => (
                            <Box key={index} className="xl:!flex !grid xl:!gap-10 !gap-1">
                                <Typography variant="subtitle1" className="!min-w-[170px] NunitoSans600 text-[var(--gray-font)] xl:!mt-0 !mt-3 ">
                                    {number.label}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className="!text-muted NunitoSans700 pt-1 !font-semibold"
                                >
                                    {number.value}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>

        </>

    )
}
