import { TOASTER_ALERT_ADD, TOASTER_ALERT_LIST } from "../../constants";
import { store } from "../../store";
import { ShowProgressBar, ShowSuccessAlert } from "../../store/alerts";
import { translateLanguage } from "../../translation";
import { getRequest, patchRequest, postRequest } from "../../utils/services";


export const getToasterAlertsAPI = async (setObj: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await getRequest((TOASTER_ALERT_LIST));

    if (response) {
        let responseData = response.data

        let tempData: any[] = []
        responseData.forEach((responseData: any) => {
            tempData.push({
                id: responseData.id,
                text: responseData.text,
                version: responseData.version,
                createdAt: responseData.created_at,
                isActive: responseData.is_active
            })
        })

        setObj(tempData)
        return true;
    }
};


export const addNewToasterApi = async (requestData: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((TOASTER_ALERT_ADD), requestData);

    if (response) {
        let responseData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: translateLanguage("ALERT_ADDED") }));

        return true;
    }
};

export const updateToasterApi = async (requestData: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await patchRequest((TOASTER_ALERT_ADD), requestData);

    if (response) {
        let responseData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: translateLanguage("ALERT_UDPATED") }));

        return true;
    }
};