import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TextDialogBox from '../../components/common/TextDialogBox';
import { updateToasterApi } from '../../services/api_services/alertsToast';
import { ShowErrorAlert } from '../../store/alerts';
import { useDispatch } from 'react-redux';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { translateLanguage } from '../../translation';

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_ALERTS_EXISTS"),
};

export default function AlertToasterDatagrid(props: any) {
    const dispatch = useDispatch();
    const { alertsList, setAlertsList } = props;
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        id: false,
    });

    const [editDialogState, setEditDialogState] = useState<any>({
        open: false,
        modeType: '',
        formType: '',
        existingValue: '',
        onSubmitCallback: () => { },
    });

    const generateColumns = (alertsList: any, setAlertsList: any): GridColDef[] => {
        return [
            { field: 'id', headerName: 'ID', width: 30, flex: 1 },
            {
                field: 'text',
                headerName: translateLanguage("BANNER_TEXT"),
                width: 250,
                flex: 2,
                headerAlign: 'left',
                sortable: false,
                align: 'left',
                editable: false,
            },
            {
                field: 'createdAt',
                headerName: translateLanguage("CREATED_AT"),
                width: 110,
                flex: 0.9,
                sortable: false,
                renderCell: (params) => {
                    return (
                        <Box className="!whitespace-nowrap">
                            {moment(params.row.createdAt).format('YYYY-MM-DD')}
                            &nbsp;
                            {moment(params.row.createdAt).format('h:mm A')}
                        </Box>
                    );
                },
            },
            {
                field: 'actions',
                headerName: translateLanguage("ACTION"),
                minWidth: 220,
                flex: 0.5,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => {
                    const isFirstRowChangeStatus = params.row.id === alertsList[0].id;
                    return (
                        <>
                            {isFirstRowChangeStatus ? (
                                <div className="!gap-3 !flex !items-center">
                                    <Typography
                                        color={params.row.isActive ? 'primary' : 'error'}
                                        onClick={() => UpdateActiveInActiveStatus(params)}
                                    >
                                        {params.row.isActive ? (
                                            <Button className="NunitoSans800 !px-4 !py-[5px] commonButtonRadius !bg-[var(--active-green)] !text-[var(--white)]  !text-base !font-semibold">
                                                {translateLanguage("ACTIVE")}
                                            </Button>
                                        ) : (
                                            <Button className="NunitoSans800 !px-4 !py-[5px] commonButtonRadius !bg-[var(--red-error)]  !text-[var(--white)]  !text-base !font-semibold">
                                                {translateLanguage("INACTIVE")}
                                            </Button>
                                        )}
                                    </Typography>
                                    <Button onClick={() => EditAlertToastPrifillData(params)} className=" !px-3  NunitoSans800   !py-[7px] commonButtonRadius !text-[var(--edit-green)] !bg-[var(--edit-green-light)] !text-base !font-semibold"><EditOutlinedIcon className='!w-5 !items-center !flex !mr-[4px]' />{translateLanguage("EDIT")}</Button>
                                </div>
                            ) : (
                                // <Typography variant="h6" className="!text-[var(--red-dark)] NunitoSans800 !text-base !font-semibold">
                                //     InActive
                                // </Typography>
                                <Typography variant='h6' className="NunitoSans800 commonStatusRadius !text-center !min-w-[100px] !text-sm !py-2 !px-4 !rounded !bg-opacity-15  !text-[var(--red)] bg-[var(--red-error-light)]">{translateLanguage("INACTIVE")} </Typography>
                            )}
                        </>
                    );
                },
            },
        ];
    };

    const columns = generateColumns(alertsList, setAlertsList);

    const UpdateActiveInActiveStatus = (params: any) => {
        updateToasterApi({
            alert_id: params.row.id,
            text: params.row.text.trim(),
            version: params.row.version,
            is_active: !params.row.isActive,
        });

        const updatedRows = alertsList.map((row: { id: any }) =>
            row.id === params.row.id ? { ...row, isActive: !params.row.isActive } : row
        );
        setAlertsList(updatedRows);
    };

    const EditAlertToastPrifillData = (params: any) => {
        setEditDialogState({
            open: true,
            modeType: translateLanguage("EDIT"),
            formType: translateLanguage("BANNER_TEXT"),
            title_length: '300',
            existingValue: params.row.text,
            onSubmitCallback: (data: any) => UpdateAlertToastUpdateText(data, params.row.id, params.row.version),
        });
    };

    const UpdateAlertToastUpdateText = (data: any, id: any, version: any) => {
        if (!(data.title).trim()) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("ENTER_TEXT_FOR_ALERT") }));
            return;
        }

        updateToasterApi({
            alert_id: id,
            text: data.title.trim(),
            version: version,
        });

        const updatedRows = alertsList.map((row: { id: any }) =>
            row.id === id ? { ...row, text: data.title.trim() } : row
        );
        setAlertsList(updatedRows);
        setEditDialogState({ ...editDialogState, open: false });
    };

    return (
        <>
            <div style={{ width: '100%', maxWidth: '2100px' }} className="alertToastDataGrid">
                <DataGrid
                    sx={{
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#60c4f08f',
                            fontWeight: 1000,
                        },
                        height: '90vh',
                        minHeight: '70vh',
                    }}
                    autoHeight={true}
                    rows={alertsList}
                    columns={columns}
                    getRowId={(obj) => obj.id}
                    pageSizeOptions={[10]}
                    localeText={customLocaleText}
                    columnVisibilityModel={columnVisibilityModel}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableVirtualization={true}
                    className='alertToastDataGridTable tableHeaderColor commonBorderRadius commonShadow'
                    paginationMode={'client'}
                    hideFooterSelectedRowCount={true}
                    disableRowSelectionOnClick={true}
                />
            </div>
            <TextDialogBox dialogState={editDialogState} setDialogState={setEditDialogState} />
        </>
    );
}
