import { TextField, } from "@mui/material";

// Custom component
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';




const CustomTextInputField = (props: any) => {
    const { input, meta, title, placeholder, required, fieldType, toolTipTitle, rows, labelTitle } = props;
    const hasValue = input.value !== undefined && input.value !== null && input.value !== '';
    const isTouched = meta.touched;

    // Show Required error if user try to move to next field without filling up the form

    const error = meta.error ? meta.error : (required && isTouched && !hasValue);
    const errorText = (meta.error ? meta.error : (required && error ? `${title} is required` : ''));

    const styles = {
        marginBottom: "5px",
        boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 7px 1px",

        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    };
    return (
        <div style={{ alignItems: 'center' }} className="!mb-2">
            {
                !labelTitle &&
                <p className="NunitoSans700" style={{ fontSize: 14, fontWeight: '600', color: 'black', marginBottom: '5px' }}>{title}{required ? '*' : ""}</p>
            }
            <TextField
                {...input}
                sx={styles}
                error={Boolean(error)}
                type={fieldType}
                required={required}
                placeholder={placeholder ? placeholder : `Enter ${title}`}
                helperText={error ? errorText : ""}
                value={hasValue ? input.value : ''}
                fullWidth={true}
                multiline={rows ? true : false}
                // className=" NunitoSans600"
                minRows={rows ? rows : 1}
                label={labelTitle}
            />

            {toolTipTitle &&
                <>
                    <Tooltip title={toolTipTitle} sx={{ ml: "2%" }}>
                        <InfoOutlinedIcon style={{ cursor: "pointer" }} />
                    </Tooltip>
                </>}
        </div>
    );
};

export default CustomTextInputField;