import { Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { ShowErrorAlert, ShowSuccessAlert } from '../../store/alerts';
import { RootStateType } from '../../store';


export default function AlertPopUp() {
    const dispatch = useDispatch();
    const alerts = useSelector((state: RootStateType) => state.alerts);

    const handleClose = () => {
        dispatch(ShowSuccessAlert({visible:false, message: ""}));
    }

    const handleErrorClose = () => {
        dispatch(ShowErrorAlert({visible:false, message: ""}));
    }

  return (
    <>
        <Snackbar className='red--whiteIcon' anchorOrigin={{ vertical : 'top', horizontal : 'center' }} open={alerts.success.visible} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%', color: '#fff', background:  '#5AAA77', fontWeight: 400}}>
                {alerts.success.message}
            </Alert>
        </Snackbar>
        <Snackbar className='red--whiteIcon' anchorOrigin={{ vertical : 'top', horizontal : 'center' }} open={alerts.error.visible} autoHideDuration={3000} onClose={handleErrorClose}>
            <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%', color: '#fff', background: '#EB3751', fontWeight: 400 }}>
                {alerts.error.message}
            </Alert>
        </Snackbar>
    </>
  )
}
