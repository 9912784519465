import React, { createContext, useEffect, useState } from 'react'
import UserBasicInfo from './UserBasicInfo'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../store';
import { pendingCustomCardInfoInterface } from '../../../services/dataType/reduxActionsInterfaces/featuredServices';
import { Box, Typography } from '@mui/material';
import { getfeaturedServiceDetailApi } from '../../../services/api_services/featuredServices';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import TaskIcon from '@mui/icons-material/Task';
import CircleIcon from '@mui/icons-material/Circle';
import { translateLanguage } from '../../../translation';

export const CardDataContext: any = createContext({});


export default function PendingCardDetail() {

    const getFileExtension = (filename: string) => {
        return filename?.split('.')?.pop()?.toLowerCase();
    };

    const isImage = (extension: string) => {
        return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
    };
    const navigate = useNavigate();

    let pendingCards = useSelector((state: RootStateType) => state.pendingCardStore.pendingCardList);


    const [pendingCardDetail, setPendingCardDetail] = useState<pendingCustomCardInfoInterface>()

    const { pendingCardId } = useParams();
    useEffect(() => {
        const cardExists = pendingCards.filter((obj) => { return obj.id === pendingCardId })

        if (cardExists.length) {
            setPendingCardDetail(cardExists[0])
        } else {
            pendingCardId && getfeaturedServiceDetailApi(pendingCardId, setPendingCardDetail)
        }
    }, [])


    const splitGuestNumber = pendingCardDetail?.guestNumbers?.split("\n");
    // const sliceGuestNumber = splitGuestNumber?.slice(0, 2);
    // const updatedGuestNumber = sliceGuestNumber?.join(', ') || <Box className="!pl-16">---</Box>

    return (
        <>
            {
                pendingCardDetail?.id &&
                <Box sx={{ pt: 4 }} className="!px-5 !mb-7">
                    <CardDataContext.Provider value={{ pendingCardDetail: pendingCardDetail, setPendingCardDetail: setPendingCardDetail }} >
                        <UserBasicInfo userData={pendingCardDetail?.requestedBy} />
                    </CardDataContext.Provider>

                    <Box className="!p-5 !mt-7 !bg-white commonBorderRadius commonShadow  !w-full">
                        {/* <ul className='!grid !grid-cols-2 '> */}
                        <Typography className='!gap-2 !font-semibold NunitoSans800 !border-b-2 !text-lg !mb-6 !p-1 !flex !items-center'>
                            <FormatListBulletedIcon className='!h-5 !w-5' />
                            {translateLanguage("GUEST_NUMBERS")}
                        </Typography>
                        <ul>
                            {
                                // pendingCardDetail.guestNumbers.split(", ").map((number, key) => {
                                //     return <li key={key}>{number}</li>;
                                // })
                                splitGuestNumber?.map((number, index) => {
                                    return <li key={index} className='!gap-1 NunitoSans600  !font-semibold !border-b !mb-2 !p-1 !flex !items-center'><CircleIcon className='!text-[var(--gray-font)] !mr-3 !w-2 !h-2' />{number}</li>
                                })
                            }
                        </ul>
                    </Box>

                    {pendingCardDetail.isCompleted ? (
                        <Box className="!p-5 !mt-7 !bg-white commonBorderRadius commonShadow  !w-full">
                            <Typography className='!gap-2 !font-semibold NunitoSans800 !border-b-2 !text-lg !mb-6 !p-1 !flex !items-center'>
                                <InsertPhotoIcon className='!h-5 !w-5' />
                                {translateLanguage("CARD_ATTACHMENT_DETAIL")}
                            </Typography>

                            <Box className=" !max-w-[600px] !w-full">

                                {
                                    (isImage(getFileExtension(pendingCardDetail.customCardMedia) ?? ""))
                                        ?
                                        <img className='!w-full !max-h-60 !object-cover' src={pendingCardDetail?.customCardMedia} alt='CardImage' />
                                        :
                                        <div className='!pl-4 !pt-[6px] NunitoSans700 !font-semibold !text-[var(--edit-green)] !items-center !flex
                                    
                                    !max-w-[366px] !w-full !rounded-md !border-2 !border-[var(--edit-green)] sm:!min-w-[366px] !max-h-[113px] !overflow-hidden sm:!min-h-[113px] !relative
                                    '>
                                            {translateLanguage("MEDIA_UPLOADED")}

                                            <TaskIcon className="!h-24 !w-24 !text-[var(--edit-green)]" />
                                        </div>
                                }

                            </Box>
                            <Typography className='!gap-2 !mt-4 !font-semibold NunitoSans800 !text-[var(--gray-font)] !text-base !p-1 '>
                                <span className='!text-[var(--edit-green)] !text-lg '>
                                    {translateLanguage("REMARKS")}: &nbsp;</span>{pendingCardDetail?.remarks}
                            </Typography>
                        </Box>)
                        :
                        <></>
                    }
                </Box>
            }
        </>
    )
}
