import { paymentListInterface } from "../../services/dataType/api_interfaces/Payments";
import { UpdatePaymentListType, UpdateTotalPaymentCountType } from "./types";

export const updatePaymentList = (payload: paymentListInterface[]): UpdatePaymentListType => ({
    type: "UPDATE_PAYMENT_LIST",
    payload,
});

export const updateTotalPaymentsCount = (payload: number): UpdateTotalPaymentCountType => ({
    type: "UPDATE_TOTAL_PAYMENT_COUNT",
    payload,
});
