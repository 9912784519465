import { FeedbackInterface } from "../../services/dataType/api_interfaces/feedback";
import { UpdateFeedbackListType, UpdateTotalFilteredCountType, UpdateTotalNotesCountType, UpdateTotalSuggestionCountType, UpdateTotalComplaintCountType, UpdateTotalContactusCountType } from "./types";

export const updateFeedbackList = (payload: FeedbackInterface[]): UpdateFeedbackListType => ({
    type: "UPDATE_FEEDBACK_LIST",
    payload,
});

export const updateTotalFilteredCount = (payload: number): UpdateTotalFilteredCountType => ({
    type: "UPDATE_TOTAL_FILTERED_COUNT",
    payload,
});

export const updateTotalNotesCount = (payload: number): UpdateTotalNotesCountType => ({
    type: "UPDATE_TOTAL_NOTES_COUNT",
    payload,
});
export const updateTotalSuggestionCount = (payload: number): UpdateTotalSuggestionCountType => ({
    type: "UPDATE_TOTAL_SUGGESTION_COUNT",
    payload,
});
export const updateTotalComplaintCount = (payload: number): UpdateTotalComplaintCountType => ({
    type: "UPDATE_TOTAL_COMPLAINT_COUNT",
    payload,
});
export const updateTotalContactusCount = (payload: number): UpdateTotalContactusCountType => ({
    type: "UPDATE_TOTAL_CONTACTUS_COUNT",
    payload,
});
