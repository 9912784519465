import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React, { useEffect } from "react";
import { isDate, isNumber } from "lodash";

export type Order = 'asc' | 'desc';

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Check if the values are dates
    if (isDate(aValue) && isDate(bValue)) {
        return bValue.getTime() - aValue.getTime();
    }

    // Check if the values are numbers or strings that can be parsed as numbers
    if ((isNumber(aValue) || !isNaN(Number(aValue))) && (isNumber(bValue) || !isNaN(Number(bValue)))) {
        return Number(bValue) - Number(aValue);
    }

    // Default case: Sort values as strings
    const stringAValue = String(aValue);
    const stringBValue = String(bValue);

    if (stringBValue < stringAValue) {
        return -1;
    }
    if (stringBValue > stringAValue) {
        return 1;
    }
    return 0;
}


export const EnhancedTableHead = (props: any) => {
    const [order, setOrder] = React.useState<Order>('asc'); // Correctly typed as Order
    const [orderBy, setOrderBy] = React.useState<string>(''); // Correctly typed as keyof Payment
    const { headCells, sortedData, setSortedData, dependentEffect } = props;


    const onRequestSort = (event: any, property: string) => { // Correctly typed as keyof Payment
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };


    useEffect(() => {
        if (sortedData.length !== dependentEffect.length) {
            setSortedData(stableSort(dependentEffect, getComparator(order, orderBy)));
        } else {
            setSortedData(stableSort(sortedData, getComparator(order, orderBy)));
        }
    }, [order, orderBy, dependentEffect])

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell: any) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                            :
                            headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};