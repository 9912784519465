export interface listRequestInterface {
    pageLimit: number;
    pageNumber: number;
    searchText?: string;
    sortBy?: string;
    sortType?: string;
    filterType?: string;
}

export const listRequest = (data: listRequestInterface) => {
    let requestData = {
        page_limit: data.pageLimit ?? 10,
        page_number: data.pageNumber ?? 1,
        search_text: data.searchText ? data.searchText.trim() : "",
        sort_by: data.sortBy ?? "",
        sort_type: data.sortType ?? ""
    }
    return requestData;
}


export interface opportunityListResponseInterface {
    id: string;
    groom: {
        id: string;
        name: string;
        email: string;
        phone_no: string;
        photo: string;
    }
    leader: {
        id: string;
        name: string;
        email: string;
        phone_no: string;
        photo: string;
    }
    relation_with_groom: {
        id: string;
        relation: string;
    },
    created_at: any;
    wedding_date: any;
    contributors: any[];
    total_amount_recieved_opportunity: string
    title: string
    total_base_amount_recieved: string;
    total_fees_recieved: string;
    is_active: boolean;
}


export const formatOpportunityDetailResponse = (data: any) => {
    let responseData: any = {
        id: data.id,
        description: data.description,
        groomBasicInfo: {
            id: data.groom.id,
            name: data.groom.name,
            email: data.groom.email,
            phoneNumber: data.groom.phone_no,
            countryCode: data.groom.country_code,
            photo: data.groom.photo,
            isEmailVerified: data.groom.is_email_verified,
            isPhoneVerified: data.groom.is_phone_verified,
            isActive: data.groom.is_active,
            isGroomProfile: data.groom.is_groom_profile,

            isGroomPayments: data.groom.is_groom_payments,

            isPalaceProfile: data.groom.is_palace_profile,
            state: data.groom.state.state,
            city: data.groom.state?.city?.city,
            weddingHall: data.groom_info.wedding_hall,
            accountNo: data.groom_info.account_no,
            bank: data.groom_info.bank,
            gender: data.groom_info.gender,
            ibanNumber: data.groom_info.iban_no,
            weddingDate: data.groom_info.wedding_date,
            placeName: data.groom_info.place_name,

            totalBaseAmount: data.total_base_amount_recieved,
            totalFeesRecived: data.total_fees_recieved,
            totalAmountRecieved: data.total_amount_recieved_opportunity,

            overallTotalAmountRecieved: Number(data.groom_info.overall_total_amount_recieved),
            overallTotalAmountRecievedIndividually: Number(data.groom_info.overall_total_amount_recieved_individually),
            overallTotalAmountRecievedOpportuntiy: Number(data.groom_info.overall_total_amount_recieved_opportuntiy),
            overallTotalAmountTransferred: Number(data.groom_info.overall_total_amount_transferred),
            overallTotalOpportunity: Number(data.groom_info.overall_total_opportunity)

        },
        leaderBasicInfo: {
            id: data.leader.id,
            name: data.leader.name,
            email: data.leader.email,
            phoneNumber: data.leader.phone_no,
            countryCode: data.leader.country_code,
            photo: data.leader.photo,
            isEmailVerified: data.leader.is_email_verified,
            isPhoneVerified: data.leader.is_phone_verified,
            isActive: data.leader.is_active,
            isGroomProfile: data.leader.is_groom_profile,

            isGroomPayments: data.groom.is_groom_payments,

            isPalaceProfile: data.leader.is_palace_profile,
            state: data.leader.state.state,
            city: data.leader.state?.city?.city
        },
        realtionWithGroom: {
            id: data.relation_with_groom.id,
            relation: data.relation_with_groom.relation
        },
        title: data.title,
        contributers: [],
        // contributersData: {
        //     countryCode: data.contributors.country_code,
        // }

    }

    responseData['contributers'] = data.contributers.map((contributer: any) => ({
        id: contributer.id,
        donorInfo: {
            id: contributer.donor_info.id,
            name: contributer.donor_info.name,
            email: contributer.donor_info.email,
            phoneNumber: contributer.donor_info.phone_no,
            countryCode: contributer.donor_info.country_code,
            photo: contributer.donor_info.photo,
            isDonorAnonymous: contributer.donor_info.is_donor_anonymous
        },
        totalAmount: contributer.total_amount,
        baseAmount: contributer.base_amount,
        fees: contributer.fees,
        time: contributer.time
    }));


    return responseData;
}

