import { Card, CardContent, Grid, Typography, Link, Container, Box } from '@mui/material'
// import { DashboardOutlined as DashboardIcon } from "@mui/icons-material";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useEffect, useState } from 'react';
import { getDasboardDataAPI } from '../../services/api_services/dashboard';
import { Link as RouterLink } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import PushPinSharpIcon from '@mui/icons-material/PushPinSharp';
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AssistantIcon from '@mui/icons-material/Assistant';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import { translateLanguage } from '../../translation';

const useStyles = () => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: "#1b8ae92e",
    // boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 11px 4px",
    // borderRadius: "10px",
    minHeight: '156px',
    textAlign: "center",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "rows",
    alignItems: "center",
    // justifyContent: "space-between",
    // justifyContent: "space-around",
    height: "100%",
    width: '100%',
  },
  title: {
    marginBottom: '8px',
    textAlign: 'left',

  },
  count: {
    // fontSize: "32px",
    marginBottom: '4px',
    textAlign: 'right',

  },
  icon: {
    // fontSize: "48px",
    // color: "#009688",
    // color: "var(--primary)",
    textAlign: 'left',

    // marginRight: '8px',
  },
});



export default function Dashboard() {
  const classes = useStyles();

  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    totalOpportunities: 0,
    totalStates: 0,
    totalCities: 0,
    totalGrooms: 0,
    totalPalace: 0,
    totalPendingCustomCards: 0,
  })

  useEffect(() => {
    getDasboardDataAPI(setDashboardData)
  }, [])

  return (
    <Box className="!px-5 !py-5 defaultBasicInfo" sx={{ maxWidth: "2000px" }}>
      {/* <Typography color="textPrimary" variant='h6' className=' !text-[32px] !mb-4 !gap-2 !flex  !items-center !font-bold'>
          Dashboard
        </Typography> */}
      <CommonHeaderText headerName={translateLanguage("DASHBOARD")} />
      <Box className="!mb-4 !mt-6">

        {/* <Typography variant="h6" className="!font-semibold">Featured Services</Typography> */}
        <Grid container spacing={3} className='breakPointLargeWidth'>
          {/* <Grid item xs={12} sm={12} md={6} xl={3}> */}

          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>

            <Link component={RouterLink} to="/users" underline="none" >
              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                className='!bg-[var(--white)] commonShadow commonBorderRadius '
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h6" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("TOTAL")} {translateLanguage("USERS")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalUsers}
                    </Typography>
                  </div>
                  <PersonOutlineOutlinedIcon className="!text-[48px] md:!text-[58px] !rounded-full !p-3  !text-[var(--orange)] !bg-[var(--orange-light)]" sx={classes.icon} />
                  {/* <div>
                  <Typography variant="h6" component="h2" sx={classes.title}>
                    Grooms
                  </Typography>
                  <Typography variant="h4" component="p" sx={classes.count}>
                    {dashboardData.totalGrooms}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6" component="h2" sx={classes.title}>
                    Palace
                  </Typography>
                  <Typography variant="h4" component="p" sx={classes.count}>
                    {dashboardData.totalPalace}
                  </Typography>
                </div> */}

                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Link component={RouterLink} to="/opportunity" underline="none" >

              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                // 8f8fa4
                className='!bg-[var(--white)] commonShadow commonBorderRadius '
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("OPPORTUNITIES")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalOpportunities}
                    </Typography>
                  </div>
                  <PushPinSharpIcon style={{ transform: "rotate(40deg)" }} className="!text-[48px] md:!text-[58px] !rounded-full !p-3  !text-[var(--yellow)] !bg-[var(--yellow-light)]" sx={classes.icon} />
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Link component={RouterLink} to="/state-city" underline="none">

              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                className='!bg-[var(--white)] commonShadow commonBorderRadius'
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("STATES")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalStates}
                    </Typography>
                  </div>

                  {/* <div className=' !pl-16 xl:!pl-20 !border-gray-600 !border-l-2'> */}
                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("CITIES")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalCities}
                    </Typography>
                  </div>

                  <LocationOnOutlinedIcon className="!text-[48px] md:!text-[58px] !rounded-full !p-3   !text-[var(--purple)] !bg-[var(--purple-light)]" sx={classes.icon} />
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>

            <Link component={RouterLink} to="/pending-cards" underline="none" >
              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                className='!bg-[var(--white)] commonShadow commonBorderRadius'
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600  !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("CUSTOM_CARD_REQUEST")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalPendingCustomCards}
                    </Typography>
                  </div>
                  <AssistantIcon className="!text-[48px] md:!text-[58px] !rounded-full !p-3  !text-[var(--teal-card)] !bg-[var(--teal-card-light)]" sx={classes.icon} />
                </CardContent>
              </Card>
            </Link>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link component={RouterLink} to="/opportunity" underline="none">

            <Card sx={{
              ...classes.card,
              backgroundColor: "#198df114",
            }}>
              <CardContent sx={classes.content}>
                <PeopleAltOutlinedIcon sx={classes.icon} />
                <div>
                  <Typography variant="h6" component="h2" sx={classes.title}>
                    Total Cities
                  </Typography>
                  <Typography variant="h4" component="p" sx={classes.count}>
                    {dashboardData.totalCities}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Link>
        </Grid> */}
        </Grid>
      </Box>
      {/* </Container> */}
    </Box>
  );
};
