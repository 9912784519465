import { Box, Button, Modal, Stack, useMediaQuery } from "@mui/material";
import { Form, Field } from "react-final-form";
import CustomTextInputField from "../common/customTextInputField";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { DeleteObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import s3ClientConfigured, { deleteS3Object } from "../../utils/s3Config";
import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  ShowErrorAlert,
  ShowProgressBar,
  ShowSuccessAlert,
} from "../../store/alerts";
import {
  AddNewCustomMenuCardAPI,
  SaveCustomMenuCardAPI,
} from "../../services/api_services/customMenuCards";
import imageSizeValidation from "../../utils/imageSizeValidator";
import { CardData } from "../../services/dataType/api_interfaces/state_city";
import { translateLanguage } from "../../translation";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1200px",
  height: "fit-content",
  borderRadius: "6px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderTopRightRadius: "6px",
};

interface ModalState {
  open: boolean;
  cardData?: CardData;
}

interface AddCardMenuProps {
  addCardModal: ModalState;
  setAddCardModal: (state: ModalState) => void;
  fetchCards: () => void;
}

export function AddCardMenu({
  addCardModal,
  setAddCardModal,
  fetchCards,
}: AddCardMenuProps) {
  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery("(min-width:600px)");

  const [imageURL, setImageUrl] = useState<string | undefined>(
    addCardModal.cardData?.image || ""
  );

  const removeImageArray = useRef<string[]>([]);

  useEffect(() => {
    if (addCardModal.open) {
      setImageUrl(addCardModal.cardData?.image || "");
    }
  }, [addCardModal.open, addCardModal.cardData?.image]);

  const handleRemoveImage = () => {
    const imageName = imageURL?.replace(
      `${process.env.REACT_APP_S3_BASE_PATH}/`,
      ""
    );
    if (imageName) {
      removeImageArray.current.push(imageName);
    }
    setImageUrl("");
  };

  const handleSubmit = async (data: CardData) => {
    if (!imageURL) {
      dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("IMAGE_REQUIRED") }));
      return;
    }

    const request_data = {
      title: data.title,
      text: data.text,
      image: imageURL,
      card_id: data.id,
      page_url: data.page_url,
    };

    if (addCardModal.cardData) {
      const editedData = {
        ...request_data,
        card_id: addCardModal.cardData.id,
      };
      await SaveCustomMenuCardAPI(editedData, fetchCards);
      dispatch(
        ShowSuccessAlert({
          visible: true,
          message: translateLanguage("MENU_CARD_UPDATE_SUCCESS"),
        })
      );
    } else {
      await AddNewCustomMenuCardAPI(request_data, fetchCards);
      dispatch(
        ShowSuccessAlert({
          visible: true,
          message: translateLanguage("MENU_CARD_ADD_SUCCESS"),
        })
      );
    }

    handleClose();

    if (removeImageArray.current.length > 0) {
      removeImageArray.current.forEach((imageName) => {
        deleteS3Object(imageName);
      });
    }
  };

  const isEditMode = !!addCardModal.cardData;

  const validateImage = (file: any) => {
    return new Promise((resolve, reject) => {
      const maxSizeMB = 1;
      if (file.size > maxSizeMB * 1024 * 1024) {
        dispatch(
          ShowErrorAlert({
            visible: true,
            message: translateLanguage("IMAGE_SIZE_LIMIT"),
          })
        );
        reject(false);
      }
      imageSizeValidation(file, (isValid) => {
        if (!isValid) {
          dispatch(
            ShowErrorAlert({
              visible: true,
              message: translateLanguage("IMAGE_ASPECT_LIMIT"),
            })
          );
          reject(false);
        }
        resolve(true);
      });
    });
  };

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];

    try {
      await validateImage(file);
      // Proceed with file upload if validation passes
      const currentUnixTimestamp = Date.now();
      const currentUnixTimestampSeconds = Math.floor(
        currentUnixTimestamp / 1000
      );
      let key = `admin_content/menu_cards/${currentUnixTimestampSeconds}_${file.name.replace(
        / /g,
        "_"
      )}`;

      const input: any = {
        ACL: "public-read",
        Body: file,
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: key,
        ContentType: "image/jpeg",
      };
      // const command = new HeadObjectCommand(input);
      const command = new PutObjectCommand(input);
      try {
        let res = await s3ClientConfigured.send(command);
        if (res && res["$metadata"]["httpStatusCode"] === 200) {
          setImageUrl(`${process.env.REACT_APP_S3_BASE_PATH}/${key}`);
        }
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleClose = () => {
    setAddCardModal({ open: false });
  };

  const validate = (data: any) => {
    const errors: any = {};
    if (!data.text || data.text.trim() === "") {
      errors.text = "Description is required";
    } else if (data.text && data.text.length > 300) {
      // if (data.text && data.text.length > 50) {
      errors.text = "Description cannot be more than 300 characters";
    }
    if (data.title && data.title.length > 80) {
      errors.title = "Title cannot be more than 80 characters";
    }
    return errors;
  };

  return (
    <>
      <Modal open={addCardModal.open} onClose={handleClose}>
        <Box sx={style} className="md:!w-[70%] !w-[92%] ">
          <CancelIcon
            onClick={handleClose}
            sx={{
              color: "#000",
              position: "absolute",
              top: "18px",
              right: "18px",
              cursor: "pointer",
            }}
          />
          <div className="app-version">
            <div
              style={{ fontWeight: 600, marginBottom: "20px" }}
              className="!text-lg NunitoSans700"
            >
              {isEditMode ?
                translateLanguage("EDIT_MENU_CARD") :
                translateLanguage("ADD_NEW_MENU_CARD")
              }
            </div>
            <div className="">
              <Form
                validate={validate}
                initialValues={addCardModal.cardData || {}}
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <div style={{ width: "50%" }}>
                          <Field
                            name="title"
                            component={CustomTextInputField}
                            fieldType="text"
                            placeholder={translateLanguage("ENTER_TITLE_HERE")}
                            required={true}
                            maxLength="50"
                            title={translateLanguage("TITLE")}
                          />
                        </div>
                        <div style={{ width: "50%" }}>
                          <Field
                            name="page_url"
                            component={CustomTextInputField}
                            fieldType="text"
                            placeholder={translateLanguage("ENTER_PAGE_URL_HERE")}
                            required={true}
                            title={translateLanguage("PAGE_URL")}
                          />
                        </div>
                      </Stack>
                      <Stack direction="column" alignContent={"center"}>
                        <div
                          style={{ width: "100%" }}
                          className="addField-textarea"
                        >
                          <Field
                            name="text"
                            component={CustomTextInputField}
                            fieldType="text"
                            rows="3"
                            maxLength="200"
                            placeholder={translateLanguage("ENTER_DESCRIPTION_HERE")}
                            required={true}
                            title={translateLanguage("DESCRIPTION")}
                          />
                        </div>
                        <div className="mt-6 flex justify-evenly items-center w-full">
                          {imageURL ? (
                            <div className="!max-w-[366px] sm:!min-w-[366px] !max-h-[113px] sm:!min-h-[113px] !relative">
                              <img
                                src={imageURL}
                                alt="Uploaded"
                                className="!max-w-[366px] !rounded-lg !border-2 !border-[var(--red-error)] sm:!min-w-[366px] !relative !max-h-[113px] sm:!min-h-[113px]"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <Button
                                onClick={handleRemoveImage}
                                color={"error"}
                                className="!p-1 !bg-white !min-w-[10px] !min-h-[20px]  !rounded-full"
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  zIndex: 1,
                                }}
                              >
                                <CloseIcon
                                  color="error"
                                  className="!w-4 !h-4"
                                />
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <input
                                id="upload-button"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                              />
                              <label htmlFor="upload-button">
                                <Button
                                  variant="outlined"
                                  className="!text-[var(--primary)] commonBorderRadius !border-[var(--primary)] !font-semibold !border-2"
                                  component="span"
                                  sx={{
                                    padding: "20px",
                                  }}
                                  startIcon={
                                    <CloudUploadOutlinedIcon
                                      sx={{ fontSize: "40px !important" }}
                                    />
                                  }
                                >
                                  <span
                                    className="NunitoSans800"
                                    style={{
                                      display: isLargeScreen ? "flex" : "none",
                                    }}
                                  >
                                    {translateLanguage("UPLOAD_IMAGE")}
                                  </span>
                                </Button>
                              </label>
                            </div>
                          )}
                        </div>
                      </Stack>
                    </Stack>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                      className="gap-3"
                    >
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        className="!bg-[var(--cancel-gray)] !px-6 !py-[10px] NunitoSans800 commonButtonRadius !font-semibold !text-base"
                      >
                        {translateLanguage("CANCEL")}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        className="!bg-[var(--primary)] !font-semibold NunitoSans800 commonButtonRadius !px-8 !py-[10px] !text-base"
                      >
                        {isEditMode ?
                          translateLanguage("UPDATE_CARD") :
                          translateLanguage("ADD_CARD")
                        }
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddCardMenu;
