import { DASHBOARD } from "../../constants";
import { store } from "../../store";
import { ShowProgressBar } from "../../store/alerts";
import { getRequest } from "../../utils/services";


export const getDasboardDataAPI = async (setDashboardData: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await getRequest((DASHBOARD));

    if (response) {
        let responseData = response.data
        setDashboardData({
            totalUsers: responseData.total_users,
            totalOpportunities: responseData.total_opportunities,
            totalStates: responseData.total_states,
            totalCities: responseData.total_cities,
            totalGrooms: responseData.total_groom_profiles,
            totalPalace: responseData.total_palace_profiles,
            totalPendingCustomCards: responseData.pending_custom_cards
        })
        return true;
    }
};