import { PAYMENTS_LIST } from "../../constants";
import { store } from "../../store";
import { updatePaymentList, updateTotalPaymentsCount } from "../../store/Payments";
import { HideTableLoader, ShowTableLoader } from "../../store/alerts";
import { postRequest } from "../../utils/services";
import { paymentListInterface, paymentsListResponseInterface } from "../dataType/api_interfaces/Payments";
import { listRequest } from "../dataType/api_interfaces/opportunity";
import { listRequestInterface } from "../dataType/api_interfaces/users";

export const PaymentListAPI = async (values: listRequestInterface) => {
    const { dispatch } = store

    let data: any = listRequest(values);
    data['start_date'] = values.startDate
    data['end_date'] = values.endDate
    if (values.filterType !== "-") {
        data['filter_type'] = values.filterType
    }


    var response: any = null;

    dispatch(ShowTableLoader());
    response = await postRequest((PAYMENTS_LIST), data);


    if (response) {
        let responseData = response.data.payments as paymentsListResponseInterface[]
        let totalCount = response.data.payments_count
        let tempData: paymentListInterface[] = []
        responseData.forEach((responseData) => {
            tempData.push({
                id: responseData.id,
                baseAmount: responseData.base_amount,
                deliverSpecially: responseData.deliver_specially,
                donorInfo: responseData.donor_info,
                paymentType: responseData.payment_type,
                successAt: responseData.success_at,
                totalAmount: responseData.total_amount,
                fees: responseData.fees,
                groomInfo: {
                    id: responseData.groom_info.id,
                    email: responseData.groom_info.email,
                    phone: responseData.groom_info.phone,
                    photo: responseData.groom_info.photo,
                    name: responseData.groom_info.name,
                    weddingDate: responseData.groom_info.wedding_date
                }
            })
        })
        dispatch(updatePaymentList(tempData));
        dispatch(updateTotalPaymentsCount(totalCount || 0));
        dispatch(HideTableLoader());
        return true;
    }

};

