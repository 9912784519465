import {
    ShowSuccessAlertAction,
    ShowErrorAlertAction,
    alertParamsInterface,
    LoaderAction,
    DatagridLoaderAction,
    AppVersionToggleAction,
    UpdateCurrentLanguageAction
} from "./types";


export const ShowSuccessAlert = (payload: alertParamsInterface): ShowSuccessAlertAction => ({
    type: "SHOW_SUCCESS_ALERT",
    payload: payload
});

export const ShowErrorAlert = (payload: alertParamsInterface): ShowErrorAlertAction => ({
    type: "SHOW_ERROR_ALERT",
    payload: payload
});

export const ShowProgressBar = (): LoaderAction => ({
    type: "SHOW_LOADER"
});

export const HideProgressBar = (): LoaderAction => ({
    type: "HIDE_LOADER"
});



export const ShowTableLoader = (): DatagridLoaderAction => ({
    type: "SHOW_TABLE_LOADER"
});

export const HideTableLoader = (): DatagridLoaderAction => ({
    type: "HIDE_TABLE_LOADER"
});


export const ShowAppVersion = (): AppVersionToggleAction => ({
    type: "SHOW_APP_VERSION"
});

export const HideAppVersion = (): AppVersionToggleAction => ({
    type: "HIDE_APP_VERSION"
});


export const UpdateCurrentLanguage = (payload: string): UpdateCurrentLanguageAction => ({
    type: "UPDATE_CURRENT_LANGUAGE",
    payload: payload
});
