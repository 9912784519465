import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import { Form, Field } from 'react-final-form'
import { Link, useNavigate } from 'react-router-dom';
import { SignInAPI } from '../../services/api_services/authentication';
import CustomTextInputField from '../../components/common/customTextInputField';
import Paper from '@mui/material/Paper';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const LoginForm = (props: any) => {
    const { submitting } = props;
    const navigate = useNavigate();

    const signin = async (values: any) => {
        const signinSuccess = await SignInAPI(values)
        signinSuccess && navigate('/dashboard');
    };


    const validate = (data: any) => {
        const errors: any = {};

        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

        if (data.email && !(emailRegex.test(data.email))) {
            errors.email = "Invalid email format";
        }

        return errors;
    };

    return (
        <>
            <Grid item xs={12} sm={12} md={7} component={Paper} elevation={6} square>
                <Box className="!m-auto !px-5 !pt-[120px]"
                    sx={{
                        // margin: 'auto',
                        mx: 4,
                        maxWidth: '500px',
                        display: 'flex',
                        flexDirection: 'column',    
                        // justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box className="!text-center">

                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} className='!w-[50px] !mb-2 !flex !justify-center !m-auto !h-[50px]'>
                            <LockOutlinedIcon className='!text-[26px] ' />
                        </Avatar>
                        <Typography component="h1" variant="h5" className='!text-4xl  !font-semibold'>
                            Sign In
                        </Typography>
                        <Typography component="h6" className='!mb-7 !mt-2 !text-gray-600 !text-sm'>
                            Welcome Back!
                        </Typography>

                    </Box>
                    <Form
                        onSubmit={signin}
                        validate={validate}
                        initialValues={{}}
                        render={({ handleSubmit }) => (
                            <form style={{ margin: "auto", width: "100%" }} className='!items-center' id="signInForm" onSubmit={handleSubmit}>
                                <Field
                                    required={true}
                                    name="email"
                                    component={CustomTextInputField}
                                    placeholder='Enter registered email here'
                                    title="Email"
                                    fieldType="email"
                                />

                                <Field
                                    required={true}
                                    name="password"
                                    component={CustomTextInputField}
                                    fieldType="password"
                                    placeholder='Enter password here'
                                    title="Password"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className='!flex !w-full !mx-auto !shadow-xl !justify-center !py-3 !text-base !font-semibold !max-w-[50%]'
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign In
                                </Button>
                                <Copyright sx={{ mt: 5 }} />
                            </form>
                        )}
                    />

                </Box>
            </Grid >
        </>
    );
};


export default LoginForm;
