export interface FeedbackInterface {
    id: string;
    first_name: any;
    country_code: any;
    phone_no: any;
    message: string;
    feedback_list: any;
}

export interface FeedbackRequestInterface {
    pageLimit?: number;
    pageNumber?: number;
    searchText?: string;
    sortBy?: string;
    sortType?: string;
    filterType?: string;
}

export const feedbackRequest = (data: FeedbackRequestInterface) => {
    let requestData = {
        page_limit: data.pageLimit ?? 10,
        page_number: data.pageNumber ?? 1,
        search_text: data.searchText ? data.searchText.trim() : "",
        sort_by: data.sortBy ?? "",
        sort_type: data.sortType ?? "",
        filter_type: data.filterType ?? "",
    }
    return requestData;
}




