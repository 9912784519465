import { Box, Button, Modal, Stack } from '@mui/material';
import { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import CustomTextInputField from '../common/customTextInputField';
import CancelIcon from '@mui/icons-material/Cancel';
import { RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { HideAppVersion } from '../../store/alerts';
import { getAppVersionApi, setAppVersionApi } from '../../services/api_services/appVersion';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    borderTopRightRadius: "24px"
};


const initialAppStoreFormData = {
    android_version: "",
    ios_version: "",
}


export default function MobileAppVersion() {
    const dispatch = useDispatch();

    const [initialAppStoreForm, setInitialAppStoreForm] = useState(initialAppStoreFormData);

    let showAppVersionModal = useSelector((state: RootStateType) => state.alerts.showAppVersion);


    useEffect(() => {
        getAppVersionApi(setInitialAppStoreForm)
    }, [])



    const handleStoreAppSubmit = async (e: any) => {
        const addedSuccessfully = await setAppVersionApi(e)
        if (addedSuccessfully) {
            handleClose()
            setInitialAppStoreForm(e)
        }
    }

    const handleClose = () => {
        dispatch(HideAppVersion())
    }


    const validate = (data: any) => {
        const errors: any = {};

        if (data.ios_version && (data.ios_version.length > 10)) {
            errors.ios_version = "ios version cannot be more than 10 characters";
        }

        if (data.android_version && (data.android_version.length > 10)) {
            errors.android_version = "Android version cannot be more than 10 characters";
        }

        return errors;
    };

    return (
        <>
            <Modal
                open={showAppVersionModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CancelIcon
                        onClick={handleClose}
                        sx={{
                            color: "#C64242",
                            position: "absolute",
                            top: "-8px",
                            right: "-11px",
                            cursor: "pointer"
                        }} />
                    <div className="app-version">
                        <div style={{ marginLeft: "3%", fontWeight: 700, marginBottom: "20px" }}>
                            Mobile App Version
                        </div>

                        <div className=''>
                            <Form
                                initialValues={{ ...initialAppStoreForm }}
                                validate={validate}
                                onSubmit={handleStoreAppSubmit}>
                                {
                                    (props: any) => (
                                        <form onSubmit={props.handleSubmit}>
                                            <Stack direction="column">
                                                <Field
                                                    name="android_version"
                                                    component={CustomTextInputField}
                                                    fieldType="number"
                                                    placeholder={`Enter android version here`}
                                                    required={true}
                                                    title={"Android version"}
                                                />
                                                <Field
                                                    name="ios_version"
                                                    component={CustomTextInputField}
                                                    fieldType="number"
                                                    placeholder={`Enter iOS version here`}
                                                    required={true}
                                                    title={"iOS version"}
                                                />

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",

                                                        marginTop: "4.5%"
                                                    }}
                                                >
                                                    <Button type="submit" variant="contained">
                                                        Save
                                                    </Button>
                                                </div>
                                            </Stack>
                                        </form>
                                    )
                                }
                            </Form>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

