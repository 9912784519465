import { userInfoInterface } from "../../services/dataType/reduxActionsInterfaces/usersStoreInterfaces";
import {
    UpdateUserListType,
    ResetUserStoreType,
    UpdateTotalUserCountType
} from "./types";



export const SaveUserList = (payload: userInfoInterface[]): UpdateUserListType => ({
    type: "UPDATE_USERS_LIST",
    payload: payload
});

export const SaveTotalUsersCount = (payload: number): UpdateTotalUserCountType => ({
    type: "UPDATE_USERS_COUNT",
    payload: payload
});

export const ResetUserStore = (): ResetUserStoreType => ({
    type: "RESET_USER_STORE"
});

