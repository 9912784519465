import { Reducer } from "redux";
import { FeedbackStoreActions, FeedbackStoreInterface } from "./types";

const feedbackInitialState: FeedbackStoreInterface = {
    feedbackList: [],
    totalFilteredCount: 0,
    totalNotesCount: 0,
    totalSuggestionCount: 0,
    totalContactusCount: 0,
    totalComplaintCount: 0,
};

export const feedbackReducer: Reducer<FeedbackStoreInterface, FeedbackStoreActions> = (
    state = feedbackInitialState,
    { type, payload }
) => {
    switch (type) {

        case "UPDATE_FEEDBACK_LIST":
            return {
                ...state,
                feedbackList: payload,
            };

        case "UPDATE_TOTAL_FILTERED_COUNT":
            return {
                ...state,
                totalFilteredCount: payload,
            };


        case "UPDATE_TOTAL_NOTES_COUNT":
            return {
                ...state,
                totalNotesCount: payload,
            };
        case "UPDATE_TOTAL_SUGGESTION_COUNT":
            return {
                ...state,
                totalSuggestionCount: payload,
            };
        case "UPDATE_TOTAL_COMPLAINT_COUNT":
            return {
                ...state,
                totalComplaintCount: payload,
            };
        case "UPDATE_TOTAL_CONTACTUS_COUNT":
            return {
                ...state,
                totalContactusCount: payload,
            };
        default:
            return state;
    }
};


