import SidebarLayout from "./SidebarLayout";
import { PrivateRoutes } from "../../Routing";
import MobileAppVersion from "../../components/Modals/AppVersion";

const DashboardLayout = (props: any) => {
    return (
        <>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{  top: 0, height: 'fit-content' }} className="!z-50 md:!sticky !fixed">
                    <SidebarLayout /> 
                </div>

                {/* <main className="md:!mt-0 !mt-10" style={{ width: "98%", maxWidth: "1800px", margin: "1% 1% auto", overflowY: 'auto' }}> */}
                <main className="md:!mt-0 !mt-10" style={{ width: "100%", maxWidth: "1800px", margin: "1% 1% auto", overflowY: 'auto' }}>
                    {PrivateRoutes}
                    <MobileAppVersion />
                </main>
            </div >
        </>
    );
}


export default DashboardLayout;
