export default function imageSizeValidation(file: any, callback: (isValid: boolean) => void) {
    const reader = new FileReader();
    reader.onload = (e) => {
        if (e.target && e.target.result) {
            const img = new Image();
            img.onload = () => {
                // Calculate aspect ratio
                const aspectRatio = img.width / img.height;
                // Check aspect ratio
                const requiredAspectRatio = 3 / 1; // Required aspect ratio (width:height)
                if (Math.abs(aspectRatio - requiredAspectRatio) > 0.01) {
                    callback(false);
                } else {
                    console.log("FINE");
                    callback(true);
                }
            };
            img.src = e.target.result as string; // Type assertion
        } else {
            console.error("Failed to read file.");
            callback(false);
        }
    };
    reader.readAsDataURL(file);
}
