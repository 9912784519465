import { useContext, useState } from "react";
import { Table, TableBody, Typography, TableCell, TableRow, Box } from "@mui/material";
import moment from 'moment';
import { UserDataContext } from "./UserDetail";
import { ContextInterface } from "../../../services/dataType/api_interfaces/users";
import { EnhancedTableHead } from "../../../components/common/tableFunctions";
import { Link } from "react-router-dom";
import ProfileNameEmailUser from "../../../components/common/ProfileNameEmailUser";
import { translateLanguage } from "../../../translation";

interface Transferred {
    id: any;
    created_at: Date;
    amount: string;
    remarks: string;
}

const headCells = [
    { id: 'remarks;', label: translateLanguage("REMARKS"), sortable: false },

    { id: 'amount', label: translateLanguage("AMOUNT"), sortable: true },
    { id: 'created_at', label: translateLanguage("PAID_ON"), sortable: true },
];

function GroomTransferredTable() {
    const contextValue: ContextInterface = useContext(UserDataContext);
    const { userData } = contextValue

    const [sortedData, setSortedData] = useState(userData.data.groomBasicInfo.adminPaymentsRecieved as Transferred[] || [])

    if (sortedData.length === 0) {
        return <Typography variant="h6" className="!justify-center !flex !py-8">
            {translateLanguage("NO_PAYMENTS_FOUND")}</Typography>;
    }

    return (
        <Table>
            <EnhancedTableHead
                headCells={headCells}
                sortedData={sortedData}
                setSortedData={setSortedData}
                dependentEffect={userData.data.groomBasicInfo.adminPaymentsRecieved}
            />
            <TableBody>
                {sortedData.length && sortedData.map((obj, index) => (
                    <TableRow key={index}>
                        <TableCell className="!whitespace-nowrap truncate max-w-xs">

                            {obj?.remarks === null ?
                                <Box className="!pl-10">---</Box>
                                :
                                <> {obj.remarks}</>
                            }

                        </TableCell>
                        <TableCell className="!whitespace-nowrap">
                            {obj.amount}
                        </TableCell>
                        <TableCell className="!whitespace-nowrap">
                            {moment(obj.created_at).format('YYYY-MM-DD')}
                            <br />
                            {moment(obj.created_at).format('h:mm A')}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default GroomTransferredTable;
