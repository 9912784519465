import {
  Box,
  Button,
  IconButton,
  Paper,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import NearMeIcon from "@mui/icons-material/NearMe";
import { CardData } from "../../services/dataType/api_interfaces/state_city";
import { SaveCustomMenuCardAPI } from "../../services/api_services/customMenuCards";
import { ShowErrorAlert, ShowSuccessAlert } from "../../store/alerts";
import { useDispatch } from "react-redux";
import { translateLanguage } from "../../translation";

interface CustomCardRowProps {
  cardObj: CardData;
  onEdit: () => void;
  img?: any;
}

export function CustomCardRow({ cardObj, onEdit }: CustomCardRowProps) {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(cardObj.is_active);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const toggleChangeStatus = () => {
    const newStatus = !status;
    setStatus(newStatus);

    const requestData = {
      card_id: cardObj.id,
      title: cardObj.title,
      image: cardObj.image,
      page_url: cardObj.page_url,
      id: cardObj.id,
      text: cardObj.text,
      is_active: newStatus,
    };

    SaveCustomMenuCardAPI(requestData, (response: any) => {
      if (response) {
        dispatch(
          ShowSuccessAlert({
            visible: true,
            message: translateLanguage("STATUS_CHANGED_SUCCESS"),
          })
        );
      } else {
        dispatch(
          ShowErrorAlert({
            visible: true,
            message: translateLanguage("FAIL_TO_CHANGE_STATUS"),
          })
        );
        setStatus(!newStatus);
      }
    });
  };

  const openStatusModal = () => {
    setShowStatusModal(true);
  };

  const closeStatusModal = () => {
    setShowStatusModal(false);
  };

  const confirmStatusChange = () => {
    toggleChangeStatus();
    closeStatusModal();
  };

  const redirectLessonDetail = () => {
    // navigate(`/academy/lesson/${lesson.data._id}`, {
    //     state: {
    //         url: "lesson",
    //         searchText: searchText
    //     }
    // });
  };

  const onRemoveLesson = (event: any) => {
    // event.stopPropagation();
    // const confirmationModalObject = {
    //     show: true,
    //     title: 'Delete Lesson',
    //     content: 'Are you sure you want to delete this lesson?',
    //     confirmText: 'Delete',
    //     cancelText: 'Cancel',
    //     callBack: () => {
    //         deleteLesson(lesson.data._id);
    //         dispatch(HideConfirmationModal())
    //     }
    // }
    // dispatch(ShowConfirmationModal(confirmationModalObject))
  };



  return (
    <Box
      // sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
      className="grid !w-full   !bg-white p-5 mb-6 commonShadow commonBorderRadius"
    >
      <Box className="xl:!flex !grid !justify-between xl:!gap-0 !gap-6 !w-full">
        <Box>
          <Box className="relative w-fit">
            <img
              className="md:!max-w-[366px] md:!min-w-[366px] !relative md:!max-h-[113px] md:!min-h-[113px]"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 10,
                objectFit: "cover",
              }}
              src={cardObj.image}
            ></img>

            <Chip
              className="!absolute !bottom-0 !right-[0px] NunitoSans700 !rounded-sm !h-[23px]"
              label={status ?
                translateLanguage("ACTIVE")
                :
                translateLanguage("INACTIVE")
              }
              sx={{
                fontWeight: "600",
                color: "white",
                background: "#ff7777fa",
                backgroundColor: status ? "var(--active-green)" : "var(--red)",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{ gridTemplateColumns: "1fr 1fr" }}
          className="sm:!flex !grid !text-center sm:!w-fit !w-full gap-2 items-center !ml-auto"
        >
          <IconButton className="flex gap-1 !font-semibold NunitoSans800 items-center !px-3 !text-[14px] commonButtonRadius !bg-[var(--blue)] !text-[var(--white)]">
            <Link to={cardObj.page_url}>
              <NearMeIcon
                style={{ cursor: "pointer" }}
                className="!w-5 !h-5 !mr-1"
              />
              {translateLanguage("PAGE_URL")}
            </Link>
          </IconButton>
          <IconButton
            onClick={onEdit}
            className="flex gap-1 !font-semibold items-center NunitoSans800 !px-3 !text-[16px] commonButtonRadius !bg-[var(--edit-green-light)] !text-[var(--edit-green)]"
          >
            <EditIcon style={{ cursor: "pointer" }} className="!w-5 !h-5" />
            {translateLanguage("EDIT")}
          </IconButton>

          <Button
            onClick={openStatusModal}
            className={`flex gap-1 !font-semibold py-[8px] NunitoSans800 !justify-center items-center !px-3 !text-[14px] commonButtonRadius !text-white`}
            style={{
              backgroundColor: status ? "var(--active-green)" : "var(--red)",
            }}
          >
            {status ? translateLanguage("ACTIVE") : translateLanguage("INACTIVE")}
          </Button>

          {/* <IconButton
            onClick={(event) => onRemoveLesson(event)}
            className="flex gap-1 !bg-opacity-60 !pointer-events-none !font-semibold items-center !px-3 !text-[14px] !rounded-md !bg-red-400 !text-white"
          >
            <DeleteIcon style={{ cursor: "pointer" }} className="!w-5 !h-5" />
            Delete
          </IconButton> */}
        </Box>
      </Box>

      <Box className="mt-5">
        <Typography variant="h6" className="!font-semibold !text-lg NunitoSans800">
          {cardObj.title}
        </Typography>
        <Box>
          <label htmlFor={`raised-button-file${"cardObj.id"}`}>
            <Typography className="!text-base courseTitle NunitoSans800 !text-[var(--gray-font)]" variant="h6">
              {cardObj.text}
            </Typography>
          </label>
        </Box>
      </Box>

      <Modal open={showStatusModal} onClose={closeStatusModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            maxWidth: 500,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" className="!text-lg  NunitoSans800 !mb-5">
            {translateLanguage("UPDATE_MENU_STATUS")}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className="!text-base !font-semibold NunitoSans800"
          >
            {translateLanguage("SURE_TO_CHANGE_STATUS")}
            {`${!status ? translateLanguage("INACTIVE") : translateLanguage("ACTIVE")
              } to ${status ? translateLanguage("INACTIVE") : translateLanguage("ACTIVE")}`}
          </Typography>
          <Box className="!flex !items-center !w-full !justify-center !mt-6 !gap-2">
            <Button
              onClick={confirmStatusChange}
              className={`flex gap-1 !font-semibold py-[8px] NunitoSans800 !w-full !justify-center items-center !px-3 !text-[14px] commonButtonRadius !text-white`}
              style={{
                backgroundColor: !status ? "var(--active-green)" : "var(--red)",
                marginTop: 2,
                marginRight: 2,
              }}
            >
              {!status ? translateLanguage("ACTIVE") : translateLanguage("INACTIVE")}
            </Button>
            <Button
              onClick={closeStatusModal}
              className={`flex gap-1 !font-semibold py-[8px] NunitoSans800 !w-full !bg-[var(--cancel-gray)] !justify-center items-center !px-3 !text-[14px] commonButtonRadius !text-white`}
              style={{ marginTop: 2 }}
            >
              {translateLanguage("CANCEL")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
