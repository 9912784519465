import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { getOpportunityDetailApi } from '../../../services/api_services/opportunity';
import OpportunityGroomInfo from './OpportunityGroomInfo';
import { Box, Button, Container, Grid, IconButton, Typography } from '@mui/material';
import OpportunityLeaderInfo from './OpportunityLeaderInfo';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../store';
import moment from 'moment';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import IosShareIcon from '@mui/icons-material/IosShare';
import GroomTransfer from '../../../components/Modals/GroomTransfer';
import { moneyTransferApi } from '../../../services/api_services/users';
import ProfileNameEmailUser from '../../../components/common/ProfileNameEmailUser';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { UserDataContext } from '../../Users/UserDetail/UserDetail';
import { ContextInterface } from '../../../services/dataType/api_interfaces/users';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { translateLanguage } from '../../../translation';


const customLocaleText = {
    noRowsLabel: translateLanguage("NO_DATA_FOUND")
};

const generateColumns = (): GridColDef[] => {
    return [
        // { field: "id", headerName: "ID", minWidth: 90, flex: 1, },
        {
            field: 'name', headerName: translateLanguage("USER_DETAILS"), minWidth: 300, flex: 1.2, headerAlign: 'left', sortable: true,
            align: "left",
            // Added for sorting as value is picked from nested object
            valueGetter: (params: GridValueGetterParams) => params.row.donorInfo.name,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/users/detail/${params?.row?.donorInfo?.id}`} style={{ textDecoration: 'none' }} >
                            <ProfileNameEmailUser profileName={params.row.donorInfo.name} profileImageAvatar={params.row.donorInfo.photo} />
                        </Link>
                    </>
                );
            }
        },
        {
            field: 'donorInfo.phone', headerName: translateLanguage("PHONE"), minWidth: 160, flex: 0.9, sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.donorInfo.countryCode}{" "}{params.row.donorInfo.phoneNumber ? params.row.donorInfo.phoneNumber : "---"}
                    </>
                );
            }
        },
        {
            field: 'time', headerName: translateLanguage("DATE"), minWidth: 170, flex: 0.9, sortable: true,
            renderCell: (params) => {
                return (
                    <>
                        {moment(params.row.time).format('YYYY-MM-DD')}
                        <br />
                        {moment(params.row.time).format('h:mm A')}

                    </>
                );
            }
        },

        {
            field: 'baseAmount', headerName:
                (<>
                    {translateLanguage("BASE_AMOUNT")}
                    {/* Base&nbsp; */}
                    {/* <br /> */}
                    {/* Amount */}
                </>) as any,
            minWidth: 180, flex: 0.9, sortable: true,
            valueGetter: (params) => parseFloat(params.row.baseAmount) || 0 // Parse string to float

        },
        {
            field: 'fees', headerName:
                (<>
                    {translateLanguage("FEES")}
                </>) as any,
            minWidth: 180, flex: 0.9, sortable: true,
            valueGetter: (params) => parseFloat(params.row.fees) || 0 // Parse string to float

        },
        {
            field: 'totalAmount', headerName:
                (<>
                    {translateLanguage("TOTAL_AMOUNT")}
                </>) as any, minWidth: 180, flex: 0.9, sortable: true,
            valueGetter: (params) => parseFloat(params.row.totalAmount) || 0 // Parse string to float


        },
    ]
};

const OpportunityDetail = () => {
    const navigate = useNavigate();
    const columns = generateColumns();
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);
    const [opportunityData, setOpportunityData] = useState<any>({
        data: {
            groomBasicInfo: {},
            leaderBasicInfo: {},
            contributers: [],
        },
        loading: true
    })

    const { opportunityId } = useParams();
    useEffect(() => {
        opportunityId && getOpportunityDetailApi(opportunityId, setOpportunityData)
    }, [])


    const formatDate = (dateString: string) => {
        if (!dateString) {
            return (
                <Box className="pl-12">
                    ---
                </Box>
            );
        }
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: "2-digit",
            month: "long",
            year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
    };

    const leftFields = [
        { label: translateLanguage("WEDDING_HALL_NAME"), value: opportunityData.data.groomBasicInfo?.weddingHall },
        { label: translateLanguage("WEDDING_PLACE"), value: opportunityData.data.groomBasicInfo.placeName },
        { label: translateLanguage("WEDDING_DATE"), value: formatDate(opportunityData.data.groomBasicInfo.weddingDate) },
        { label: translateLanguage("GENDER"), value: opportunityData.data.groomBasicInfo.gender },
    ];

    const rightFields = [
        { label: translateLanguage("IBAN_NUMBER"), value: opportunityData.data.groomBasicInfo.ibanNumber },
        { label: translateLanguage("BANK_ACCOUNT"), value: opportunityData.data.groomBasicInfo.bank },
        { label: translateLanguage("ACCOUNT_NUMBER"), value: opportunityData.data.groomBasicInfo.accountNo },
    ];


    const [transferModal, setTransferModal] = useState({
        open: false,
        totalBaseAmountRecived: 0,
        totalBaseAmountTransfered: 0,
        totalOpportunity: 0,
        totalAmountRecievedOpportuntiy: 0,
        totalAmountRecievedIndividually: 0,

    })

    useEffect(() => {
        setTransferModal({
            ...transferModal,
            totalBaseAmountRecived: opportunityData.data.groomBasicInfo?.overallTotalAmountRecieved ?? 0,
            totalBaseAmountTransfered: opportunityData.data.groomBasicInfo?.overallTotalAmountTransferred ?? 0,

            totalOpportunity: opportunityData.data.groomBasicInfo?.overallTotalOpportunity ?? 0,
            totalAmountRecievedOpportuntiy: opportunityData.data.groomBasicInfo?.overallTotalAmountRecievedOpportuntiy ?? 0,
            totalAmountRecievedIndividually: opportunityData.data.groomBasicInfo?.overallTotalAmountRecievedIndividually ?? 0,

        })
    }, [opportunityData])

    const handleTransfer = () => {
        setTransferModal({
            ...transferModal,
            open: true
        })
    }

    const TransferMoney = async (amount: string, remarks: string) => {
        const response = await moneyTransferApi({
            userId: opportunityData.data.groomBasicInfo.id,
            paymentTo: "groom",
            amount: amount,
            remarks: remarks
        })
        if (response) {
            opportunityId && getOpportunityDetailApi(opportunityId, setOpportunityData)
        }

    }


    // download data in csv format code might be use don't removed
    const csvDataOpportunitiesPage = opportunityData.data.contributers.map((contributor: any) => ({
        'User Name': contributor.donorInfo.name,
        Phone: `${contributor.donorInfo.countryCode} ${contributor.donorInfo.phoneNumber || '---'}`,
        Date: moment(contributor.time).format('YYYY-MM-DD'),
        'Base Amount': contributor.baseAmount,
        Fees: contributor.fees,
        'Total Amount': contributor.totalAmount,
    }));

    // download data in Excel format code might be use don't removed 
    // const downloadExcelSheetOpportunitiesPage = () => {
    //     const worksheet = XLSX.utils.json_to_sheet(opportunityData.data.contributers.map((contributor: any) => ({
    //         'User Name': contributor.donorInfo.name,
    //         Phone: `${contributor.donorInfo.countryCode} ${contributor.donorInfo.phoneNumber || '---'}`,
    //         Date: moment(contributor.time).format('YYYY-MM-DD'),
    //         'Base Amount': contributor.baseAmount,
    //         Fees: contributor.fees,
    //         'Total Amount': contributor.totalAmount,
    //     })));

    //     const workbook = XLSX?.utils?.book_new();
    //     XLSX?.utils?.book_append_sheet(workbook, worksheet, 'GroomOpportunityPaymentReport');
    //     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     FileSaver?.saveAs(data, 'GroomOpportunityPaymentReport.xlsx');
    // };

    return (
        <>
            {/* <Container className="py-5 !mx-auto" > */}
            <GroomTransfer
                transferModal={transferModal}
                setTransferModal={setTransferModal}
                callBackTransfer={TransferMoney}
            />

            <Box className="!w-full !py-5  !mx-auto !max-w-[2000px]" >
                <Grid container spacing={3} className='!w-full !px-5 !m-0'>
                    <Grid item xs={12} className="!gap-3 !item-center !p-0 !flex">
                        <IconButton className="!bg-gray-100" onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></IconButton>

                        <Typography color="textPrimary" variant='h6' className=' !mt-[5px] NunitoSans800 !font-semibold'>{translateLanguage("OPPORTUNTIY_DETAIL")}</Typography>
                    </Grid>
                    {
                        !opportunityData.loading &&
                        <>
                            <OpportunityGroomInfo opportunityData={opportunityData.data} />
                            {/* <OpportunityLeaderInfo opportunityData={opportunityData.data} /> */}
                            <Box className="!py-5 !w-full" >


                                <Box className=" commonShadow px-4 py-2 flex items-baseline gap-3 commonBorderRadius !mb-6 !rounded-md !bg-white">
                                    <Typography color="textPrimary" variant='h6' className=' !mt-[5px] !text-lg NunitoSans800 !font-semibold'>{translateLanguage("TITLE")}: </Typography>
                                    {opportunityData?.data?.title?.length !== 0 ? (
                                        <>
                                            <Typography color="textPrimary" variant='h6' className=' !mt-[5px] !text-base NunitoSans800 !text-[var(--gray-font)]'>{opportunityData?.data?.title}</Typography>
                                        </>
                                    ) : (
                                        "---"
                                    )}
                                </Box>

                                < Box className=" !gap-4">
                                    <Box
                                        // sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
                                        className="lg:!flex commonShadow commonBorderRadius !justify-between !rounded-md !bg-white"
                                    >
                                        <Box className="!p-5 lg:!flex !grid !items-center  !justify-between !w-full">
                                            <Box className="lg:!border-l-2 lg:!pl-3">
                                                {leftFields.map((field, index) => (
                                                    <Box key={index} className="xl:!flex !grid xl:!gap-10 !gap-1">
                                                        <Typography variant="subtitle1" className="!min-w-[180px] NunitoSans600 text-[var(--gray-font)] xl:!mt-0 !mt-3 ">
                                                            {field.label}
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle2"
                                                            className="!text-muted NunitoSans700 !pt-1 textOverflowTwoLines !font-semibold"
                                                        >
                                                            {field.value}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>

                                            <Box className="lg:!border-l-2 lg:!pl-3">
                                                {rightFields.map((number, index) => (
                                                    <Box key={index} className="xl:!flex !grid xl:!gap-10 !gap-1">
                                                        <Typography variant="subtitle1" className="!min-w-[180px] NunitoSans600 text-[var(--gray-font)] xl:!mt-0 !mt-3 ">
                                                            {number.label}
                                                        </Typography>

                                                        <Typography
                                                            variant="subtitle2"
                                                            className="!text-muted NunitoSans700 !pt-1 !font-semibold"
                                                        >
                                                            {number.value}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                </Grid>


                <Grid item xs={12} className="!mt-4 !grid !gap-3 !px-5">
                    <Typography color="textPrimary" variant='caption' className='!text-base NunitoSans800 !mt-3 !font-semibold'>
                        {translateLanguage("CONTRIBUTORS")}
                    </Typography>

                    <Box className="xxl:!flex grid-cols-1 lg:!grid-cols-2 xxl:!grid-cols-4 !grid !mb-5 !items-center !px-5 lg:!px-0 !gap-4 "
                    // style={{ gridTemplateColumns: "1fr " }}
                    >

                        <Typography variant="h3" sx={{
                            background: "var(--amount-green-light)",
                            // border: "2px solid #c3f1c3"
                        }} className="!font-semibold NunitoSans800 commonButtonRadius text-[var(--amount-green)] lg:!ml-1 !text-sm md:!text-base !whitespace-nowrap  sm:!px-5 !px-2 !py-[6px]">
                            {translateLanguage("BASE_AMOUNT")}:&nbsp;
                            <span className="!font-semibold NunitoSans800 !text-base  md:!text-lg ">
                                {opportunityData.data.groomBasicInfo.totalBaseAmount || 0}
                            </span>
                        </Typography>

                        <Typography variant="h3" sx={{
                            background: "var(--violet-amount-light)",
                            // border: "2px solid #f1f18b"
                        }} className="!font-semibold NunitoSans800 commonButtonRadius lg:!ml-1  text-[var(--violet-amount)] !text-sm md:!text-base !whitespace-nowrap  sm:!px-5 !px-2 !py-[6px]">
                            {translateLanguage("FEES")}:&nbsp;
                            <span className="!font-semibold NunitoSans800 !text-base  md:!text-lg ">
                                {(opportunityData.data.groomBasicInfo.totalFeesRecived || 0).toFixed(2)}
                            </span>
                        </Typography>


                        <Typography variant="h3" sx={{
                            background: "var(--yellow-dark-light)",
                            // border: "2px solid #ade7fa"
                        }} className="!font-semibold NunitoSans800 commonButtonRadius lg:!ml-1  text-[var(--yellow-dark)] !text-sm md:!text-base !whitespace-nowrap   sm:!px-5 !px-2 !py-[6px]">
                            {translateLanguage("TOTAL_AMOUNT")}:&nbsp;
                            <span className="!font-semibold NunitoSans800 !text-base  md:!text-lg ">
                                {(opportunityData.data.groomBasicInfo.totalAmountRecieved || 0).toFixed(2)}
                            </span>
                        </Typography>

                        <Button style={{ border: "1px solid " }}
                            // onClick={downloadExcelSheetOpportunitiesPage}
                            className='!whitespace-nowrap !font-semibold NunitoSans800 !text-base  !rounded !bg-opacity-15 !bg-blue-500 !text-blue-500 !py-2 !border-2 !border-blue-500 !px-3'>
                            <FileDownloadIcon className='mr-2' />
                            <CSVLink data={csvDataOpportunitiesPage} filename={'GroomPaymentReport.csv'}>
                                {translateLanguage("DOWNLOAD_REPORT")}
                            </CSVLink>
                        </Button>

                        <Button onClick={handleTransfer} className="!font-semibold NunitoSans800 commonButtonRadius !text-[var(--white)] !bg-[var(--primary)] !min-w-[130px] sm:!px-8 !px-4 !flex !gap-2 !py-2 !text-sm md:!text-base !whitespace-nowrap" variant="contained" >
                            {translateLanguage("TRANSFER")} <IosShareIcon className="!w-4 !h-4" />
                        </Button>

                    </Box>

                </Grid>
                <div style={{ width: '100%', maxWidth: "2100px" }} className='!px-5 dataGridTable oppurtunitiesGridTable  !mt-2'>
                    <DataGrid
                        rows={opportunityData.data.contributers}
                        columns={columns}

                        sx={{

                            '& .MuiDataGrid-columnHeader': {
                                // backgroundColor: "#60c4f08f",
                                fontWeight: 1000,
                                fontSize: 16,
                                lineHeight: 1,
                                whiteSpace: "break-spaces",
                                textOverflow: "clip",
                            },

                            // height: "90vh",
                            minHeight: "fit-content",
                        }}
                        autoHeight={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        loading={tableLoader}
                        getRowId={(row) => row.id}
                        hideFooter={opportunityData.data.contributers.length < 10}
                        pageSizeOptions={[10]}
                        className='feedbackDatagrid tableHeaderColor commonBorderRadius commonShadow'
                        localeText={customLocaleText}
                        paginationMode={'client'}
                    />
                </div>
            </Box >
            {/* </Container> */}
        </>
    )
}

export default OpportunityDetail
