import { Box, Button, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import ForwardIcon from '@mui/icons-material/Forward';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { translateLanguage } from '../../translation';

interface GroomTransferInterface {
    transferModal: {
        open: boolean;
        totalBaseAmountRecived: number;
        totalBaseAmountTransfered: number;

        totalOpportunity?: number;
        totalAmountRecievedOpportuntiy?: number;
        totalAmountRecievedIndividually?: number;
    }
    setTransferModal: any
    callBackTransfer: any
}
export default function GroomTransfer(props: GroomTransferInterface) {
    const { transferModal, setTransferModal, callBackTransfer } = props;

    const [PaymentTextInput, setPaymentTextInput] = useState("");
    const remarksText = useRef<HTMLInputElement>(null)

    const handleCloseModal = () => {
        setTransferModal({
            ...transferModal,
            open: false
        })
        setPaymentTextInput("")
    }



    return (
        <Modal
            open={transferModal.open}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            className="!right-3 !left-3"
            aria-describedby="modal-description"
        >
            <Box className="!rounded-md !py-6 !px-8" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: 770, bgcolor: 'background.paper', boxShadow: 24, }}>
                <Typography id="modal-title" variant="h6" component="h2" className="!font-semibold NunitoSans800 !flex !justify-between !items-center">
                    {translateLanguage("TRANSFER_MONEY")}:
                    <IconButton onClick={handleCloseModal}>
                        <ClearIcon />
                    </IconButton>
                </Typography>

                <Box className="!grid !gap-4 !justify-betweesn !w-full !items-centewr !mt-0">
                    <Box className=" !grid !gap-4 !justify-betweesn !w-full !items-centewr !mt-7 !overflow-x-auto">

                        <Typography variant="h4" sx={{
                            background: "var(--yellow-dark-light)",
                        }} className=" !w-full !text-[var(--yellow-dark)] !h-fit !font-semibold NunitoSans800 !whitespace-nowrap !text-base  commonButtonRadius  !px-5 !py-[14px]">
                            <span className="!flex !gap-1 !items-center">
                                {translateLanguage("TOTAL_AMOUNT")}:
                                <span className="!font-semibold NunitoSans800 !text-lg ">
                                    {" "} {(transferModal.totalBaseAmountRecived).toFixed(2)}
                                </span>
                                <CheckCircleOutlineIcon className=" !ml-auto !text-[var(--yellow-dark)]" />
                            </span>

                            {/* <span className="!flex !gap-1 !items-center">
                                Individual ({
                                    transferModal.totalAmountRecievedIndividually
                                }),
                                Amount received on {transferModal.totalOpportunity} opportunities({transferModal.totalAmountRecievedOpportuntiy}).
                            </span> */}
                        </Typography>


                        <Typography variant="h4" sx={{
                            // boxShadow: "0px 0px 15px rgba(0, 0, 0, 0)",
                            background: "var(--red-error-light)",
                            // border: "2px solid #ee71715c"
                        }} className="!w-full !text-[var(--red-dark)] !h-fit !font-semibold NunitoSans800 !whitespace-nowrap commonButtonRadius !text-base   !px-5 ">
                            {/* <span className="!flex !gap-1 !items-center">
                            Amount received on
                                <span className="!font-semibold NunitoSans800 !text-lg ">
                                {transferModal.totalOpportunity}
                                </span>
                                opportunities
                                ({transferModal.totalAmountRecievedOpportuntiy})
                            </span> */}
                            <span className="!flex !gap-1 !items-center">
                                <span className='!border-r-2 !pr-5  NunitoSans800 !border-[var(--red-dark)] !py-[14px]'>
                                    {translateLanguage("INDIVIDUAL")}
                                    ({
                                        transferModal.totalAmountRecievedIndividually
                                    })

                                </span>
                                <span className='!pl-5 !py-[14px] NunitoSans800'>
                                    {translateLanguage("AMOUNT_RECIEVED_ON")}
                                    {transferModal.totalOpportunity}
                                    {translateLanguage("OPPORTUNITIES").toLowerCase()}
                                    ({transferModal.totalAmountRecievedOpportuntiy}).
                                </span>
                                <CheckCircleOutlineIcon className=" !ml-auto !text-[var(--red-dark)]" />
                            </span>
                        </Typography>




                        <Typography variant="h4" sx={{
                            // boxShadow: "0px 0px 15px rgba(0, 0, 0, 0)", 
                            background: "var(--green-transfered-light)",
                            // border: "2px solid #c3f1c3"
                        }} className=" !w-full !h-fit !font-semibold NunitoSans800 !whitespace-nowrap !text-base  commonButtonRadius !px-5 !py-[14px]">
                            <span className="!flex !gap-1 !items-center !text-[var(--green-transfered)]">
                                {translateLanguage("TRANSFERRED_AMOUNT")}:
                                <span className="!font-semibold NunitoSans800 !text-lg ">
                                    {transferModal.totalBaseAmountTransfered}
                                </span>
                                <CheckCircleOutlineIcon className=" !ml-auto !text-[var(--green-transfered)]" />
                            </span>
                        </Typography>


                        <Typography variant="h4" sx={{
                            // boxShadow: "0px 0px 15px rgba(0, 0, 0, 0)", 
                            background: "var(--lightblue-balance-light)",
                            // border: "2px solid lightblue"
                        }} className=" !w-full !h-fit !font-semibold NunitoSans800 !whitespace-nowrap !text-base  commonButtonRadius  !px-5 !py-[14px]">
                            <span className="!flex !gap-1 !items-center !text-[var(--lightblue-balance)]">
                                {translateLanguage("BALANCE_AMOUNT")}:
                                <span className="!font-semibold NunitoSans800 !text-lg ">
                                    {(transferModal.totalBaseAmountRecived - transferModal.totalBaseAmountTransfered).toFixed(2)}
                                </span>
                                <CheckCircleOutlineIcon className=" !ml-auto !text-[var(--lightblue-balance)]" />
                            </span>
                        </Typography>
                    </Box>
                    <Box className="!w-full">
                        <TextField
                            fullWidth
                            value={PaymentTextInput}
                            className="!w-full sm:!mt-0 !mt-6"
                            type="number"
                            placeholder={translateLanguage("ENTER_AMOUNT_HERE")}
                            onChange={(e) => setPaymentTextInput(e.target.value)}
                            variant="outlined"
                        />
                        {parseFloat(PaymentTextInput) > (transferModal.totalBaseAmountRecived - transferModal.totalBaseAmountTransfered) && (
                            <Typography variant="subtitle1" className="!text-sm !pt-1 NunitoSans600" style={{ color: 'red' }}>
                                {translateLanguage("BALANCE_AMOUNT_THAT_CAN_BE_TRANSFERRED")}
                                &nbsp;
                                {(transferModal.totalBaseAmountRecived - transferModal.totalBaseAmountTransfered
                                ).toFixed(2)}
                            </Typography>
                        )}
                    </Box>

                    <Box className="!w-full">
                        <TextField
                            fullWidth
                            placeholder={translateLanguage("ENTER_REMARKS_HERE")}
                            title="Remarks"
                            type="text"
                            inputRef={remarksText}
                        />
                    </Box>
                </Box>

                <Box className="!mb-4 !mt-8 !w-full" display="flex" justifyContent="center">
                    <Button
                        onClick={() => {
                            callBackTransfer(PaymentTextInput, remarksText.current?.value.trim())
                            handleCloseModal()
                        }
                        }
                        className="!font-semibold !px-8 commonButtonRadius NunitoSans800 !flex !gap-2 !py-3 !text-base"
                        variant="contained"
                        color="success"
                        disabled={!PaymentTextInput || parseFloat(PaymentTextInput) > (transferModal.totalBaseAmountRecived - transferModal.totalBaseAmountTransfered)}
                    >
                        {translateLanguage("TRANSFER")} <ForwardIcon />
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
