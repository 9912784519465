import React, { useContext, useState } from "react";
import { Box, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./GroomInfo.css";
import ProfileNameEmailUser from "../../../components/common/ProfileNameEmailUser";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ContextInterface } from "../../../services/dataType/api_interfaces/users";
import { UserDataContext } from "./UserDetail";
import { EnhancedTableHead } from "../../../components/common/tableFunctions";
import ViewIconButton from "../../../components/common/ViewIconButton";
import moment from 'moment';
import { translateLanguage } from "../../../translation";


interface Opportunity {
    total_payments_recieved_to_groom?: number;
    total_payments_recieved_on_post?: number,
    total_amount_given_to_palace?: number,
    total_amount_given_to_groom?: number,

    id?: any;
    groom?: {
        name?: string;
        email?: string;
        photo?: string;
    };
    created_at?: any;
    relation_with_groom?: {
        relation?: any;
    };
    total_fees_recieved?: string;
    title?: string;
    contributors?: any[];
    weddingDate: any;
}

const headCells = [
    { id: 'user_detail;', label: translateLanguage("GROOM"), sortable: false },
    {
        id: 'wedding_date;',
        label: (
            <>
                <span className='!whitespace-nowrap'>{translateLanguage("WEDDING_DATE")}Wedding Date</span>
            </>
        ) as any,
        sortable: true
    },
    { id: 'title', label: translateLanguage("TITLE"), sortable: false },
    { id: 'contributors', label: translateLanguage("CONTRIBUTORS"), sortable: false },
    { id: 'relation', label: translateLanguage("RELATION"), sortable: false },
    {
        id: 'total_payments_recieved_on_post',

        label: (
            <>
                <span className='!whitespace-nowrap'>{translateLanguage("BASE_AMOUNT")} ({translateLanguage("OPPORTUNITY")})</span>
            </>
        ) as any,

        sortable: true
    },
    { id: 'total_fees_recieved', label: translateLanguage("FEES"), sortable: true },
    {
        id: 'total_amount_given_to_palace',
        label: (
            <>
                <span className='!whitespace-nowrap'>{translateLanguage("AMOUNT_TRANSFERRED")} ({translateLanguage("PALACE")})</span>
            </>
        ) as any,
        sortable: false
    },
    { id: 'action', label: 'Action', sortable: false },
];

function PalacePostTable() {
    // const formatDate = (dateString: string) => {
    //     const date = new Date(dateString);
    //     const options: Intl.DateTimeFormatOptions = {
    //         day: "2-digit",
    //         month: "long",
    //         year: "numeric",
    //     };
    //     return date.toLocaleDateString("en-US", options);
    // };

    const contextValue: ContextInterface = useContext(UserDataContext);
    const { userData } = contextValue

    const [sortedData, setSortedData] = useState(userData.data.palaceBasicInfo.opportunityList as Opportunity[] || [])

    if (sortedData.length === 0) {
        return <Typography variant="h6" className="!justify-center !flex !py-8">No payments found</Typography>;
    }

    return (
        <>
            <Table>
                <EnhancedTableHead
                    headCells={headCells}
                    sortedData={sortedData}
                    setSortedData={setSortedData}
                    dependentEffect={userData?.data?.palaceBasicInfo?.opportunityList}
                />
                <TableBody>
                    {sortedData.length && sortedData.map((data, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Link to={`/users/detail/${data.id}`} style={{ textDecoration: 'none' }} >
                                    <ProfileNameEmailUser profileImageAvatar={data?.groom?.photo} profileName={data?.groom?.name} />
                                </Link>
                            </TableCell>
                            <TableCell className="!whitespace-nowrap">
                                {/* {formatDate(data.created_at)} */}
                                {moment(data.weddingDate).format('YYYY-MM-DD')}

                            </TableCell>
                            <TableCell className="!w-full">
                                <Box className="textOverflowTwoLines !min-w-[200px] !whitespace-normal">
                                    {data.title?.length !== 0 ? (
                                        <>
                                            {data?.title}
                                        </>
                                    ) : (
                                        "---"
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell className="!text-center">{data.contributors}</TableCell>
                            <TableCell className="!whitespace-nowrap">{data?.relation_with_groom?.relation}</TableCell>
                            <TableCell className="!whitespace-nowrap !text-center">
                                {data.total_payments_recieved_on_post}
                            </TableCell>
                            <TableCell className="!text-center">
                                {data.total_fees_recieved}
                            </TableCell>
                            <TableCell className="!text-center">
                                {data.total_amount_given_to_palace}
                            </TableCell>
                            <TableCell className="!text-center">
                                <Link to={`/opportunity/detail/${data.id}`}>
                                    <ViewIconButton />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export default PalacePostTable;

