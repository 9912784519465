import {
  Container,
  Typography,
  Avatar,
  Chip,
  Box,
  IconButton,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useState } from "react";
import { defaultOtpAPI } from "../../../services/api_services/users";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { translateLanguage } from "../../../translation";

const verifiedIcon = (
  <>
    <CheckCircleOutlinedIcon className="!w-5 !h-5" sx={{ fill: "green" }} />
  </>
);

const unVerifiedIcon = (
  <>
    <NewReleasesOutlinedIcon className="!w-5 !h-5" sx={{ fill: "red" }} />
  </>
);

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export default function BasicInfo(props: any) {
  const { userData } = props;
  const navigate = useNavigate();

  const [defaultOtpChangeStatus, setDefaultOtpChangeStatus] = useState(
    userData.defaultOtp
  );

  const handleDefaultOtpToggle = async () => {
    const successHitSwitchAPI = await defaultOtpAPI(userData.id);
    if (successHitSwitchAPI) {
      setDefaultOtpChangeStatus(!defaultOtpChangeStatus);
    }
  };

  return (
    // <Container className="py-5 defaultBasicInfo" sx={{ maxWidth: "2000px" }}>
    <Box className="py-5 !px-5 defaultBasicInfo" sx={{ maxWidth: "2000px" }}>
      <Box className="!flex !justify-between !items-center !mb-4">

        <Typography color="textPrimary" variant='h6' className='!mt-2 !gap-2 !flex !items-center NunitoSans800 !font-semibold'>
          <IconButton className="!bg-gray-100 " onClick={() => navigate(-1)}><KeyboardBackspaceIcon /></IconButton>
          {translateLanguage("USER_PROFILE")}
        </Typography>

        {/* <FormGroup>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={defaultOtpChangeStatus}
                onChange={handleDefaultOtpToggle}
              />
            }
            label="Default OTP Toggle"
          />
        </FormGroup> */}
      </Box>

      <Box
        // sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
        className=" xl:flex justify-between  commonBorderRadius commonShadow  !bg-white"
      >
        <Box className="xl:!flex !grid xl:!gap-12 !gap-4 p-5 items-center h-full">
          <Box className="relative w-fit">
            <Avatar
              alt={userData.name}
              src={userData.photo}
              className="rounded-circle xl:!w-[100px] xl:!h-[100px] !h-[80px] !w-[80px]"
            />

            <Chip
              className="!absolute !bottom-0 !right-[-20px] NunitoSans700 !pt-[2px] !h-[23px]"
              label={userData.isActive ?
                translateLanguage("ACTIVE")

                :
                translateLanguage("INACTIVE")

              }
              sx={{
                fontWeight: "600",
                color: "white",
                backgroundColor: `${userData.isActive ? "#32cd32" : "#ff7777fa"
                  }`,
              }}
            />
          </Box>

          <Box>
            <Typography variant="body1" className="!text-muted NunitoSans700 textOverflowTwoLines">
              {userData.name}
            </Typography>
            {/* <Typography
              variant="body1"
              className="text-muted flex gap-1 items-center NunitoSans500 "
            >
              {userData.email}
              {userData.isEmailVerified ? verifiedIcon : unVerifiedIcon}
            </Typography> */}
          </Box>
        </Box>

        <Box className="p-5 flex items-center">
          <Box className="lg:border-l-2 lg:pl-3">
            <Box className="flex gap-10">
              <Typography
                variant="subtitle1"
                className="md:min-w-[100px] min-w-[60px] NunitoSans600 text-[var(--gray-font)] "
              >
                {translateLanguage("PHONE")}

              </Typography>

              <Typography
                variant="body1"
                className="text-muted flex gap-1 items-center NunitoSans700 !font-semibold"
              >
                {userData.countryCode} {userData.phoneNumber}
                {userData.isPhoneVerified ? verifiedIcon : unVerifiedIcon}
              </Typography>
            </Box>

            <Box className="flex gap-10">
              <Typography
                variant="subtitle1"
                className="md:min-w-[100px] min-w-[60px] NunitoSans600 text-[var(--gray-font)] "
              >
                {translateLanguage("STATE")}
              </Typography>

              <Typography variant="body1" className="text-muted textOverflowTwoLines NunitoSans700 !font-semibold">
                {userData.state}
              </Typography>
            </Box>

            <Box className="flex gap-10">
              <Typography
                variant="subtitle1"
                className="md:min-w-[100px] min-w-[60px] NunitoSans600 text-[var(--gray-font)] "
              >
                {translateLanguage("CITY")}
              </Typography>

              <Typography variant="body1" className="text-muted textOverflowTwoLines NunitoSans700 !font-semibold">
                {userData.city}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    // </Container>
  );
}
