import { Box, Button } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomTextInputField from './customTextInputField';
import { translateLanguage } from '../../translation';

const TextDialogBox = (props: any) => {
    const { dialogState, setDialogState } = props;

    const validate = (data: any) => {
        const errors: any = {};

        if (data.title && data.title.trim() && data.title.trim().length > (dialogState.title_length ?? 10)) {
            errors.title = `${dialogState.modeType} ${dialogState.formType} ${translateLanguage("CANNOT_BE_MORE_THAN")} ${dialogState.title_length ?? 10} ${translateLanguage("CHARACTERS")}`;
        }
        return errors;
    };

    const AddEditModalClose = () => {
        setDialogState({
            open: false,
        });
    }

    return (
        <Dialog
            open={dialogState.open}
            PaperProps={{
                sx: {
                    minWidth: '30%',
                    backgroundColor: '#e1e4e6',
                    width: '80%',
                },
            }}
            onClose={() => {
                setDialogState({
                    ...dialogState,
                    open: false,
                });
            }}
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'down',
                timeout: {
                    enter: 600,
                    exit: 600,
                },
                easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
            } as Partial<TransitionProps>}
        >
            <DialogTitle style={{ fontWeight: 600, fontSize: '1.3rem' }} className='NunitoSans800'>{dialogState.modeType} {dialogState.formType}</DialogTitle>
            <DialogContent>
                {dialogState.open ? (
                    <Form
                        onSubmit={dialogState.onSubmitCallback}
                        validate={validate}
                        initialValues={{ title: dialogState.existingValue }}
                    >
                        {(props: any) => (
                            <form onSubmit={props.handleSubmit}>
                                <Box component="div" className="row m-t-30">
                                    <div className="col-12 md-col-12">
                                        <Field
                                            name="title"
                                            component={CustomTextInputField}
                                            type="text"
                                            placeholder={`${translateLanguage("ENTER")} ${dialogState.formType} ${translateLanguage("HERE")}`}
                                            required={true}
                                            rows="3"
                                            title={dialogState.formType}
                                        />
                                        {dialogState.note && (
                                            <p className='NunitoSans500' style={{ fontSize: '15px', color: 'gray' }}>
                                                <span className='!text-red-500 NunitoSans500'>
                                                    {translateLanguage("NOTE")}:</span> {dialogState.note}
                                            </p>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '20px',
                                        }}
                                    >
                                        <Button onClick={AddEditModalClose} type="button" variant="contained" className='!border-2 NunitoSans800 !mr-4 commonButtonRadius !bg-[var(--cancel-gray)] !px-6 !py-[8px] !font-semibold  !text-base '>
                                            {translateLanguage("CANCEL")}
                                        </Button>
                                        <Button type="submit" variant="contained" className=' !bg-[var(--primary)] NunitoSans800 !font-semibold !px-8 !py-[8px] commonButtonRadius !text-[var(--white)] !text-base'>
                                            {/* {dialogState.modeType} {dialogState.formType} */}
                                            {translateLanguage("SAVE")}
                                        </Button>
                                    </div>
                                </Box>
                            </form>
                        )}
                    </Form>
                ) : (
                    ''
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TextDialogBox;
