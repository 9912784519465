import { APP_VERSION } from "../../constants";
import { store } from "../../store";
import { ShowProgressBar, ShowSuccessAlert } from "../../store/alerts";
import { translateLanguage } from "../../translation";
import { getRequest, postRequest } from "../../utils/services";


export const getAppVersionApi = async (setAppVersion: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await getRequest((APP_VERSION));

    if (response) {
        let responseData = response.data
        setAppVersion({
            android_version: responseData.android_version,
            ios_version: responseData.ios_version
        })
        return true;
    }
};


export const setAppVersionApi = async (requestData: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((APP_VERSION), requestData);

    if (response) {
        let responseData = response.data
        dispatch(ShowSuccessAlert({ visible: true, message: translateLanguage("APP_VERSION_UDPATED_SUCCESS") }));

        return true;
    }
};