import { Box, Button } from '@mui/material';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { translateLanguage } from '../../translation';


const DatePicker = (props: any) => {
    const { dialogState, setDialogState } = props

    function handleSelect(ranges: any) {
        setDialogState({
            ...dialogState,
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
        })
    }

    const selectionRange = {
        startDate: dialogState.startDate,
        endDate: dialogState.endDate,
        key: 'selection',
    }

    const handleSubmit = () => {
        setDialogState({
            ...dialogState,
            open: false,
            triggerApi: true
        })
    };

    return (
        <>
            <Dialog open={dialogState.open}
                PaperProps={{
                    sx: {
                        minWidth: "41%",
                        backgroundColor: "#e1e4e6",
                        height: "67vh",
                        minHeight: "400px",
                        width: "77%", // Adjust the width as needed
                    }
                }}


                onClose={() => {
                    setDialogState({
                        ...dialogState,
                        open: false
                    })
                }}
                TransitionComponent={Slide} TransitionProps={{
                    direction: 'down',
                    timeout: {
                        enter: 600,
                        exit: 600,
                    },
                    easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                } as Partial<TransitionProps>}
            >
                <DialogTitle className='NunitoSans800' style={{ fontWeight: 600, fontSize: '1.3rem' }}>
                    {translateLanguage("DATE_RANGE")}
                </DialogTitle>
                <DialogContent className='!px-[20px]'>
                    {
                        dialogState.open ?
                            <>

                                <DateRangePicker
                                    // e-date-range-wrapper="NunitoSans600"
                                    // e-calendar="NunitoSans600"
                                    // e-right-calendar="NunitoSans600"
                                    // e-left-calendar="NunitoSans600"
                                    // e-day-span="NunitoSans600"
                                    rangeColors={['var(--primary)']}
                                    ranges={[selectionRange]}
                                    onChange={handleSelect}
                                />
                            </>

                            :
                            ""}
                </DialogContent>
                <DialogActions
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button variant="outlined" className='!text-[var(--primary)] NunitoSans800 !px-4 !font-semibold !border-[var(--primary)]' onClick={() => {
                        setDialogState({
                            ...dialogState,
                            open: false
                        })
                    }}>
                        {translateLanguage("CANCEL")}
                    </Button>
                    <Button type="submit" variant="contained" className='!text-[--var(white)] NunitoSans800 !px-6 !font-semibold !bg-[var(--primary)]' onClick={handleSubmit}>
                        {translateLanguage("SAVE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};



export default DatePicker;