export interface listRequestInterface {
    pageLimit: number;
    pageNumber: number;
    searchText?: string;
    sortBy?: string;
    sortType?: string;
    filterType?: string
    startDate?: Date;
    endDate?: Date;
    cardStatus?: string;

}

export const usersListRequest = (data: listRequestInterface) => {
    let requestData = {
        page_limit: data.pageLimit ?? 10,
        page_number: data.pageNumber ?? 1,
        search_text: data.searchText ? data.searchText.trim() : "",
        sort_by: data.sortBy ?? "",
        sort_type: data.sortType ?? "",
        filter_type: data.filterType ?? "all",
    };
    return requestData;
};

export interface usersListResponseInterface {
    id: string;
    name: string;
    email: string;
    phone_no: string;
    is_active: boolean;
    is_email_verified: boolean;
    is_phone_verified: boolean;
    photo: string;
    is_groom_profile: boolean;
    is_groom_payments: boolean;
    country_code: string;
    wedding_date: string;
    is_groom_profile_verified: boolean;
    is_groom_amount_transferred: boolean;
    is_palace_profile: boolean;
    is_blocked: boolean;
}



export interface UserStateInterface {
    data: {
        userBasicInfo: {
            isGroomProfile: boolean,
            isPalaceProfile: boolean,
        },
        groomBasicInfo: {
            opportunityList?: []
            adminPaymentsRecieved?: []
        },
        groomPaymentsInfo: [],
        palaceBasicInfo: {
            // opportunityList(opportunityList: any, arg1: string): unknown;
            paymentsInfo?: []
            opportunityList?: []
        },
    },
    loading: boolean,
}

export interface ContextInterface {
    userData: UserStateInterface,
    setUserData: any
}

export interface PaymentDetailResponse {
    groomBasicPaymentInfo: {
        id: string;
        paymentType: string;
        baseAmount: string;
        isNotifyAress: boolean;
        sendPayNotiToAress: boolean;
        sendCongoCard: boolean;
        totalAmount: string;
        time: string;
        paymentId: string;
        notifiedArres: any;
        sendPaymentNotification: any;
        sendCongratsCard: any;
        deliverySpecialWay: any;
    };
    groomPaymentDonarInfo: {
        id: string;
        name: string;
        email: string;
        phoneNo: string;
        photo: string;
    };
}

export const formatPaymentDetailResponse = (data: any): PaymentDetailResponse => {
    const groomBasicPaymentInfo = {
        id: data.id,
        paymentType: data.payment_type,
        baseAmount: data.base_amount,
        isNotifyAress: data.is_notify_aress,
        sendPayNotiToAress: data.send_pay_noti_to_aress,
        sendCongoCard: data.send_congo_card,
        totalAmount: data.total_amount,
        time: data.time,
        paymentId: data.payment_id,
        notifiedArres: data.is_notify_aress,
        sendPaymentNotification: data.send_pay_noti_to_aress,
        sendCongratsCard: data.send_congo_card,
        deliverySpecialWay: data.deliver_specially,

    };

    const groomPaymentDonarInfo = {
        id: data.donor_info.id,
        name: data.donor_info.name,
        email: data.donor_info.email,
        phoneNo: data.donor_info.phone_no,
        photo: data.donor_info.photo,
    };

    return { groomBasicPaymentInfo, groomPaymentDonarInfo };
};


export const formatUserDetailResponse = (data: any) => {
    let responseData: any = {
        userBasicInfo: {
            id: data.id,
            name: data.name,
            email: data.email,
            phoneNumber: data.phone_no,
            countryCode: data.country_code,
            weddingDate: data.wedding_date,
            // isGroomProfileTransferred: data.is_groom_amount_transferred,
            // isGroomProfileVerified: data.is_groom_profile_verified,
            photo: data.photo,
            isEmailVerified: data.is_email_verified,
            isPhoneVerified: data.is_phone_verified,
            isActive: data.is_active,
            isGroomProfile: data.is_groom_profile,

            isGroomPayments: data.is_groom_payments,
            defaultOtp: data.is_default_otp_enabled,
            isPalaceProfile: data.is_palace_profile,
            state: data.state.state,
            city: data.state?.city?.city,
        },
    };

    if (data.is_groom_profile) {
        responseData["groomBasicInfo"] = {
            id: data.groom_profile.id,
            weddingHallName: data.groom_profile.wedding_hall_name,
            lattitude: data.groom_profile.latitude,
            longitude: data.groom_profile.longitude,
            weddingDate: data.groom_profile.wedding_date,
            bankAccount: data.groom_profile.bank_account,
            recipientName: data.groom_profile.recipient_name,
            accountNo: data.groom_profile.account_no,
            ibanNumber: data.groom_profile.iban_no,
            // isGroomProfileTransferred: data.groom_profile.is_groom_amount_transferred,
            // isGroomProfileVerified: data.groom_profile.is_groom_profile_verified,
            gender: data.groom_profile.gender,
            opportunityList: data.groom_profile.opportunity_list,
            totalBaseAmountRecived: data.groom_profile.total_base_amount_received,
            totalBaseAmountTransfered: data.groom_profile.total_base_amount_transfered,
            adminPaymentsRecieved: data.groom_profile.groom_payments_recieved
        };
    } else {
        responseData["groomBasicInfo"] = {};
    }

    responseData["groomPaymentsInfo"] = data.groom_payments.map(
        (payment: any) => ({
            id: payment.id,
            time: payment.time,
            totalAmount: payment.total_amount,
            paymentType: payment.payment_type,
            baseAmount: payment.base_amount,
            fees: payment.fees,

            donorInfo: {
                country_code: payment.donor_info.country_code,
                email: payment.donor_info.email,
                id: payment.donor_info.id,
                name: payment.donor_info.name,
                phone_no: payment.donor_info.phone_no,
                photo: payment.donor_info.photo,
                isDonorAnonymous: payment.donor_info.is_donor_anonymous,
            },
            opportunity_info: {
                leaderName: payment.post_info?.leader_name,
                leaderEmail: payment.post_info?.leader_email,
                leaderPhone: payment.post_info?.leader_phone,
                leaderCountryCode: payment.post_info?.leader_country_code,
                opportunityAddedOn: payment.post_info?.post_added_on,
                opportunityId: payment.post_info?.post_id,
            }
        })
    );


    if (data.is_palace_profile) {
        responseData["palaceBasicInfo"] = {
            id: data.palace_profile.id,
            weddingHallName: data.palace_profile.wedding_hall_name,
            latitude: data.palace_profile.latitude,
            longitude: data.palace_profile.longitude,
            placeName: data.palace_profile.place_name,
            opportunityList: data.palace_profile.opportunity_list,
            paymentsInfo: data.palace_profile.palace_payments_recieved

        };
    } else {
        responseData["palaceBasicInfo"] = {};
    }

    return responseData;

};

export interface MakePaymentRequest {
    user_id: any | string;
    payment_to: string;
    amount_transfer: string;
    opportunity_id?: string
    remarks?: string;
}