import { opportunityInfoInterface } from "../../services/dataType/reduxActionsInterfaces/opportunityStoreInterfaces";
import {
    UpdateOpportunityListType,
    ResetOpportunityStoreType,
    UpdateTotalOpportunityCountType
} from "./types";



export const SaveOpportunityList = (payload: opportunityInfoInterface[]): UpdateOpportunityListType => ({
    type: "UPDATE_OPPORTUNITY_LIST",
    payload: payload
});

export const SaveTotalOpportunityCount = (payload: number): UpdateTotalOpportunityCountType => ({
    type: "UPDATE_OPPORTUNITY_COUNT",
    payload: payload
});

export const ResetOpportunityStore = (): ResetOpportunityStoreType => ({
    type: "RESET_OPPORTUNITY_STORE"
});

