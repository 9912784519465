import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";


import { useSelector } from 'react-redux';
import { RootStateType } from './store';

// Pages Imports Here
import SignIn from './pages/Signin/SignIn';
import DashboardLayout from './pages/Layout/DashboardLayout';
import Dashboard from './pages/Dashboard/Dashboard';
import UserList from "./pages/Users/UserList";
import OpportunityList from "./pages/Opportunity/OpportunityList";
import StateCityList from "./pages/States/StateCityList";
import UserDetailsPage from "./pages/Users/UserDetail/UserDetail";
import OpportunityDetail from "./pages/Opportunity/OpportunityDetail/OpportunityDetail";
import RelationList from "./pages/Relations/RelationList";
import ToastAlertList from "./pages/ToastAlert/ToastAlertList";
import CustomCardsList from "./pages/CustomCards/CardsList";
import Feedback from "./pages/Feedback/Feedback";
import FeaturedServicesList from "./pages/featuredServices/FeaturedServicesList";
import UserPaymentDetail from "./pages/Users/UserDetail/UserPaymentDetail";
import PaymentList from "./pages/payments/PaymentList";
import PendingCustomCard from "./pages/pendingCustomCard/PendingCustomCard";
import PendingCardDetail from "./pages/pendingCustomCard/pendingCardDetail/PendingCardDetail";




export const PrivateRoute = ({ children, ...rest }: any) => {
  let adminInfo = useSelector((state: RootStateType) => state.adminInfo);
  let accessToken: string = adminInfo.accessToken;

  if (!accessToken) {
    return <Navigate to={{ pathname: "/" }} />;
  }
  return children;
};

export const IsLoggedIn = ({ children, ...rest }: any) => {
  let adminInfo = useSelector((state: RootStateType) => state.adminInfo);
  let accessToken: string = adminInfo.accessToken;

  if (accessToken) {
    return <Navigate to={{ pathname: "/dashboard/" }} />;
  }

  return children;
};

export const PrivateRoutes: any = (
  <>
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/users" element={<UserList />} />
      {/* <Route path="/users/groom" element={<UserList filterType="groom" />} />
      <Route path="/users/palace" element={<UserList filterType="palace" />} /> */}

      <Route path="/users/detail/:userId" element={<UserDetailsPage />} />
      <Route path="/users/detail/user-payment-detail/:paymentId" element={<UserPaymentDetail />} />

      <Route path="/opportunity" element={<OpportunityList />} />
      <Route
        path="/opportunity/detail/:opportunityId"
        element={<OpportunityDetail />}
      />

      <Route path='/state-city' element={<StateCityList />} />
      <Route path='/relation' element={<RelationList />} />
      <Route path='/feedback' element={<Feedback />} />
      <Route path='/toast' element={<ToastAlertList />} />
      <Route path='/custom-card' element={<CustomCardsList />} />

      <Route path='/featured-services' element={<FeaturedServicesList />} />
      <Route path='/payments' element={<PaymentList />} />

      <Route path='/pending-cards' element={<PendingCustomCard />} />
      <Route path='/pending-cards/detail/:pendingCardId' element={<PendingCardDetail />} />




    </Routes>

  </>
);

export default function Routing() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <IsLoggedIn>
                <SignIn />
              </IsLoggedIn>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}
