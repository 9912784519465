import React from 'react';

interface TruncatedTextProps {
    text: string;
    maxLength: number;
}

const TruncatedText = ({ text, maxLength }: any) => {
    // const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    const truncatedText = text;

    return (
        <span title={text}>{truncatedText}</span>
    );
};

export default TruncatedText;
