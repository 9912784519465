import { adminInfoInterface } from "../../services/dataType/reduxActionsInterfaces/adminStoreInterfaces";
import {
    UpdateAdminInfoType,
    ResetAdminInfoType,
} from "./types";



export const SaveAdminInfo = (payload: adminInfoInterface): UpdateAdminInfoType => ({
    type: "UPDATE_ADMIN_INFO",
    payload: payload
});

export const ResetAdminStore = (): ResetAdminInfoType => ({
    type: "RESET_ADMIN_STORE"
});

