import { DataGrid, GridColDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RootStateType } from '../../store';
import { OpportunityListAPI } from '../../services/api_services/opportunity';

import { ShowErrorAlert, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import React from 'react';
import { listRequestInterface } from '../../services/dataType/api_interfaces/opportunity';
import moment from 'moment';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ProfileNameEmailUser from '../common/ProfileNameEmailUser';
import ViewIconButton from '../common/ViewIconButton';
import { UpdateActiveInactiveAPI } from '../../services/api_services/users';
import { translateLanguage } from '../../translation';


const customLocaleText = {
    noRowsLabel: translateLanguage("NO_OPPORTUNITY_FOUND"),
};

interface opportunityDatagridProps {
    searchText: string;
    filterType: string;
}

export default function OpportunityDatagrid(props: opportunityDatagridProps) {


    const generateColumns = (updateActiveStatus: any): GridColDef[] => {
        return [
            { field: "id", headerName: "ID", width: 30, flex: 1, },
            {
                field: 'groomName',
                headerName:
                    (
                        <span className='!pl-3'>{translateLanguage("GROOM")}</span>
                    ) as any, minWidth: 200, flex: 1.4, headerAlign: 'left', sortable: true,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            <ProfileNameEmailUser
                                profileImageAvatar={params.row.groomPhoto} profileName={params.row.groomName} />
                        </>
                    );
                }
            },

            {
                field: 'leaderName', headerName:
                    (
                        <span className='!pl-3'>{translateLanguage("LEADER")}</span>
                    ) as any,
                minWidth: 200, flex: 1.4, headerAlign: 'left', sortable: true,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            <ProfileNameEmailUser profileImageAvatar={params.row.leaderPhoto} profileName={params.row.leaderName} />
                        </>
                    );
                }
            },
            // {
            //     field: 'relationWithGroomText', headerName: 'Relation', minWidth: 110, flex: 0.9, sortable: false,
            //     renderCell: (params) => {
            //         return (
            //             <>
            //                 {params.row.relationWithGroomText ? params.row.relationWithGroomText : "-"}
            //             </>
            //         );
            //     }
            // },

            {
                field: 'title', headerName: translateLanguage("TITLE"), minWidth: 220, flex: 0.9, sortable: false,
                renderCell: (params) => {
                    return (
                        <Box className="textOverflowTwoLines !whitespace-normal">
                            {params.row?.title?.length !== 0 ? (
                                // <Box className="textOverflowTwoLines !inline">
                                <>
                                    {params.row.title}
                                </>
                                // </Box>
                            ) : (
                                "---"
                            )}
                        </Box>
                    );
                }
            },
            {
                field: 'constributersCount', headerName: translateLanguage("CONTRIBUTORS"), minWidth: 130, flex: 0.9, sortable: false,

                renderCell: (params) => {
                    return (
                        <Box className="!pl-10 ">
                            {params.row.contributors?.length}
                        </Box>
                    );
                }
            },
            // {
            //     field: 'totalAmountRecievedOpportunity', headerName:
            //         (
            //             <>
            //                 Base
            //                 <br />
            //                 Amount
            //                 <br />
            //                 (SAR)
            //             </>
            //         ) as any,
            //     minWidth: 110, flex: 0.8, sortable: false
            // },
            // {
            //     field: 'totalFeesRecievedOpportunity', headerName:
            //         (
            //             <>
            //                 Fees
            //                 <br />
            //                 (SAR)
            //             </>
            //         ) as any,
            //     minWidth: 110, flex: 0.8, sortable: false
            // },

            {
                field: 'totalAmountRecievedOpportunity', headerName:
                    (
                        <>

                            {translateLanguage("AMOUNT")}&nbsp;
                            {/* <br /> */}
                            <span className='!text-xs !font-semibold !text-[#566370]'>(SAR)</span>
                        </>
                    ) as any,
                minWidth: 150, flex: 0.8, sortable: true
            },
            {
                field: 'wedding_date', headerName:
                    (
                        <>
                            {translateLanguage("WEDDING_DATE")}&nbsp;
                            {/* Wedding&nbsp;
                            {/* Date */}
                        </>
                    ) as any, minWidth: 180, flex: 0.5, sortable: true,
                renderCell: (params) => {
                    return (
                        <Box>
                            {moment(params.row.weddingDate).format('YYYY-MM-DD')}
                        </Box>
                    );
                }
            },

            {
                field: 'created_at', headerName: translateLanguage("CREATED_AT"), minWidth: 130, flex: 0.9, sortable: true,
                // headerAlign: '',
                renderCell: (params) => {
                    return (
                        <Box className="">
                            {moment(params.row.createdAt).format('YYYY-MM-DD')}
                            {/* &nbsp; */}
                            <br />
                            {moment(params.row.createdAt).format('hh:mm A')}
                        </Box>
                    );
                }
            },


            {
                field: 'action', headerName: translateLanguage("ACTION"), minWidth: 200, flex: 0.5, sortable: true,
                headerAlign: 'center',
                renderCell: (params) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='!gap-3 !items-center !flex'>
                            <Link to={`/opportunity/detail/${params.row.id}`}>
                                <ViewIconButton />
                            </Link>
                            <Button
                                onClick={() => statusChangeModalOpen(params.row.id as string)}
                                className={`flex gap-1 !font-semibold py-[8px] NunitoSans800 !justify-center items-center !px-3 !text-[14px] commonButtonRadius !text-white`}
                                style={{ backgroundColor: params.row.isActive ? "var(--active-green)" : "var(--red)" }}
                            >
                                {params.row.isActive ? translateLanguage("ACTIVE") : translateLanguage("INACTIVE")}
                            </Button>
                        </div>
                    );
                }
            },

        ]
    };

    const { searchText, filterType } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const [refreshAPI, setRefreshAPI] = useState(0)
    const typingTimeoutRef = useRef<number | NodeJS.Timeout | undefined>(undefined);

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            id: false
        });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });


    const [sortState, setSortState] = useState<any>({
        field: "",
        sortType: "",
        active: false
    });

    let opportunityList = useSelector((state: RootStateType) => state.opportunity.opportunityList);
    let totalOpportunityCount = useSelector((state: RootStateType) => state.opportunity.totalOpportunityCount);
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);
    const [statusChange, setStatusChange] = useState(false);
    const [showStatusChagneModal, setShowStatusChangeModal] = useState(false);
    const [selectedOpportunityIdForChangeStatus, setSelectedOpportunityIdForChangeStatus] = useState<string | null>(null);
    const updateActiveStatus = async (id: string, isBlocked: boolean) => {
        const apiRequestData = {
            obj_id: id,
            service_type: "post"
        };
        const response = await UpdateActiveInactiveAPI(apiRequestData);
        if (response) {
            if (isBlocked) {
                dispatch(ShowSuccessAlert({ visible: true, message: translateLanguage("OPPORTUNITY_ACTIVATE") }));
            } else {
                dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("OPPORTUNITY_DISABLE") }));
            }
            setRefreshAPI(refreshAPI + 1);
        }
    };

    const statusChangeModalOpen = (id: string) => {
        setSelectedOpportunityIdForChangeStatus(id);
        setShowStatusChangeModal(true);
        setStatusChange(opportunityList.find(opportunity => opportunity.id === id)?.isActive ?? false);
    };

    const statusChangeModalClose = () => {
        setSelectedOpportunityIdForChangeStatus(null);
        setShowStatusChangeModal(false);
    };

    const confirmChangeStatus = async () => {
        if (selectedOpportunityIdForChangeStatus) {
            await updateActiveStatus(selectedOpportunityIdForChangeStatus, !statusChange);
            statusChangeModalClose();
        }
    };

    // const updateActiveStatus = async (id: string, isBlocked: boolean) => {
    //     const apiRequestData = {
    //         obj_id: id,
    //         service_type: "post"
    //     }
    //     const response = await UpdateActiveInactiveAPI(apiRequestData)
    //     if (response) {
    //         if (isBlocked) {
    //             dispatch(ShowSuccessAlert({ visible: true, message: `Opportunity activated.` }));
    //         } else {
    //             dispatch(ShowErrorAlert({ visible: true, message: `Opportunity disabled.` }));
    //         }
    //         setRefreshAPI(
    //             refreshAPI + 1
    //         )
    //     }
    // }

    const columns = generateColumns(updateActiveStatus);

    // useEffect(() => {
    //     OpportunityListAPI({ pageLimit: 10, pageNumber: 1 })
    // }, [])

    useEffect(() => {
        dispatch(ShowProgressBar());
        const apiRequestData: listRequestInterface = {
            pageLimit: paginationModel.pageSize,
            pageNumber: paginationModel.page + 1,
            searchText: searchText,
            filterType: filterType
        }

        if (sortState.active) {
            apiRequestData['sortBy'] = sortState.field
            apiRequestData['sortType'] = sortState.sortType
        }
        OpportunityListAPI(apiRequestData)
    }, [paginationModel, sortState, filterType, refreshAPI])

    useEffect(() => {
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(() => {
            setPaginationModel({ ...paginationModel, page: 0 });
        }, 1000);
    }, [searchText])


    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {

            let sortField = ""
            switch (sortModel[0].field) {
                case "phoneNumber":
                    sortField = "phone_no"
                    break;

                default:
                    sortField = sortModel[0].field;
                    break;
            }


            setSortState({
                field: sortField,
                sortType: sortModel[0].sort,
                active: true
            })
        }
        else {
            setSortState({
                field: "",
                sortType: "asc",
                active: false
            })
        }
    }, []);
    return (
        <>
            <div style={{ width: '100%', maxWidth: "2100px", overflowX: 'auto' }}>
                <DataGrid
                    sx={{
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        '& .MuiDataGrid-row:hover': {
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: "#60c4f08f",
                            fontWeight: 1000,
                            fontSize: 16,
                            lineHeight: 1,
                            whiteSpace: "break-spaces",
                            textOverflow: "clip",
                        },

                        height: "90vh",
                        minHeight: "70vh"
                    }}

                    autoHeight={true}

                    rows={opportunityList}
                    columns={columns}
                    getRowId={(user) => user.id}
                    rowCount={totalOpportunityCount}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={customLocaleText}
                    columnVisibilityModel={columnVisibilityModel}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    rowHeight={85}
                    className='tableHeaderColor commonBorderRadius commonShadow'
                    sortingMode={'server'}
                    loading={tableLoader}
                    onSortModelChange={handleSortModelChange}

                    disableVirtualization={true}
                    paginationMode={'server'}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    hideFooterSelectedRowCount={true}
                    disableRowSelectionOnClick={true}
                // onRowClick={(props: any) => {
                //     navigate(`/opportunity/detail/${props.id}`, {
                //         state: {
                //             url: "opportunity",
                //             currentPage: paginationModel.page,
                //             pageSize: paginationModel.pageSize,
                //             // searchText: assetSearchText
                //         }
                //     })
                // }}
                />
            </div>

            <Modal open={showStatusChagneModal} onClose={statusChangeModalClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        borderRadius: "8px",
                        boxShadow: 24,
                        p: 4,
                        maxWidth: 540,
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h6" className="!text-xl  NunitoSans800 !mb-5">
                        {/* Status Changed */}
                        {translateLanguage("UPDATE_OPPORTUINTY_STATUS")}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        className="!text-base !font-semibold NunitoSans800"
                    >
                        {translateLanguage("SURE_TO_CHANGE_STATUS")} &nbsp;
                        {
                            <Box className="!items-center !w-full !justify-center !text-center !flex">
                                <Box className="!text-black">
                                    {!statusChange ?
                                        translateLanguage("INACTIVE") :
                                        translateLanguage("ACTIVE")}
                                </Box>
                                &nbsp;to&nbsp;
                                <Box className="!text-black">
                                    {statusChange ?
                                        translateLanguage("INACTIVE") :
                                        translateLanguage("ACTIVE")
                                    }
                                </Box>
                            </Box>
                        }
                    </Typography>
                    <Box className="!flex !items-center !w-full !justify-center !mt-6 !gap-3">
                        <Button
                            onClick={confirmChangeStatus}
                            className={`flex gap-1 !font-semibold !py-[8px] NunitoSans800 !w-full !justify-center items-center !px-3 !text-[16px] commonButtonRadius !text-white`}
                            style={{ backgroundColor: !statusChange ? "var(--active-green)" : "var(--red)" }}
                        >
                            {!statusChange ? translateLanguage("ACTIVE") : translateLanguage("INACTIVE")}
                        </Button>
                        <Button
                            onClick={statusChangeModalClose}
                            className={`flex gap-1 !font-semibold !py-[8px] NunitoSans800 !w-full !bg-[var(--cancel-gray)] !justify-center items-center !px-3 !text-[16px] commonButtonRadius !text-white`}
                            style={{ marginTop: 2 }}
                        >
                            {translateLanguage("CANCEL")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
