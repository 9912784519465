import { featuredServicesInfoInterface } from "../../services/dataType/reduxActionsInterfaces/featuredServices";
import {
    UpdateFeaturedServicesListType,
    ResetFeaturedServicesStoreType,
    UpdateTotalFeaturedServicesCountType
} from "./types";



export const SaveFeaturedServicesList = (payload: featuredServicesInfoInterface[]): UpdateFeaturedServicesListType => ({
    type: "UPDATE_FEATURED_SERVICES_LIST",
    payload: payload
});

export const SaveTotalFeaturedServicesCount = (payload: number): UpdateTotalFeaturedServicesCountType => ({
    type: "UPDATE_FEATURED_SERVICES_COUNT",
    payload: payload
});

export const ResetFeaturedServicesStore = (): ResetFeaturedServicesStoreType => ({
    type: "RESET_FEATURED_SERVICES_STORE"
});

