interface adminLoginRequestInterface {
    email: string;
    password: string;
}

export const adminLoginRequest = (data: adminLoginRequestInterface) => {
    let user = {
        email: (data.email.trim()).toLowerCase(),
        password: data.password.trim(),
    }
    return user;
}

export interface adminLoginResponseInterface {
    username?: string;
    email?: string;
    access_token: string;
    refresh_token: string;
}

