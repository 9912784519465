import { RELATION_ADD, RELATION_LIST } from "../../constants";
import { store } from "../../store";
import { ShowProgressBar } from "../../store/alerts";
import { patchRequest, postRequest } from "../../utils/services";

export const RelationListAPI = async (setStateData: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((RELATION_LIST), {});

    if (response) {
        let responseData = response.data as any
        setStateData(responseData)
        return true;
    }
};


export const SaveRelationAPI = async (requestData: any, callback: any) => {
    const { dispatch } = store
    var response: any = null;

    dispatch(ShowProgressBar());
    response = await patchRequest((RELATION_ADD), requestData);

    if (response) {
        let responseData = response.data as any
        callback(responseData, "edit")
        return true;
    }
};


export const AddNewRelationAPI = async (requestData: any, callback: any) => {
    const { dispatch } = store
    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((RELATION_ADD), {
        relation: requestData
    });

    if (response) {
        return await callback()
    }
};