import { Reducer } from "react";
import { featuredServicesStoreActions, featuredServicesStoreInterface } from "./types";

export const featuredServicesInitialState: featuredServicesStoreInterface = {
    featuredServicesList: [],
    totalFeaturedServicesCount: 0,
}

export const featuredServicesReducer: Reducer<featuredServicesStoreInterface, featuredServicesStoreActions> = (state = featuredServicesInitialState, { type, payload }: featuredServicesStoreActions) => {
    switch (type) {

        case "UPDATE_FEATURED_SERVICES_LIST":
            return {
                ...state,
                featuredServicesList: payload
            };

        case "UPDATE_FEATURED_SERVICES_COUNT":
            return {
                ...state,
                totalFeaturedServicesCount: payload
            };

        case "RESET_FEATURED_SERVICES_STORE":
            return featuredServicesInitialState;

        default:
            return state;
    }
};
