import React, { useState, useContext } from "react";
import { Box, IconButton, Typography, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from 'moment';
import { EnhancedTableHead } from "../../../components/common/tableFunctions";
import { getColorStyles } from "./UserPaymentDetail";
import { UserDataContext } from "./UserDetail";
import { ContextInterface } from "../../../services/dataType/api_interfaces/users";
import ProfileNameEmailUser from "../../../components/common/ProfileNameEmailUser";
import ViewIconButton from "../../../components/common/ViewIconButton";
import { translateLanguage } from "../../../translation";

interface Payment {
    id: any;
    time: any;
    donorInfo: {
        id: any;
        phone_no: string;
        country_code: string;
        name: string;
        email: string;
        photo?: string;
    };
    totalAmount: string;
    user: string;
    date: string;
    amount: string;
    fees: string;
    total: string;
    baseAmount: string;
    paymentType: string;
}


const headCells = [
    { id: 'user', label: translateLanguage("USERS"), sortable: false },
    { id: 'time', label: translateLanguage("DATE"), sortable: true },
    { id: 'paymentType', label: translateLanguage("PAYMENT_TYPE"), sortable: false },
    {
        id: 'baseAmount', label: (<>
            <span className='!whitespace-nowrap'>
                {translateLanguage("BASE_AMOUNT")}
                (SAR)
            </span>
        </>
        ) as any, sortable: true
    },
    {
        id: 'fees', label: (<>
            <span className='!whitespace-nowrap'>
                {translateLanguage("FEES")}

                (SAR)
            </span>
        </>
        ) as any, sortable: true
    },
    {
        id: 'totalAmount', label: (<>
            <span className='!whitespace-nowrap'>
                {translateLanguage("TOTAL_AMOUNT")}

                (SAR)
            </span>
        </>
        ) as any, sortable: true
    },
    { id: 'action', label: translateLanguage("ACTION"), sortable: false },
]
function PaymentTable() {

    const contextValue: ContextInterface = useContext(UserDataContext);
    const { userData } = contextValue;

    const [sortedData, setSortedData] = useState(userData.data.groomPaymentsInfo as Payment[] || [])

    if (sortedData.length === 0) {
        return <Typography variant="h6" className="!justify-center !flex !py-8">
            {translateLanguage("NO_PAYMENT_DATA_FOUND")}
        </Typography>;
    }

    return (
        <Table>
            <EnhancedTableHead
                headCells={headCells}
                sortedData={sortedData}
                setSortedData={setSortedData}
                dependentEffect={userData.data.groomPaymentsInfo}
            />
            <TableBody>
                {sortedData.map((payment, index) => (
                    <TableRow key={index}>
                        <TableCell>
                            <Link to={`/users/detail/${payment.donorInfo?.id}`} style={{ textDecoration: 'none' }} >
                                <ProfileNameEmailUser profileImageAvatar={payment?.donorInfo?.photo} profileName={payment?.donorInfo?.name} />
                            </Link>
                        </TableCell>
                        <TableCell className="!whitespace-nowrap">
                            {moment(payment.time).format('YYYY-MM-DD')}
                            <br />
                            {moment(payment.time).format('h:mm A')}
                        </TableCell>
                        <TableCell className="!whitespace-nowrap">
                            <Typography variant="body1" style={{ ...getColorStyles(payment.paymentType) }} className="!px-[12px] !py-[8px] !w-fit  commonStatusRadius text-muted !text-sm textOverflowTwoLines !font-semibold">
                                {(payment.paymentType === "INDIVIDUAL") ?
                                    translateLanguage("INDIVIDUAL")
                                    : (payment.paymentType === "OPPORTUNITY") ?
                                        translateLanguage("OPPORTUNITY") :
                                        payment.paymentType
                                }
                            </Typography>
                        </TableCell>
                        <TableCell className="!whitespace-nowrap">{payment.baseAmount}</TableCell>
                        <TableCell>{payment.fees}</TableCell>
                        <TableCell>{payment.totalAmount}</TableCell>
                        <TableCell>
                            <Link to={`/users/detail/user-payment-detail/${payment?.id}`}>
                                <ViewIconButton />
                            </Link>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default PaymentTable;
