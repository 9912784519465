import {
    S3Client, DeleteObjectCommand
    // HeadObjectCommand 
} from "@aws-sdk/client-s3";


const REGION = process.env.REACT_APP_S3_REGION;
const accessKeyId = process.env.REACT_APP_S3_ACCESS_KEY;
const secretAccessKey = process.env.REACT_APP_S3_SECRET_KEY;
const BASE_URL = process.env.REACT_APP_S3_BASE_PATH;
const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET;

let s3ClientConfigured: any = S3Client

if (accessKeyId && secretAccessKey) {
    s3ClientConfigured = new S3Client({
        credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey
        },
        region: REGION
    });
}

// Function to delete an object
export async function deleteS3Object(objectKey: string) {
    try {
        const params = {
            Bucket: S3_BUCKET_NAME, // Specify the name of the bucket
            Key: objectKey // Specify the key of the object to delete
        };

        const command = new DeleteObjectCommand(params);
        const response = await s3ClientConfigured.send(command);
        console.log('Object deleted successfully:', response);
    } catch (err) {
        console.error('Error deleting object:', err);
    }
}


export default s3ClientConfigured;