import { combineReducers } from 'redux';
import { alertsReducer } from "./alerts";
import { adminInfoReducer } from './admin';
import { userInfoReducer } from './users';
import { opportunityInfoReducer } from './opportunity';
import { feedbackReducer } from './feedback';
import { featuredServicesReducer } from './featuredServices';
import { PaymentReducer } from './Payments';
import { pendingCardReducer } from './PendingCustomCards';


const rootReducer = combineReducers({
    alerts: alertsReducer,
    adminInfo: adminInfoReducer,
    users: userInfoReducer,
    opportunity: opportunityInfoReducer,
    feedback: feedbackReducer,
    featuredServices: featuredServicesReducer,
    paymentStore: PaymentReducer,
    pendingCardStore: pendingCardReducer
});

export default rootReducer;