import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from '@mui/material';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
import "../src/index.css"
import theme from './theme';

// Get language from environment variable
const locale = process.env.REACT_APP_LOCALE || "en";
// Update lang attribute of the document
document.documentElement.lang = locale;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const version = packageJson.version;

root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={true} //If true, the library writes verbose logs to console.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ ThemeProvider>
      </PersistGate>
    </Provider>
  </CacheBuster>
);


reportWebVitals();
