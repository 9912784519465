import React, { useContext, useEffect, useRef, useState } from "react";
import { Typography, Box, Button, TextField, Modal, IconButton } from "@mui/material";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import PaidIcon from "@mui/icons-material/Paid";
import "./GroomInfo.css";
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import PostTable from "./PostTable";
import PaymentTable from "./PaymentTable";
import ForwardIcon from '@mui/icons-material/Forward';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from "react-router-dom";
import { SentTransferMoneyApi } from "../../../services/api_services/users";
import { ContextInterface, MakePaymentRequest } from "../../../services/dataType/api_interfaces/users";
import ReportIcon from '@mui/icons-material/Report';
import Report from "./Report";
import { UserDataContext } from "./UserDetail";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomTextInputField from "../../../components/common/customTextInputField";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroomTransferredTable from "./GroomTransferredTable";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import IosShareIcon from '@mui/icons-material/IosShare';
import PushPinSharpIcon from '@mui/icons-material/PushPinSharp';
import { translateLanguage } from "../../../translation";

interface Opportunity {
    leader: {
        name: string;
        email: string;
        photo?: string;
    };
    created_at: string;
    relation_with_groom: {
        relation: string;
    };
    contributors: any[];
}
interface Payment {
    time: string | number | Date;
    donorInfo: {
        name: string;
        email: string;
        photo?: string;
    };
    totalAmount: string;
    user: string;
    date: string;
    amount: string;
    total: string;
    baseAmount: string;
    paymentType: string;
}


export default function GroomInfo(props: any) {
    const contextValue: ContextInterface = useContext(UserDataContext);
    const userData: any = contextValue.userData.data.groomBasicInfo;
    const paymentsData: any = contextValue.userData.data.groomPaymentsInfo;


    const remarksText = useRef<HTMLInputElement>(null)
    const { userId } = useParams();
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [openModal, setOpenModal] = useState(false);
    const [PaymentTextInput, setPaymentTextInput] = useState("");

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleEnableMoney = () => {
        handleCloseModal();
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: "2-digit",
            month: "long",
            year: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
    };

    const userFields = [
        { label: translateLanguage("WEDDING_HALL_NAME"), value: userData.weddingHallName },
        { label: translateLanguage("WEDDING_DATE"), value: formatDate(userData.weddingDate) },
        { label: translateLanguage("GENDER"), value: userData.gender },
    ];

    const userFieldsNumbers = [
        { label: translateLanguage("IBAN_NUMBER"), value: userData.ibanNumber },
        { label: translateLanguage("BANK_ACCOUNT"), value: userData.bankAccount },
        { label: translateLanguage("ACCOUNT_NUMBER"), value: userData.accountNo },
    ];

    const handleSort = (column: string) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const sortedData = sortColumn
        ? userData.opportunityList
            .slice()
            .sort((a: Opportunity, b: Opportunity) => {
                if (sortColumn === "date") {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
                } else if (sortColumn === "contributors") {
                    return sortOrder === "asc"
                        ? a.contributors.length - b.contributors.length
                        : b.contributors.length - a.contributors.length;
                }
                return 0;
            })
        : userData.opportunityList;

    const handleDateSort = () => {
        setSortColumn("date");
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleTotalSort = () => {
        setSortColumn("totalAmount");
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleFeeSort = () => {
        setSortColumn("fees");
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };



    const sortedPayments = paymentsData.slice().sort((a: Payment, b: Payment) => {
        if (sortColumn === "date") {
            const dateA = new Date(a.time).getTime();
            const dateB = new Date(b.time).getTime();
            return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        } else if (sortColumn === "totalAmount") {
            const totalA = parseFloat(a.totalAmount);
            const totalB = parseFloat(b.totalAmount);
            return sortOrder === "asc" ? totalA - totalB : totalB - totalA;
        }
        return 0;
    });

    const [updatedTabsdata, setUpdatedTabsdata] = useState(userData);
    const SentTransferMoney = async () => {
        const requestData: MakePaymentRequest = {
            user_id: userId,
            payment_to: "groom",
            amount_transfer: PaymentTextInput,
            remarks: remarksText.current?.value?.trim() ?? ""
        };
        try {
            const successHitPaymentAPI = await SentTransferMoneyApi(requestData);
            if (successHitPaymentAPI) {
                handleCloseModal();
                setPaymentTextInput("");

                const updatedTotalTransferedAmount = parseFloat(updatedTabsdata.totalBaseAmountTransfered) + parseFloat(PaymentTextInput);
                const updatedTabsData = { ...updatedTabsdata, totalBaseAmountTransfered: updatedTotalTransferedAmount.toString() };
                setUpdatedTabsdata(updatedTabsData);
            }
        } catch (error) {
            console.error("Error transferring money:", error);
        }
    };

    return (
        <>
            <Box className="!py-5 !bg-[var(--white)]  commonBorderRadius commonShadow  px-5 !shadow-lg !gap-4">
                <Box
                    sx={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.06)" }}
                    className="lg:!flex !justify-between !rounded-md  commonBorderRadius   border !bg-white"
                >
                    <Box className="!p-5 lg:!flex !grid !items-center !justify-between !w-full">
                        <Box className="lg:!border-l-2 lg:!pl-3">
                            {userFields.map((field, index) => (
                                <Box key={index} className="xl:!flex !grid xl:!gap-10 !gap-1">
                                    <Typography variant="subtitle1" className="!min-w-[180px] NunitoSans600 !text-[var(--gray-font)] xl:!mt-0 !mt-3 ">
                                        {field.label}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        className="!text-muted textOverflowTwoLines NunitoSans700 !pt-1 !font-semibold"
                                    >
                                        {field.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>

                        <Box className="lg:!border-l-2 lg:!pl-3">
                            {userFieldsNumbers.map((number, index) => (
                                <Box key={index} className="xl:!flex !grid xl:!gap-10 !gap-1">
                                    <Typography variant="subtitle1" className="!min-w-[180px] NunitoSans600 !text-[var(--gray-font)] xl:!mt-0 !mt-3 ">
                                        {number.label}
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        className="!text-muted NunitoSans700 !pt-1 !font-semibold"
                                    >
                                        {number.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>

                <Box className="!py-3 !mt-8">
                    <Tabs value="payment">
                        <Box className="!items-center !overflow-x-auto !grid !gap-4 !justify-between !w-full ">
                            <TabsHeader className="lg:!max-w-[700px] !max-w-[500px] lg:!min-w-[500px] !min-w-[300px] NunitoSans700 !overflow-x-auto commonButtonRadius commonShadow  ">
                                {[
                                    { label: translateLanguage("PAYMENTS"), value: "payment", icon: AccountBalanceIcon },
                                    { label: translateLanguage("OPPORTUNITIES"), value: "post", icon: PushPinSharpIcon },
                                    { label: translateLanguage("REPORT"), value: "report", icon: ReportIcon },
                                    { label: translateLanguage("TRANSFERRED"), value: "transferred", icon: IosShareIcon },
                                ].map(({ label, value, icon }) => (
                                    <Tab key={value} value={value}>
                                        <div className="!flex !items-center !justify-center !text-center !gap-2 xl:!min-w-[150px] NunitoSans700">
                                            {/* {React.createElement(icon, { className: "!w-[18px] !h-[18px]" })} */}
                                            {React.createElement(icon, { className: `!w-[19px] !h-[19px] ${value === 'post' ? 'iconRotate' : ''}` })}
                                            {label}
                                        </div>
                                    </Tab>
                                ))}
                            </TabsHeader>
                            <Box className="xl:!flex !grid !w-full !items-center  !gap-4 "

                            >

                                <Typography variant="h3" sx={{
                                    background: "var(--green-transfered-light)",
                                }} className="!font-semibold NunitoSans800  !text-[var(--green-transfered)]  !w-full !text-sm md:!text-base !whitespace-nowrap   commonButtonRadius  sm:!px-5 !px-2 !py-[6px]">
                                    {translateLanguage("AMOUNT_TRANSFERRED")}: <span className="!font-semibold NunitoSans800 !text-base  md:!text-lg ">{updatedTabsdata.totalBaseAmountTransfered}</span> </Typography>

                                <Typography variant="h3" sx={{
                                    background: "var(--yellow-dark-light)",
                                }} className=" !font-semibold NunitoSans800 !text-[var(--yellow-dark)] !w-full  !text-sm md:!text-base !whitespace-nowrap commonButtonRadius  sm:!px-5 !px-2 !py-[6px]">{translateLanguage("TOTAL_AMOUNT")}: <span className="!font-semibold NunitoSans800 !text-base  md:!text-lg ">{(userData.totalBaseAmountRecived).toFixed(2)}</span> </Typography>

                                <Button onClick={handleOpenModal} className="!font-semibold NunitoSans800 commonButtonRadius !min-w-[130px] sm:!px-8 !px-4 !flex !gap-2 !w-fit !py-2 !text-sm md:!text-base !whitespace-nowrap !bg-[var(--primary)]" variant="contained"
                                >
                                    {translateLanguage("TRANSFER")} <IosShareIcon className="!w-4 !h-4" />
                                </Button>
                            </Box>
                        </Box>
                        <TabsBody>
                            {[
                                {
                                    label: translateLanguage("PAYMENTS"),
                                    value: "payment",
                                    components: (
                                        <>
                                            <PaymentTable
                                            // sortedPayments={sortedPayments}
                                            // handleDateSort={handleDateSort}
                                            // handleTotalSort={handleTotalSort}
                                            // handleFeeSort={handleFeeSort}
                                            />
                                        </>
                                    ),
                                },
                                {
                                    label: translateLanguage("POST"),
                                    value: "post",
                                    components: (
                                        <PostTable />
                                    ),
                                },
                                {
                                    label: translateLanguage("REPORT"),
                                    value: "report",
                                    components: (
                                        <Report
                                            sortedPayments={sortedPayments} />
                                    ),
                                },
                                {
                                    label: translateLanguage("TRANSFERRED"),
                                    value: "transferred",
                                    components: (
                                        <GroomTransferredTable />
                                    ),
                                },
                            ].map(({ value, components }) => (
                                <TabPanel key={value} value={value} className="!overflow-x-auto !px-0">
                                    {components}
                                </TabPanel>
                            ))}
                        </TabsBody>
                    </Tabs>
                </Box>
            </Box>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                className="!right-3 !left-3"
                aria-describedby="modal-description"
            >
                <Box className="!rounded-md !py-6 !px-8" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: 770, bgcolor: 'background.paper', boxShadow: 24, }}>
                    <Typography id="modal-title" variant="h6" component="h2" className="!font-semibold !flex NunitoSans800 !justify-between !items-center">
                        {translateLanguage("TRANSFER_MONEY")}
                        <IconButton onClick={handleEnableMoney}>
                            <ClearIcon />
                        </IconButton>
                    </Typography>

                    <Box className="!grid !gap-4 !justify-betweesn !w-full !items-centewr !mt-0">
                        <Box className=" !grid !gap-4 !justify-betweesn !w-full !items-centewr !mt-7 !overflow-x-auto">
                            <Typography variant="h4" sx={{
                                background: "var(--yellow-dark-light)",
                            }} className=" !w-full !h-fit !font-semibold NunitoSans800 !whitespace-nowrap !text-base  commonButtonRadius  !px-5 !py-[14px]">
                                <span className="!flex !gap-1 !items-center !text-[var(--yellow-dark)]">
                                    {translateLanguage("TOTAL_AMOUNT")}:
                                    <span className="!font-semibold NunitoSans800 !text-lg ">
                                        {" "} {(userData?.totalBaseAmountRecived).toFixed(2)}
                                    </span>
                                    <CheckCircleOutlineIcon className=" !ml-auto !text-[var(--yellow-dark)]" />
                                </span>

                            </Typography>
                            <Typography variant="h4" sx={{
                                background: "var(--green-transfered-light)",
                            }} className=" !w-full !h-fit !font-semibold NunitoSans800 !whitespace-nowrap !text-base commonButtonRadius !px-5 !py-[14px]">
                                <span className="!flex !gap-1 !text-[var(--green-transfered)] !items-center">
                                    {translateLanguage("AMOUNT_TRANSFERRED")}:
                                    <span className="!font-semibold NunitoSans800 !text-lg ">
                                        {" "}{updatedTabsdata?.totalBaseAmountTransfered}
                                    </span>
                                    <CheckCircleOutlineIcon className=" !ml-auto !text-[var(--green-transfered)]" />
                                </span>
                            </Typography>
                            <Typography variant="h4" sx={{
                                background: "var(--lightblue-balance-light)"
                            }} className=" !w-full !h-fit !font-semibold NunitoSans800 !whitespace-nowrap !text-base  commonButtonRadius  !px-5 !py-[14px]">
                                <span className="!flex !gap-1 !items-center !text-[var(--lightblue-balance)]">
                                    {translateLanguage("BALANCE_AMOUNT")}:
                                    <span className="!font-semibold NunitoSans800 !text-lg ">
                                        {(parseFloat(userData.totalBaseAmountRecived) - parseFloat(updatedTabsdata.totalBaseAmountTransfered)).toFixed(2)}
                                    </span>
                                    <CheckCircleOutlineIcon className=" !ml-auto !text-[var(--lightblue-balance)]" />
                                </span>
                            </Typography>
                        </Box>
                        <Box className="!w-full">
                            <TextField
                                fullWidth
                                value={PaymentTextInput}
                                className="!w-full sm:!mt-0 !mt-6"
                                type="number"
                                placeholder={translateLanguage("ENTER_AMOUNT_HERE")}
                                onChange={(e) => setPaymentTextInput(e.target.value)}
                                variant="outlined"
                            />
                            {parseFloat(PaymentTextInput) > parseFloat(userData.totalBaseAmountRecived) - parseFloat(updatedTabsdata.totalBaseAmountTransfered) && (
                                <Typography variant="subtitle1" className="!text-sm !pt-1 NunitoSans600" style={{ color: 'red' }}>
                                    {translateLanguage("BALANCE_AMOUNT_THAT_CAN_BE_TRANSFERRED")}:
                                    &nbsp;{(parseFloat(userData.totalBaseAmountRecived) - parseFloat(updatedTabsdata.totalBaseAmountTransfered)).toFixed(2)}
                                </Typography>
                            )}
                        </Box>

                        <Box className="!w-full">
                            <TextField
                                fullWidth
                                placeholder={translateLanguage("ENTER_REMARKS_HERE")}
                                title="Remarks"
                                type="text"
                                inputRef={remarksText}
                            />
                        </Box>
                    </Box>

                    <Box className="!mb-4 !mt-8 !w-full" display="flex" justifyContent="center">
                        <Button
                            onClick={SentTransferMoney}
                            className="!font-semibold !px-8 commonButtonRadius NunitoSans800 !flex !gap-2 !py-3 !text-base"
                            variant="contained"
                            color="success"
                            disabled={!PaymentTextInput || parseFloat(PaymentTextInput) > parseFloat(userData.totalBaseAmountRecived) - parseFloat(updatedTabsdata.totalBaseAmountTransfered)}
                        >
                            {translateLanguage("TRANSFER")} <ForwardIcon />
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
