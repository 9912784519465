import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import LoginForm from './LoginForm';
import { Box, Typography } from '@mui/material';
import LoginAppLogo from "../../static/LoginAppLogo";
import {
  Menu,
  MenuItem as ProMenuItem,
} from "react-pro-sidebar";


export default function SignInSide() {

  return (


    <Grid container component="main" sx={{ height: '100vh' }}>

      <CssBaseline />
      <Grid
        item
        className='!bg-opacity-10 !bg-[#28857b] !justify-center !items-center lg:!flex !hidden h-fit md:!h-full !m-auto'
        xs={false}
        sm={12}
        md={5}
      // sx={{
      //   // backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
      //   // backgroundImage: url(""),
      //   backgroundRepeat: 'no-repeat',
      //   backgroundColor: (t) =>
      //     t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // }}
      >


        <Box className="">
          <Box className=" !gap-5 !h-full !items-center !w-full !text-center !justify-center ">
            <Typography variant='h1' className="!text-[32px] !mb-8 !font-semibold">
              Refdah Admin
            </Typography>
            <LoginAppLogo className="!inline" />
          </Box>
        </Box>
      </Grid>
      <LoginForm />
    </Grid>
  );
}