import { Box, Button, Fab, Typography } from "@mui/material"
import AlertToasterDatagrid from "../../components/Datagrids/AlertToasterDatagrid"
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import TextDialogBox from "../../components/common/TextDialogBox";
import { addNewToasterApi, getToasterAlertsAPI } from "../../services/api_services/alertsToast";
import AddPrimaryButton from "../../components/common/AddPrimaryButton";
import CommonHeaderText from "../../components/common/CommonHeaderText";
import { translateLanguage } from "../../translation";

function incrementVersion(version: any) {
    let [major, minor, patch] = version.split('.').map(Number);

    // Increment the patch value
    patch++;

    // Check if the patch value exceeds 9
    if (patch > 9) {
        patch = 0;
        minor++;
    }

    // Check if the minor value exceeds 9
    if (minor > 9) {
        minor = 0;
        major++;
    }

    // Return the updated version string
    return `${major}.${minor}.${patch}`;
}

const ToastAlertList = () => {
    const [alertsList, setAlertsList] = useState<any>([])

    const [dialogState, setDialogState] = useState<any>({
        open: false,
        modeType: "",
        formType: "",
        existingValue: "",
        onSubmitCallback: () => { }
    })

    const addNewToastCallback = async (value: any) => {
        const newVersion = alertsList.length ? incrementVersion(alertsList[0].version) : "1.1.0"
        const requestData = {
            text: value.title,
            version: newVersion
        }
        const responseSuccess = await addNewToasterApi(requestData)
        if (responseSuccess) {
            getToasterAlertsAPI(setAlertsList)
            setDialogState({
                ...dialogState,
                open: false
            })
        }
    }

    useEffect(() => {
        getToasterAlertsAPI(setAlertsList)
    }, [])

    const noteName = translateLanguage("ADDING_NEW_BANNER_INFO")

    return (
        <>
            <TextDialogBox
                dialogState={dialogState}
                setDialogState={setDialogState}
            />
            <Box className="!p-5 ">
                <div
                    className="!flex !mb-[5px]   !items-center !h-full"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "10px",
                    }}
                >
                    <Box>
                        <CommonHeaderText headerName={translateLanguage("BANNER_NOTIFICATION")} />
                    </Box>


                    <AddPrimaryButton
                        onClick={() => {
                            setDialogState({
                                open: true, modeType: translateLanguage("ADDING"),
                                formType: translateLanguage("BANNER_TEXT"),
                                note: noteName,
                                title_length: "300",
                                existingValue: "",
                                onSubmitCallback: addNewToastCallback
                            });
                        }}
                        buttonName={translateLanguage("ADD_BANNER")}
                        variant="outlined"
                    />


                </div>
                <AlertToasterDatagrid alertsList={alertsList} setAlertsList={setAlertsList} />
            </Box>
        </>

    )
}

export default ToastAlertList
