import Routing from './Routing';
import { ProgressBar } from './components/common/Loader'
import AlertPopUp from './components/common/AlertPopUp';

function App() {
  return (
    <>
      <ProgressBar />
      <AlertPopUp />
      <Routing />
    </>
  );
}

export default App;
