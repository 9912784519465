import TextDialogBox from '../../components/common/TextDialogBox'
import { relationListInterface } from '../../services/dataType/api_interfaces/relations';
import { AddNewRelationAPI, RelationListAPI, SaveRelationAPI } from '../../services/api_services/relations';
import { RootStateType } from '../../store';
import TruncatedText from '../../components/common/TruncatedText';
import { ShowErrorAlert } from '../../store/alerts';
import { Add, Button, IconButton, ModeEdit, Paper, Stack, TextField, Typography, useDispatch, useEffect, useRef, useSelector, useState } from '../../utils/commonImports';
import AddPrimaryButton from '../../components/common/AddPrimaryButton';
import { translateLanguage } from '../../translation';

const RelationList = () => {
    const dispatch = useDispatch();
    const relationTitleRef: any = useRef(null);
    const currentLanguage = useSelector((state: RootStateType) => state.alerts.currentLanguage)

    const [editRelation, setEditRelation] = useState({
        id: "",
        editting: false,
    })
    const [relations, setRelations] = useState<relationListInterface[]>([]);

    const getRelationsList = async () => {
        const res = await RelationListAPI(setRelations)
        if (res) {
            return true;
        }
    }

    useEffect(() => {
        getRelationsList()
        setEditRelation({
            id: "",
            editting: false,
        })
    }, [currentLanguage])

    const [dialogState, setDialogState] = useState<any>({
        open: false,
        modeType: "",
        formType: "",
        existingValue: "",
        onSubmitCallback: () => { }
    })

    const successCallbackRelationSave = (dataObj: any, modeType: string) => {
        setRelations(prevStates => prevStates.map(relationObj => {
            if (relationObj.id === dataObj.id) {
                return { ...relationObj, relation: dataObj.relation };
            }
            return relationObj;
        }))
        setEditRelation({
            id: "",
            editting: false
        })
    }

    const handleRelationEdit = (relationObj: any) => {
        relationTitleRef.current = relationObj.relation;
        setEditRelation({
            id: relationObj.id,
            editting: true
        })
    }


    const handleRelationSave = () => {
        if (!relationTitleRef.current.value && !relationTitleRef.current.value.trim()) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("ENTER_RELATION") }));
            return false;
        } else if (relationTitleRef.current.value.length > 40) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("RELATION_CHAR_LIMIT") }));
            return false;
        }
        const requestData = {
            relation: relationTitleRef.current.value,
            relation_id: editRelation.id
        }
        SaveRelationAPI(requestData, successCallbackRelationSave)
    }

    const AddNewRelationCallbackSave = async (data: any) => {
        const response = await AddNewRelationAPI(data.title, getRelationsList)
        if (response) {
            setDialogState({
                ...dialogState,
                open: false
            })
        }
    }

    return (
        <div className='!py-[30px] !px-5' style={{ display: 'flex', height: '95vh', justifyContent: "flex-start" }}>
            <TextDialogBox dialogState={dialogState} setDialogState={setDialogState} />
            <Paper
                className="commonBorderRadius commonShadow"
                elevation={5}
                style={{
                    width: '100%', overflowY: 'auto',
                    // borderRadius: "5",
                }}>

                <Stack className='!p-1 commonButtonRadius commonShadowLight' direction="row" sx={{
                    // boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 7px 1px",

                    marginBottom: '20px',
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    height: "auto"
                }}>
                    <Typography variant="h6" className='!font-semibold !pl-3 NunitoSans800' style={{ textAlign: "center" }}>
                        {translateLanguage("RELATIONS")}
                    </Typography>

                    <AddPrimaryButton
                        onClick={() => setDialogState({
                            open: true,
                            modeType: translateLanguage("ADD"),
                            formType: translateLanguage("RELATION"),
                            title_length: "40",
                            existingValue: "",
                            onSubmitCallback: AddNewRelationCallbackSave

                        })}
                        buttonName={translateLanguage("ADD_RELATIONS")}
                        variant="outlined"
                    />


                </Stack>

                <Stack direction="column" spacing={1} className='!px-5 !pb-3'>
                    {relations.map((relationObj, index) => (
                        <div
                            className='!gap-3 !mb-2 commonShadowLight !p-1'
                            key={index}
                            style={{
                                display: "flex",
                                boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 7px 1px",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            {
                                (editRelation.editting && editRelation.id === relationObj.id)
                                    ?
                                    <>
                                        <Button
                                            variant="contained" className='!px-4 !py-[8px] !text-sm !font-semibold commonButtonRadius NunitoSans800 !bg-red-500 !text-white'
                                            onClick={() => {
                                                setEditRelation({
                                                    id: "",
                                                    editting: false
                                                })
                                            }}
                                        >
                                            {translateLanguage("CANCEL")}
                                        </Button>
                                        <TextField
                                            inputRef={relationTitleRef}
                                            className='!w-full'
                                            defaultValue={relationTitleRef.current}
                                            InputProps={{
                                                style: {
                                                    height: '40px',
                                                }
                                            }}

                                        />
                                        <Button
                                            variant="contained" className='!px-4 !py-[8px] !text-sm !font-semibold  commonButtonRadius NunitoSans800 !bg-green-500 !text-white'
                                            onClick={() => handleRelationSave()}
                                        >
                                            {translateLanguage("SAVE")}
                                        </Button>
                                    </>
                                    :
                                    <>
                                        < Typography className='pl-3 NunitoSans500' sx={{
                                            backgroundColor: 'inherit',
                                            color: "black"
                                        }}
                                        >
                                            <TruncatedText text={relationObj.relation} maxLength={45} />
                                        </Typography>
                                        <IconButton
                                            onClick={() => handleRelationEdit(relationObj)}

                                            className="!px-3 !py-[5px] !text-sm !ml-auto commonButtonRadius NunitoSans800 !bg-opacity-15 !font-semibold !bg-[var(--edit-green-light)] !text-[var(--edit-green)]"
                                        >
                                            <ModeEdit className='!w-4 !mr-[5px] !text-[var(--edit-green)]' />
                                            {translateLanguage("EDIT")}

                                        </IconButton>


                                    </>
                            }
                        </div>
                    ))}
                </Stack>
            </Paper>
        </div>
    )
}

export default RelationList
