import { Reducer } from "react";
import { AlertActions, AlertsInterface } from "./types";

export const initialState: AlertsInterface = {
    success: {
        visible: false,
        message: ''
    },
    error: {
        visible: false,
        message: ''
    },
    loader: {
        visible: false
    },
    tableLoader: false,
    showAppVersion: false,
    currentLanguage: "ar"
}



export const alertsReducer: Reducer<AlertsInterface, AlertActions> = (state = initialState, { type, payload }: AlertActions) => {
    switch (type) {
        case "SHOW_SUCCESS_ALERT":
            return {
                ...state,
                success: payload
            };
        case "SHOW_ERROR_ALERT":
            return {
                ...state,
                error: payload
            };
        case "SHOW_LOADER":
            return {
                ...state,
                loader: { visible: true }
            };
        case "HIDE_LOADER":
            return {
                ...state,
                loader: { visible: false }
            };

        case "SHOW_TABLE_LOADER":
            return {
                ...state,
                tableLoader: true
            };
        case "HIDE_TABLE_LOADER":
            return {
                ...state,
                tableLoader: false
            };

        case "SHOW_APP_VERSION":
            return {
                ...state,
                showAppVersion: true
            }

        case "HIDE_APP_VERSION":
            return {
                ...state,
                showAppVersion: false
            }

        case "UPDATE_CURRENT_LANGUAGE":
            return {
                ...state,
                currentLanguage: payload
            }

        default:
            return state;
    }
};
