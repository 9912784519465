import { CUSTOM_MENU_CARD_ADD, CUSTOM_MENU_CARD_LIST } from "../../constants";
import { store } from "../../store";
import { ShowProgressBar } from "../../store/alerts";
import { patchRequest, postRequest } from "../../utils/services";

export const CustomMenuCardListAPI = async (setStateData: any) => {
  const { dispatch } = store;

  var response: any = null;

  dispatch(ShowProgressBar());
  response = await postRequest(CUSTOM_MENU_CARD_LIST, {});

  if (response) {
    let responseData = response.data as any;

    let tempData: any[] = [];
    responseData.forEach((responseData: any) => {
      tempData.push({
        card_id: responseData.id,
        page_url: responseData.page_url,
        description: responseData.text,
        title: responseData.title,
        imageURL: responseData.image,
        isActive: responseData.is_active,
      });
    });

    setStateData(responseData);
    return true;
  }
};

export const SaveCustomMenuCardAPI = async (
  requestData: any,
  callback: any
) => {
  const { dispatch } = store;
  var response: any = null;

  dispatch(ShowProgressBar());
  response = await patchRequest(CUSTOM_MENU_CARD_ADD, requestData);

  if (response) {
    let responseData = response.data as any;
    callback(responseData, "edit");
    return true;
  }
};

export const AddNewCustomMenuCardAPI = async (
  requestData: any,
  callback: any
) => {
  const { dispatch } = store;
  var response: any = null;

  dispatch(ShowProgressBar());
  response = await postRequest(CUSTOM_MENU_CARD_ADD, requestData);

  if (response) {
    return await callback();
  }
};
