// export const ALERTS_REDUX = {
//     SHOW_ERROR_ALERT: 'SHOW_ERROR_ALERT',
//     SHOW_SUCCESS_ALERT: 'SHOW_SUCCESS_ALERT',
//     SHOW_LOADER: 'SHOW_LOADER',
//     HIDE_LOADER: 'HIDE_LOADER',   
// };


export const USER_REDUX_CONST = {
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
}

export const RESET = 'RESET'; 