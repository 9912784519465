import { FEATURED_SERVICES_LIST, FEATURED_SERVIVCE_DETAIL, SEND_PENDING_CARD_ATTACHMENT } from "../../constants";
import { store } from "../../store";
import { SavePendingCardList, SaveTotalPendingCardCount } from "../../store/PendingCustomCards";
import { HideTableLoader, ShowTableLoader } from "../../store/alerts";
import { SaveFeaturedServicesList, SaveTotalFeaturedServicesCount } from "../../store/featuredServices";
import { postRequest } from "../../utils/services";
import { featuredServicesListResponseInterface, pendingCardsListResponseInterface } from "../dataType/api_interfaces/featuredServices";
import { listRequest } from "../dataType/api_interfaces/opportunity";
import { listRequestInterface } from "../dataType/api_interfaces/users";
import { featuredServicesInfoInterface, pendingCustomCardInfoInterface } from "../dataType/reduxActionsInterfaces/featuredServices";

export const FeaturedServicesListAPI = async (values: listRequestInterface) => {
    const { dispatch } = store

    let data: any = listRequest(values);
    data['filter_type'] = values.filterType;
    var response: any = null;

    dispatch(ShowTableLoader());
    response = await postRequest((FEATURED_SERVICES_LIST), data);


    if (response) {
        let responseData = response.data.featured_services as featuredServicesListResponseInterface[]
        let totalCount = response.data.featured_services_count
        let tempData: featuredServicesInfoInterface[] = []
        responseData.forEach((responseData) => {
            tempData.push({
                id: responseData.id,
                serviceType: responseData.service_type,
                amount: responseData.amount,
                guestNumbers: responseData.guest_numbers,
                createdAt: responseData.created_at,
                requestedBy: {
                    id: responseData.requested_by.id,
                    name: responseData.requested_by.name,
                    photo: responseData.requested_by.photo
                },
                donorInfo: {
                    id: responseData.donor_info.id,
                    name: responseData.donor_info.name,
                    photo: responseData.donor_info.photo
                },
                payment: {
                    id: responseData.payment.id
                }

            })
        })

        dispatch(SaveFeaturedServicesList(tempData));
        dispatch(SaveTotalFeaturedServicesCount(totalCount || 0));
        dispatch(HideTableLoader());
        return true;
    }

};



export const PendingCardsListAPI = async (values: listRequestInterface) => {
    const { dispatch } = store

    let data: any = listRequest(values);
    data['filter_type'] = "groom_custom_card"
    data['card_status'] = values.cardStatus
    var response: any = null;

    dispatch(ShowTableLoader());
    response = await postRequest((FEATURED_SERVICES_LIST), data);

    if (response) {
        let responseData = response.data.featured_services as pendingCardsListResponseInterface[]
        let totalCount = response.data.featured_services_count
        let tempData: pendingCustomCardInfoInterface[] = []
        responseData.forEach((responseData) => {
            tempData.push({
                id: responseData.id,
                serviceType: responseData.service_type,
                amount: responseData.amount,
                guestNumbers: responseData.guest_numbers,
                createdAt: responseData.created_at,
                customCardMedia: responseData.custom_card_media,
                remarks: responseData.remarks,
                isCompleted: responseData.is_completed,
                requestedBy: {
                    id: responseData.requested_by.id,
                    name: responseData.requested_by.name,
                    photo: responseData.requested_by.photo,
                    weddingDate: responseData.requested_by.wedding_date,
                    city: responseData.requested_by.city,
                    state: responseData.requested_by.state,
                    phoneNumber: responseData.requested_by.phone,
                    accountNumber: responseData.requested_by.account_no,
                    ibanNumber: responseData.requested_by.iban_number,
                    bankAccount: responseData.requested_by.bank_account,
                    email: responseData.requested_by.email,
                    weddingHallName: responseData.requested_by.wedding_hall_name,
                    weddingPlace: responseData.requested_by.wedding_place,
                    gender: responseData.requested_by.gender
                }
            })
        })

        dispatch(SavePendingCardList(tempData));
        dispatch(SaveTotalPendingCardCount(totalCount || 0));
        dispatch(HideTableLoader());
        return true;
    }
};



export const getfeaturedServiceDetailApi = async (cardId: string, setObj: any) => {
    const { dispatch } = store

    let data = {
        custom_card_id: cardId,
    };
    var response: any = null;

    dispatch(ShowTableLoader());
    response = await postRequest((FEATURED_SERVIVCE_DETAIL), data);

    if (response) {
        let responseData = response.data as pendingCardsListResponseInterface
        setObj({
            id: responseData.id,
            serviceType: responseData.service_type,
            amount: responseData.amount,
            guestNumbers: responseData.guest_numbers,
            createdAt: responseData.created_at,
            customCardMedia: responseData.custom_card_media,
            remarks: responseData.remarks,
            isCompleted: responseData.is_completed,
            requestedBy: {
                id: responseData.requested_by.id,
                name: responseData.requested_by.name,
                photo: responseData.requested_by.photo,
                weddingDate: responseData.requested_by.wedding_date,
                city: responseData.requested_by.city,
                state: responseData.requested_by.state,
                phoneNumber: responseData.requested_by.phone,
                accountNumber: responseData.requested_by.account_no,
                ibanNumber: responseData.requested_by.iban_number,
                bankAccount: responseData.requested_by.bank_account,
                email: responseData.requested_by.email,
                weddingHallName: responseData.requested_by.wedding_hall_name,
                gender: responseData.requested_by.gender,
                weddingPlace: responseData.requested_by.wedding_place,

            }
        }
        )
        return true;
    }
};



export const SendPendingCardAPI = async (values: any) => {
    const { dispatch } = store

    let data = {
        custom_card_id: values.card_id,
        custom_card_media: values.card_media,
        remarks: values.remarks
    };
    var response: any = null;

    dispatch(ShowTableLoader());
    response = await postRequest((SEND_PENDING_CARD_ATTACHMENT), data);


    if (response) {
        let responseData = response.data.featured_services as featuredServicesListResponseInterface[]

        return true;
    }

};

