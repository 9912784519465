import { Reducer } from "react";
import { pendingCardStoreActions, pendingCardStoreInterface } from "./types";

export const pendingCardInitialState: pendingCardStoreInterface = {
    pendingCardList: [],
    totalPendingCardCount: 0,
}

export const pendingCardReducer: Reducer<pendingCardStoreInterface, pendingCardStoreActions> = (state = pendingCardInitialState, { type, payload }: pendingCardStoreActions) => {
    switch (type) {

        case "UPDATE_PENDING_CARD_LIST":
            return {
                ...state,
                pendingCardList: payload
            };

        case "UPDATE_PENDING_CARD_COUNT":
            return {
                ...state,
                totalPendingCardCount: payload
            };

        case "RESET_PENDING_CARD_STORE":
            return pendingCardInitialState;

        default:
            return state;
    }
};
