import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import FeaturedServicesDatagrid from '../../components/Datagrids/FeaturedServicesDatagrid';
import { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import { translateLanguage } from '../../translation';


export default function FeaturedServicesList() {
    const [filterType, setFeedbackFilter] = useState("featured_individual");
    // const feedbackList = useSelector((state: RootStateType) => state?.feedback);
    const handleTabChange = (event: any, newValue: any) => {
        setFeedbackFilter(newValue);
    };

    return (
        <>
            <Box className="!py-5 !px-5">
                {/* <Container className="!py-5" sx={{ maxWidth: "2000px" }}> */}
                <Box className="">
                    <CommonHeaderText headerName={translateLanguage("FEATURED_SERVICES")} />
                </Box>
                <div className='!mb-5 !gap-3 lg:!gap-5 !grid lg:!flex !items-center !justify-between !mt-4' >

                    <Box className="lg:!flex !grid !items-center !gap-3 !overflow-hidden">
                        <Box className="!border commonShadow !bg-[var(--primary)] tabsRadius">
                            <Tabs value={filterType} onChange={handleTabChange} className=" !overeflow-scroll tabsScroll" >
                                <Tab className='lg:!text-base lg:!min-w-[220px] !text-sm !font-semibold'
                                    label={translateLanguage("INDIVIDUAL")}
                                    icon={<CalendarViewDayIcon />}
                                    value="featured_individual"
                                // label={`Individvals (${feedbackList?.totalNotesCount})`}  
                                />
                                <Tab className='lg:!text-base lg:!min-w-[220px] !text-sm !font-semibold'
                                    label={translateLanguage("OPPORTUNITIES")}
                                    icon={<CardGiftcardIcon />}
                                    value="featured_opportunity"
                                // label={`Oppurtnities (${feedbackList?.totalComplaintCount})`} 
                                />
                                <Tab className='lg:!text-base lg:!min-w-[220px] !text-sm !font-semibold'
                                    label={translateLanguage("PAY_IN_SPECAIL_WAY")}
                                    icon={<AccountBalanceIcon />}
                                    value="groom_special_pay"
                                // label={`CustomAnytheing (${feedbackList?.totalSuggestionCount})`} 
                                />
                            </Tabs>
                        </Box>
                    </Box>
                </div>
                <FeaturedServicesDatagrid filterType={filterType} />
                {/* </Container> */}
            </Box>
        </>
    )
}