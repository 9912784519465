import { Reducer } from "react";
import { userStoreActions, userStoreInterface } from "./types";

export const userInitialState: userStoreInterface = {
    usersList: [],
    totalUsersCount: 0,
}

export const userInfoReducer: Reducer<userStoreInterface, userStoreActions> = (state = userInitialState, { type, payload }: userStoreActions) => {
    switch (type) {
        case "UPDATE_USERS_LIST":
            return {
                ...state,
                usersList: payload
            };

        case "UPDATE_USERS_COUNT":
            return {
                ...state,
                totalUsersCount: payload
            };


        case "RESET_USER_STORE":
            return userInitialState;

        default:
            return state;
    }
};
