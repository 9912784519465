import { useSelector } from 'react-redux';
import { RootStateType } from '../../store/RootState';
import { Box, LinearProgress } from '@mui/material';


export function ProgressBar(props: any) {
    const alerts = useSelector((state: RootStateType) => state.alerts);
    return (
        <>
            {
                alerts.loader.visible
                    ?
                    <Box component={'div'} sx={{ width: '100%', position: 'fixed', zIndex: "9999" }}>
                        <LinearProgress sx={{
                            backgroundColor: 'var(--brand--font--link)'
                        }} />
                    </Box>
                    :
                    ''
            }
        </>
    )
}
