import { Reducer } from "react";
import { opportunityStoreActions, opportunityStoreInterface } from "./types";

export const opportunityInitialState: opportunityStoreInterface = {
    opportunityList: [],
    totalOpportunityCount: 0,
}

export const opportunityInfoReducer: Reducer<opportunityStoreInterface, opportunityStoreActions> = (state = opportunityInitialState, { type, payload }: opportunityStoreActions) => {
    switch (type) {

        case "UPDATE_OPPORTUNITY_LIST":
            return {
                ...state,
                opportunityList: payload
            };

        case "UPDATE_OPPORTUNITY_COUNT":
            return {
                ...state,
                totalOpportunityCount: payload
            };

        case "RESET_OPPORTUNITY_STORE":
            return opportunityInitialState;

        default:
            return state;
    }
};
