import { CITY_ADD, STATE_ADD, STATE_CITY_LIST } from "../../constants";
import { store } from "../../store";
import { ShowProgressBar } from "../../store/alerts";
import { patchRequest, postRequest } from "../../utils/services";
import { stateCityListResponseInterface } from "../dataType/api_interfaces/state_city";


export const StateCityListAPI = async (setStateData: any) => {
    const { dispatch } = store

    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((STATE_CITY_LIST), {});

    if (response) {
        let responseData = response.data as stateCityListResponseInterface
        setStateData(responseData)
        return true;
    }
};


export const SaveStateAPI = async (requestData: any, callback: any) => {
    const { dispatch } = store
    var response: any = null;

    dispatch(ShowProgressBar());
    response = await patchRequest((STATE_ADD), requestData);

    if (response) {
        let responseData = response.data as stateCityListResponseInterface
        callback(responseData, "edit")
        return true;
    }
};


export const AddNewStateAPI = async (requestData: any, callback: any) => {
    const { dispatch } = store
    var response: any = null;

    dispatch(ShowProgressBar());
    response = await postRequest((STATE_ADD), {
        state: requestData
    });

    if (response) {
        return await callback()
    }
};

export const AddCitytAPI = async (requestData: any, callback: any) => {
    const { dispatch } = store
    var response: any = null;

    dispatch(ShowProgressBar());

    response = await postRequest((CITY_ADD), requestData);

    if (response) {
        return await callback()
    }
};


export const SaveCityAPI = async (requestData: any, callback: any) => {
    const { dispatch } = store
    var response: any = null;

    dispatch(ShowProgressBar());
    response = await patchRequest((CITY_ADD), requestData);

    if (response) {
        let responseData = response.data

        callback(responseData)
        return true;
    }
};