import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { RootStateType } from '../../store';
import { useSelector } from 'react-redux';
import { FeedbackRequestInterface } from '../../services/dataType/api_interfaces/feedback';
import { getFeedbackList } from '../../services/api_services/feedback';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ViewIconButton from '../common/ViewIconButton';
import { translateLanguage } from '../../translation';


interface FeedbackGridProps {
    searchText?: string;
    filterType?: string;
}

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_DATA_FOUND"),
};

export default function FeedbackDatagrid(props: FeedbackGridProps) {
    const { searchText, filterType } = props;




    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: 'first_name', headerName: translateLanguage("USER_NAME"), minWidth: 200, maxWidth: 200, flex: 1.4, headerAlign: 'left', sortable: false,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            {params.row.first_name}
                        </>
                    );
                }
            },
            {
                field: 'phone_no', headerName: translateLanguage("PHONE"), minWidth: 200, maxWidth: 200, flex: 1.4, headerAlign: 'left', sortable: false,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            {params.row.country_code}&nbsp;&nbsp;
                            {params.row.phone_no}
                        </>
                    );
                }
            },
            {
                field: 'message', headerName: translateLanguage("MESSAGE"), minWidth: 200, flex: 1.4, headerAlign: 'left', sortable: false,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            <Typography className="!whitespace-nowrap !max-w-[800px] !overflow-hidden !text-ellipsis">
                                {params.row.message}
                            </Typography>
                        </>
                    );
                }
            },
            {
                field: 'action', headerName: translateLanguage("ACTION"), minWidth: 200, flex: 0.5, sortable: true,
                headerAlign: 'center',
                renderCell: (params) => {
                    return (
                        <div onClick={() => handleViewButtonClick(params.row)} className='!m-auto !items-center !justify-center '>
                            {/* <Box className=" !msl-[-50px]"> */}

                            {/* <Button  className="NunitoSans800 !px-4 !py-[5px] commonButtonRadius !bg-[var(--primary-light)]  !text-[var(--primary)]  !text-base !font-semibold">
                                View Message
                            </Button> */}
                            <ViewIconButton />
                            {/* </Box> */}
                        </div>
                    );
                }
            },

        ]
    }


    const columns = generateColumns();
    const typingTimeoutRef = useRef<number | NodeJS.Timeout | undefined>(undefined);
    const [columnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            id: true
        });
    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [sortState, setSortState] = useState<any>({
        field: "",
        sortType: "",
        active: false
    });
    const [selectedFeedback, setSelectedFeedback] = useState<any>(null);
    const [openModal, setOpenModal] = useState(false);
    const feedbackList = useSelector((state: RootStateType) => state?.feedback?.feedbackList);
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);
    const totalFilteredCount = useSelector((state: RootStateType) => state?.feedback?.totalFilteredCount);

    useEffect(() => {
        const apiRequestData: FeedbackRequestInterface = {
            pageLimit: paginationModel.pageSize,
            pageNumber: paginationModel.page + 1,
            searchText: searchText,
            filterType: filterType
        }

        if (sortState.active) {
            apiRequestData['sortBy'] = sortState.field
            apiRequestData['sortType'] = sortState.sortType
        }

        getFeedbackList(apiRequestData)
    }, [paginationModel, sortState, searchText, filterType]);


    useEffect(() => {
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(() => {
            setPaginationModel({ ...paginationModel, page: 0 });
        }, 1000);
    }, [searchText])


    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {

            let sortField = ""
            switch (sortModel[0].field) {
                case "phoneNumber":
                    sortField = "phone_no"
                    break;

                default:
                    sortField = sortModel[0].field;
                    break;
            }

            setSortState({
                field: sortField,
                sortType: sortModel[0].sort,
                active: true
            })
        }
        else {
            setSortState({
                field: "",
                sortType: "asc",
                active: false
            })
        }
    }, []);

    const handleViewButtonClick = (feedback: any) => {
        setSelectedFeedback(feedback);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTable feedbackDataGridTwoLineHeight'>
            <DataGrid
                autoHeight={true}
                rows={feedbackList}
                columns={columns}
                loading={tableLoader}
                getRowId={(row) => row.id}
                pageSizeOptions={[10, 25, 50]}
                className='feedbackDatagrid tableHeaderColor commonBorderRadius commonShadow'
                localeText={customLocaleText}
                paginationMode={'server'}
                paginationModel={paginationModel}
                columnVisibilityModel={columnVisibilityModel}
                onSortModelChange={handleSortModelChange}
                onPaginationModelChange={setPaginationModel}
                rowCount={totalFilteredCount}
            />

            <Dialog open={openModal} maxWidth="sm" fullWidth onClose={handleCloseModal} className='!p-10'>
                <DialogTitle className='NunitoSans800' style={{ fontWeight: 600, fontSize: '1.3rem' }}>
                    {translateLanguage("FEEDBACK_MESSAGE")}
                </DialogTitle>
                <DialogContent className='NunitoSans800 !text-[var(--gray-font)]'>{selectedFeedback && selectedFeedback.message}</DialogContent>
                <DialogActions className=" !pb-7">
                    <Button onClick={handleCloseModal} type="button" variant="contained" className='!border-2 NunitoSans800 !mr-4 commonButtonRadius !bg-[var(--cancel-gray)] !px-6 !py-[8px] !font-semibold  !text-base '>
                        {translateLanguage("CLOSE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
