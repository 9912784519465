import { pendingCustomCardInfoInterface } from "../../services/dataType/reduxActionsInterfaces/featuredServices";
import {
    UpdatePendingCardListType,
    ResetPendingCardStoreType,
    UpdateTotalPendingCardCountType
} from "./types";



export const SavePendingCardList = (payload: pendingCustomCardInfoInterface[]): UpdatePendingCardListType => ({
    type: "UPDATE_PENDING_CARD_LIST",
    payload: payload
});

export const SaveTotalPendingCardCount = (payload: number): UpdateTotalPendingCardCountType => ({
    type: "UPDATE_PENDING_CARD_COUNT",
    payload: payload
});

export const ResetPendingCardStore = (): ResetPendingCardStoreType => ({
    type: "RESET_PENDING_CARD_STORE"
});

