import { Box, Button, FormControl, FormLabel, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import DatePicker from '../../components/common/DatePicker';
import PaymentsListDatagrid from '../../components/Datagrids/PaymentsListDatagrid';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import { translateLanguage } from '../../translation';

export default function PaymentList() {

    const [filterType, setFilterType] = useState("-")

    const handleFilterChange = (event: SelectChangeEvent<string>) => {
        setFilterType(event.target.value);
    }

    const [dialogState, setDialogState] = useState({
        open: false,
        startDate: new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)),
        endDate: new Date(),
        triggerApi: false
    })


    let paymentsList = useSelector((state: RootStateType) => state.paymentStore.PaymentList);
    useEffect(() => {
        setDialogState({
            ...dialogState,
            triggerApi: false
        })
    }, [paymentsList])

    return (
        <>
            <DatePicker dialogState={dialogState} setDialogState={setDialogState} />
            <div className="!py-5 !px-5">
                <div className='!mb-5 !gap-5 lg:!flex !items-centeer !justify-between ' >
                    <Box>
                        <CommonHeaderText headerName={translateLanguage("PAYMENTS")} />
                    </Box>
                    <Box className="!flex !items-center !gap-2 selecterFocus" >
                        <FormControl>
                            <FormLabel className='!text-[var(--gray-font)] !font-semibold NunitoSans800'>{translateLanguage("PAYMENT_TYPE")}</FormLabel>
                            <Select
                                style={{ background: "var(--white)" }}

                                MenuProps={{
                                    sx: {
                                        "&& .Mui-selected": {
                                            color: "var(--primary)",
                                            background: "var(--primary-light) ",
                                        },
                                    },
                                }}
                                sx={{
                                    "& [aria-expanded=true]": {
                                        background: "var(--white)",
                                    },
                                }}


                                labelId="filter-type-label"
                                id="filter-type-select"
                                className='commonButtonRadius commonShadow NunitoSans800 filterType'
                                value={filterType}
                                onChange={handleFilterChange}
                            >
                                <MenuItem className='NunitoSans600' value="-">{translateLanguage("ALL")}</MenuItem>
                                <MenuItem className='NunitoSans600' value="individual">{translateLanguage("INDIVIDUAL")}</MenuItem>
                                <MenuItem className='NunitoSans600' value="opportunity">{translateLanguage("OPPORTUNITY")}</MenuItem>
                                {/* <MenuItem className='NunitoSans600' value="featured_individual">Featured Individual</MenuItem>
                                <MenuItem className='NunitoSans600' value="featured_opportunity">Featured Opportunity</MenuItem>
                                <MenuItem className='NunitoSans600' value="groom_special_pay">Groom Special Pay</MenuItem>
                                <MenuItem className='NunitoSans600' value="groom_custom_card">Groom Custom Card</MenuItem> */}
                            </Select>


                        </FormControl>
                        <Box>
                            <Typography variant="body1" className='!text-[var(--gray-font)] NunitoSans800 !font-semibold' style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {translateLanguage("DATE_RANGE")}
                            </Typography>

                            <Button
                                variant="outlined"
                                style={{ background: "var(--white)" }}
                                className='commonButtonRadius commonShadow NunitoSans800 primaryButtonClass'
                                type="button"
                                // sx={{ mt: 3 }}
                                onClick={() => setDialogState({ ...dialogState, open: true })}
                            >
                                {moment(dialogState.startDate).format('MMMM D, YYYY')}-
                                {moment(dialogState.endDate).format('MMMM D, YYYY')}

                            </Button>
                        </Box>
                    </Box>


                </div>
                <PaymentsListDatagrid filterType={filterType} dialogState={dialogState} />
            </div >
        </>

    )
}
