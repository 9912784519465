import { TextField, IconButton, Slide, Fade } from '@mui/material'
import ClearIcon from '@mui/icons-material/ClearOutlined';
import Search from '@mui/icons-material/SearchOutlined';
import React, { Dispatch, SetStateAction, useState } from 'react'
import { translateLanguage } from '../../translation';

export default function SearchBox(props: any) {
    const { searchText, setSearchText } = props

    // const [showSearch, setShowSearch]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false);


    const handleClearClick = () => {
        setSearchText('')
    }

    function searchChange(text: React.ChangeEvent<HTMLInputElement>) {
        setSearchText(text.target.value)
    }

    // const toggleSearchBox = (event: any) => {
    //     event.stopPropagation()
    //     setShowSearch(!showSearch)
    // }

    return (
        <div className='!flex searchbarWidth'>
            <Slide
                direction="down"
                in={true}
                // in={showSearch}
                className='searchBox '>
                <TextField
                    InputProps={{
                        style: {
                            height: '40px',
                            marginTop: "10px",
                            borderRadius: "5px",
                            // borderRadius: "26px",
                        },
                        endAdornment: (
                            <IconButton
                                onClick={handleClearClick}
                            >
                                <ClearIcon />
                            </IconButton>
                        ),
                    }}
                    value={searchText}
                    sx={{ marginTop: "-10px" }}
                    onChange={searchChange} id="search" placeholder={translateLanguage("SEARCH")} variant="outlined" />
            </Slide>

            {/* <IconButton id='searchBar' className='searchBar' color="inherit" onClick={toggleSearchBox}><Search /></IconButton> */}
        </div>

    )
}
