import { Reducer } from "redux";
import { PaymentStoreActions, PaymentStoreInterface } from "./types";

const PaymentInitialState: PaymentStoreInterface = {
    PaymentList: [],
    totalPaymentCount: 0,
};

export const PaymentReducer: Reducer<PaymentStoreInterface, PaymentStoreActions> = (
    state = PaymentInitialState,
    { type, payload }
) => {
    switch (type) {

        case "UPDATE_PAYMENT_LIST":
            return {
                ...state,
                PaymentList: payload,
            };

        case "UPDATE_TOTAL_PAYMENT_COUNT":
            return {
                ...state,
                totalPaymentCount: payload,
            };

        default:
            return state;
    }
};


