import { Typography, Avatar, Chip, Box, Container, IconButton } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { translateLanguage } from '../../../translation';


const verifiedIcon = (
    <>
        <CheckCircleOutlinedIcon sx={{ fill: "green", width: "20px", height: "20px", marginTop: "-2px", marginLeft: "", color: "green" }} />
    </>
)

const unVerifiedIcon = (
    <>
        <NewReleasesOutlinedIcon sx={{ fill: "red", width: "20px", height: "20px", marginTop: "-2px", marginLeft: "", color: "red" }} />
    </>
)
const OpportunityGroomInfo = (props: any) => {
    const { opportunityData } = props
    const data = opportunityData

    return (
        <>
            {/* <Container className="py-5" sx={{ maxWidth: "2000px" }}> */}
            <Box className="!pb-2 !pt-5 !w-full" >
                <Box
                    // sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)" }}
                    className="commonShadow commonBorderRadius !bg-white"
                >
                    <Box
                        className=" xl:flex justify-between "
                    >
                        <Box className="xl:!flex !grid xl:!gap-12 !gap-4 p-5 items-center !h-fit ">
                            <Box className="relative w-fit">
                                <Avatar
                                    alt={data.groomBasicInfo.name}
                                    src={data.groomBasicInfo.photo}
                                    className="rounded-circle xl:!w-[100px] xl:!h-[100px] !h-[80px] !w-[80px]"
                                />
                                <Chip
                                    className="!absolute !bottom-0 !right-[-20px] NunitoSans700 !h-[23px]"
                                    label={data.groomBasicInfo.isActive ?
                                        translateLanguage("ACTIVE")

                                        :
                                        translateLanguage("INACTIVE")
                                    }
                                    sx={{
                                        fontWeight: "600",
                                        color: "white",
                                        backgroundColor: `${data.groomBasicInfo.isActive ? "#32cd32" : "#ff7777fa"
                                            }`,
                                    }}
                                />
                            </Box>

                            <Box>
                                <Typography variant="body1" className="!w-fit !my-2 NunitoSans800 !font-semibold !text-sm !py-1 !px-2 !rounded !bg-opacity-15 !bg-blue-800 !text-blue-800">
                                    {translateLanguage("GROOM_INFO")}
                                </Typography>
                                <Link to={`/users/detail/${data.groomBasicInfo.id}`}>
                                    {/* className="!text-muted textOverflowTwoLines !font-semibold NunitoSans800"> */}
                                    <Typography variant="body1"
                                        className="!w-fit !text-blue-500 !border-blue-600 NunitoSans800 !border-b-2 textOverflowTwoLines !font-semibold">

                                        {data.groomBasicInfo.name}
                                    </Typography>
                                </Link>
                                {/* <Typography
                                    variant="body1"
                                    className="text-muted flex gap-1 items-center NunitoSans600"
                                >
                                    {data.groomBasicInfo   }
                                    {data.groomBasicInfo.isEmailVerified ? verifiedIcon : unVerifiedIcon}
                                </Typography> */}
                                <Typography
                                    variant="body1"
                                    className="text-muted flex gap-1 items-center NunitoSans600"
                                >
                                    {data.groomBasicInfo.countryCode} {data.groomBasicInfo.phoneNumber}
                                    {data.groomBasicInfo.isPhoneVerified ? verifiedIcon : unVerifiedIcon}
                                </Typography>
                            </Box>
                        </Box>

                        <Box className="p-5 flex items-center lg:!min-w-[450px]">
                            <Box className="lg:border-l-2 lg:pl-3">
                                {/* <Box className="flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[100px] min-w-[60px]"
                                    >
                                        Phone
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="text-muted flex gap-1 items-center !font-semibold"
                                    >
                                        {data.groomBasicInfo.countryCode} {data.groomBasicInfo.phoneNumber}
                                        {data.groomBasicInfo.isPhoneVerified ? verifiedIcon : unVerifiedIcon}
                                    </Typography>
                                </Box> */}


                                <Box className="flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[100px] NunitoSans600 !text-[var(--gray-font)] min-w-[60px]"
                                    >
                                        {translateLanguage("STATE")}
                                    </Typography>
                                    <Typography variant="body1" className="text-muted NunitoSans700 textOverflowTwoLines !font-semibold">
                                        {data.groomBasicInfo.state}
                                    </Typography>
                                </Box>

                                <Box className="flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[100px] NunitoSans600 !text-[var(--gray-font)] min-w-[60px]"
                                    >
                                        {translateLanguage("CITY")}
                                    </Typography>
                                    <Typography variant="body1" className="text-muted NunitoSans700 textOverflowTwoLines !font-semibold">
                                        {data.groomBasicInfo.city}
                                    </Typography>
                                </Box>


                                <Box className="flex gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[100px] NunitoSans600 !text-[var(--gray-font)] min-w-[60px]"
                                    >
                                        {translateLanguage("RELATION")}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="text-muted NunitoSans700 flex gap-1 items-center !font-semibold"
                                    >
                                        {data.realtionWithGroom.relation}
                                    </Typography>
                                </Box>

                                <Box className="flex !mt-3 !gap-10">
                                    <Typography
                                        variant="subtitle1"
                                        className="md:min-w-[100px] NunitoSans900 text-[var(--gray-font)] !font-semibold  min-w-[60px]"
                                    >
                                        {translateLanguage("LEADER")}
                                    </Typography>
                                    <Link to={`/users/detail/${data.leaderBasicInfo.id}`}>

                                        <Typography className="!text-blue-500 !border-blue-600 NunitoSans800 !border-b-2 textOverflowTwoLines !font-semibold">
                                            {data.leaderBasicInfo.name}
                                            {/* <IconButton className='!rounded-none !border-blue-600 !border-b-2'>
                                            <InfoIcon color="primary" className='!relative  !bottom-[2px]'/>
                                            </IconButton> */}
                                        </Typography>
                                    </Link>
                                </Box >
                            </Box >
                        </Box >
                    </Box >
                </Box >
            </Box >
        </>
    )
}

export default OpportunityGroomInfo
