import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
// import { arSD } from '@mui/x-data-grid/locales';
import { arSD } from '@mui/material/locale'

// Get environment variables for locale and direction
const locale = process.env.REACT_APP_LOCALE; // Example: 'arSD'

// Create a theme instance.
const theme = createTheme(
  {
    direction: (locale === "ar") ? 'rtl' : 'ltr',
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    palette: {
      primary: {
        main: '#0098e5',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
    },
  },
  (locale === "ar") ? arSD : {}
);

export default theme;
